export enum Environment {
  Production = 'Production',
  Staging = 'Staging',
}

export const isDevelopment = process.env.NODE_ENV === 'development';
export const env: Environment = Environment.Production;
export const useVaults: boolean = false;
export const useCryptoPay: boolean = false;
export const useDerivatives: boolean = false;
export const useNotifications: boolean = false;
export const useReferrals: boolean = false;

const siteName: string = 'NoOnes';

interface PermanentConfig {
  siteName: string,
  useVaults: boolean,
  useCryptoPay: boolean,
  useDerivatives: boolean,
  useNotifications: boolean,
  useReferrals: boolean,
}

export interface PermanentConfigEnv extends PermanentConfig {
  environment: Environment,
  exchangeName: string,
  vakoWs: string,
  vakoUrl: string,
  derivativesTradeUrl: string,
  derivativesTradeWs: string,
  cryptoPayUrl: string,
  cryptoPayWidgetUrl: string,
  lendingReports: string,
  vakoUploads: string,
  vakoReports: string,
}

export interface ConfigEnv extends PermanentConfigEnv {
  Production: PermanentConfigEnv,
  Staging: PermanentConfigEnv,
}

const permanentConfigData: PermanentConfig = {
  siteName,
  useVaults,
  useCryptoPay,
  useDerivatives,
  useNotifications,
  useReferrals,
};

// @ts-ignore
const envs: ConfigEnv = {
  Production: {
    environment: Environment.Production,
    exchangeName: 'DEMO',
    vakoWs: 'wss://vakotrade-noones.cryptosrvc.com/graphql',
    vakoUrl: 'https://vakotrade-noones.cryptosrvc.com/graphql',
    derivativesTradeUrl: 'https://margin-trading-service.cryptosrvc-staging.com/graphql',
    derivativesTradeWs: 'wss://margin-trading-service.cryptosrvc-staging.com/graphql',
    cryptoPayUrl: 'https://crypto-pay-service.cryptosrvc.com',
    cryptoPayWidgetUrl: 'https://crypto-pay-widget.cryptosrvc.com',
    lendingReports: 'https://lending-service.cryptosrvc.com/v1/back-office/lending',
    vakoUploads: 'https://vakotrade-noones.cryptosrvc.com/uploads',
    vakoReports: 'https://vakotrade-noones.cryptosrvc.com/reports',
    ...permanentConfigData,
  },
  Staging: {
    environment: Environment.Staging,
    exchangeName: 'VAKO-MAIN',
    vakoWs: 'wss://vakotrade.cryptosrvc-dev.com/graphql',
    vakoUrl: 'https://vakotrade.cryptosrvc-dev.com/graphql',
    derivativesTradeUrl: 'https://margin-trading-service.cryptosrvc-staging.com/graphql',
    derivativesTradeWs: 'wss://margin-trading-service.cryptosrvc-staging.com/graphql',
    cryptoPayUrl: 'https://crypto-pay-service.cryptosrvc-staging.com',
    cryptoPayWidgetUrl: 'https://crypto-pay-widget.cryptosrvc-staging.com',
    lendingReports: 'https://lending-service.cryptosrvc-staging.com/v1/back-office/lending',
    vakoUploads: 'https://vakotrade.cryptosrvc-dev.com/uploads',
    vakoReports: 'https://vakotrade.cryptosrvc-dev.com/reports',
    ...permanentConfigData,
  },
};

export default envs[env] as PermanentConfigEnv;
