import * as gql from 'gql-query-builder';

import { 
  accountDerivativesDataFields,
  estimateDerivativesPositionFields,
  getDerivativesBalanceFields,
  derivativesOpenCloseTradeFields,
  derivativesOpenClosedPositionsFields,
  derivativesOpenOrderFields,
} from '../fields';
import { 
  ICancelDerivativesOrderPayload,
  IClosePositionPayload,
  ICreateDerivativesOrderPayload,
  IEstimatePositionPayload,
  IOpenPositionPayload,
  IUpdateOpenedDerivaivesOrderPayload,
  IUpdateOpenedPositionPayload,
} from '../dto';

export enum DerivativesTradeOperations {
  // ---- query
  EstimatePosition = 'estimate_margin_user_position',
  // ---- positions mutations 
  OpenPosition = 'open_margin_position',
  ClosePosition = 'close_margin_position',
  CloseAllPositions = 'close_all_margin_positions',
  UpdateOpenedPosition = 'update_open_margin_position',
  UpdateOpenedOrder = 'edit_margin_order',
  // ---- orders mutations
  CreateOrder = 'create_margin_order',
  CancelAllOrders = 'cancel_all_margin_orders',
  CancelOrder = 'cancel_margin_order',
}

export default {

  closePosition: ({ margin_position_id }: IClosePositionPayload) => {
    return gql.mutation({
      operation: DerivativesTradeOperations.ClosePosition,
      variables: {
        margin_position_id: { value: margin_position_id, required: true, type: 'String' },
      },
    });
  },

  closeAllPositions: () => {
    return gql.mutation({
      operation: DerivativesTradeOperations.CloseAllPositions,
    });
  },

  estimatePosition: ({ instrument_id, amount, leverage, side }: IEstimatePositionPayload) => {
    return gql.query({
      operation: DerivativesTradeOperations.EstimatePosition,
      fields: estimateDerivativesPositionFields,
      variables: {
        instrument_id: { value: instrument_id, required: true, type: 'String' },
        quantity: { value: amount, required: true, type: 'Float' },
        leverage: { value: leverage, required: true, type: 'Int' },
        side: { value: side, required: true, type: 'MarginPositionSide' },
      },
    });
  },

  availableMargin: ({ instrument_id, amount, leverage, side }: IEstimatePositionPayload) => {
    return gql.query({
      operation: DerivativesTradeOperations.EstimatePosition,
      fields: ['order_available_margin'],
      variables: {
        instrument_id: { value: instrument_id, required: true, type: 'String' },
        quantity: { value: amount, required: true, type: 'Float' },
        leverage: { value: leverage, required: true, type: 'Int' },
        side: { value: side, required: true, type: 'MarginPositionSide' },
      },
    });
  },

  getDerivativesBalance: ({ instrument_id, amount, leverage, side }: IEstimatePositionPayload) => {
    return gql.query({
      operation: DerivativesTradeOperations.EstimatePosition,
      fields: getDerivativesBalanceFields,
      variables: {
        instrument_id: { value: instrument_id, required: true, type: 'String' },
        quantity: { value: amount, required: true, type: 'Float' },
        leverage: { value: leverage, required: true, type: 'Int' },
        side: { value: side, required: true, type: 'MarginPositionSide' },
      },
    });
  },

  getAccountDerivativesData: ({ instrument_id, amount, leverage, side }: IEstimatePositionPayload) => {
    return gql.query({
      operation: DerivativesTradeOperations.EstimatePosition,
      fields: accountDerivativesDataFields,
      variables: {
        instrument_id: { value: instrument_id, required: true, type: 'String' },
        quantity: { value: amount, required: true, type: 'Float' },
        leverage: { value: leverage, required: true, type: 'Int' },
        side: { value: side, required: true, type: 'MarginPositionSide' },
      },
    });
  },

  updateOpenedPosition: ({ margin_position_id, stop_loss, take_profit, leverage }: IUpdateOpenedPositionPayload) => {
    return gql.mutation({
      operation: DerivativesTradeOperations.UpdateOpenedPosition,
      fields: derivativesOpenClosedPositionsFields(),
      variables: {
        margin_position_id: { value: margin_position_id, required: true, type: 'String' },
        leverage: { value: leverage, required: true, type: 'Int' },
        stop_loss: { value: stop_loss, required: false, type: 'Float' },
        take_profit: { value: take_profit, required: false, type: 'Float' },
      },
    });
  },

  // ---- orders
  createDerivativesOrder: ({ 
    instrument_id,
    leverage,
    side,
    price,
    stop_loss,
    take_profit,
    quantity,
    type,
  }: ICreateDerivativesOrderPayload) => {
    return gql.mutation({
      operation: DerivativesTradeOperations.CreateOrder,
      fields: derivativesOpenOrderFields,
      variables: {
        quantity: { value: quantity, required: true, type: 'Float' },
        instrument_id: { value: instrument_id, required: true, type: 'String' },
        leverage: { value: leverage, required: true, type: 'Int' },
        side: { value: side, required: true, type: 'MarginPositionSide' },
        type: { value: type, required: true, type: 'MarginOrderType' },
        price: { value: price, required: false, type: 'Float' },
        stop_loss: { value: stop_loss, required: false, type: 'Float' },
        take_profit: { value: take_profit, required: false, type: 'Float' },
      },
    });
  },

  updateOpenedOrder: ({ leverage, margin_order_id, quantity, price, stopLoss, takeProfit }: IUpdateOpenedDerivaivesOrderPayload) => {
    return gql.mutation({
      operation: DerivativesTradeOperations.UpdateOpenedOrder,
      fields: [],
      variables: {
        margin_order_id: { value: margin_order_id, required: true, type: 'String' },
        leverage: { value: leverage, required: true, type: 'Int' },
        quantity: { value: quantity, required: true, type: 'Float' },
        price: { value: price, required: false, type: 'Float' },
        stop_loss: { value: stopLoss, required: false, type: 'Float' },
        take_profit: { value: takeProfit, required: false, type: 'Float' },
      },
    });
  },

  cancelDerivativesOrder: ({ margin_order_id }: ICancelDerivativesOrderPayload) => {
    return gql.mutation({
      operation: DerivativesTradeOperations.CancelOrder,
      variables: {
        margin_order_id: { value: margin_order_id, required: true, type: 'String' },
      },
    });
  },

  cancelAllDerivativesOrders: () => {
    return gql.mutation({
      operation: DerivativesTradeOperations.CancelAllOrders,
    });
  },
}