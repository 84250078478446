import { createClient, Client } from 'graphql-ws';
import configEnv from '#/config/config-env';

export interface IGqlConnectionRequest {
  query: string,
  variables?: Record<string, unknown> | null,
};

export interface RequestCallbacks {
  next: (data: any) => void,
  error: (error: any) => void,
  complete: () => void,
}

export interface HeadersClient {
  [header: string]: any,
  authorization?: string,
}

export default class GqlConnection {
  public headers: HeadersClient;
  public clientWs: Client | undefined;
  private _apiRequest: IGqlConnectionRequest | undefined;
  private _callbacks: RequestCallbacks | undefined;

  constructor(headers?: HeadersClient) {
    this.headers = headers || {};
  }

  private _connect(wsUrl: string = configEnv.vakoWs) {
    if (this.clientWs) {
      this.disconnect();
    }

    this.clientWs = createClient({
      url: wsUrl,
      connectionParams: this.headers,
    });
  }

  public setAuthorization(authorization: string | undefined) {
    this.headers.authorization = authorization;
  }

  public setHeaders(headers: Object) {
    this.headers = {
      ...this.headers,
      ...headers,
    }
  }

  public disconnect() {
    return this.clientWs?.dispose();
  }

  private async _reconnect(wsUrl?: string) {
    await this.disconnect();
    this._connect(wsUrl);
  }

  public async subscribe(apiRequest: IGqlConnectionRequest, callbacks: RequestCallbacks, wsUrl?: string) {
    await this._reconnect(wsUrl);
    this._callbacks = callbacks;
    this._apiRequest = apiRequest;
    return this.clientWs?.subscribe(apiRequest, callbacks);
  }

  public resubscribe() {
    if (!this._apiRequest || !this._callbacks) {
      return new Error('resubscribe');
    }
    return this.subscribe(this._apiRequest, this._callbacks);
  }
}
