import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { handleErrorThunk, throttle } from '#/util'
import { RequestStatus } from '#/types/enums';
import { IDerivativesConfig } from '#/api/derivatives/dto';

import DerivativesService from '#/api/derivatives/DerivativesService';
import { 
  derivativesConfigRequestStatus,
  updateDerivativesConfig,
} from './derivativesConfig';

export const getDerivativesConfig = createAsyncThunk(
  'derivatives/getConfig',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    dispatch(derivativesConfigRequestStatus(RequestStatus.Pending));
    try {      
      const { margin_trading_config }: { margin_trading_config: IDerivativesConfig } = await (extra.DerivativesService as DerivativesService).getDerivativesConfig();
      batch(() => {
        dispatch(updateDerivativesConfig(margin_trading_config));
        dispatch(derivativesConfigRequestStatus(RequestStatus.Success));
      }); 
    } catch (error) {
      dispatch(derivativesConfigRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get Derivatives config failed', dispatch);
    }
  }, 5000),
) as unknown as () => AnyAction;