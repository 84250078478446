// ---- configs
export const derivativesInstrumentsFields = [
  'margin_instrument_id',
  'type',
  'name',
  'base_currency_id',
  'quote_currency_id',
  'min_leverage',
  'max_leverage',
  'min_quantity',
  'max_quantity',
  'funding_rate_1h',
  'price_24h_change',
  'price_decimals',
  'quantity_decimals',
  'price_24h_low',
  'price_24h_high',
  {
    base_currency: [
      'currency_id',
      'precision',
      'is_active',
    ]
  },
  {
    quote_currency: [
      'currency_id',
      'precision',
      'is_active',
    ]
  },
  {
    price: [
      'margin_instrument_id',
      'ask',
      'bid',
      'updated_at',
    ]
  },
  // 'cross_1_margin_instrument_id',
  // 'cross_1_margin_instrument_id',
];

export const derivativesConfigFields = [
  'margin_currency',
];

export const accountDerivativesDataFields = [
  'max_position_size',
  'order_required_margin',
];

// ---- positions
export const derivativesOpenClosedPositionsFields = (isClosedPosition?: boolean) => [
  'margin_position_id',
  'instrument_id',
  'user_id',
  'side',
  'leverage',
  'amount',
  'position_fee_amount',
  'funding_fee_amount',
  'entry_price',
  'limit_price',
  'active',
  'start_ts',
  'end_ts',
  'start_price_ts',
  'end_price_ts',
  'start_ask_price',
  'end_ask_price',
  'pnl',
  'max_profit',
  'max_loss',
  'stop_loss',
  'take_profit',
  'start_ts_timestamp',
  'on_limit',
  'close_reason',
  'updated_at',
  'start_bid_price',
  'end_bid_price',
  ...isClosedPosition ? ['exit_price'] : [],
  {
    margin_instrument: [
      'base_currency_id',
      'quote_currency_id',
      'name',
      'price_decimals',
      'quantity_decimals',
      'min_leverage',
      'max_leverage',
      {
        quote_currency: ['precision'],
      },
      {
        price: [
          'ask',
          'bid',
        ]
      }],
  }
];

export const subscribeDerivativesOrderbookFields = [
  'instrument_id',
  {
    buy: ['quantity', 'price'],
  },
  {
    sell: ['quantity', 'price'],
  }
];

export const subscribeDerivativesUserBalancePositionFields = [
  'free_balance',
  'equity',
  'margin_call_left_margin',
  'currently_used_margin',
  'pnl',
  'margin_ratio_percentage',
  'risk_level',
  'liquidation_left_margin',
];

// ---- trades
export const derivativesOpenCloseTradeFields = [
  'margin_position_id',
];

export const derivativesOpenOrderFields = [
  'margin_order_id',
];

export const estimateDerivativesPositionFields = [
  'order_required_margin',
  'fee',
  'max_position_size',
  'order_available_margin',
  {
    fees_breakdown: [
      'fee',
      'value',
      'source',
    ]
  },
];

export const getDerivativesBalanceFields = [
  'free_balance',
  'pnl',
  'equity',
];

// ---- instruments
export const derivativesPriceBarFields = [
  'margin_instrument_id',
  'high',
  'low',
  'updated_at',
  'updated_at_iso',
  'close',
  'open',
];

export const derivativesStrategiesFields = [
  'margin_instrument_id',
  'type',
  'is_active',
];

// ---- orders
export const derivativesOpenClosedOrdersFields = [
  'margin_order_id',
  'margin_position_id',
  'user_id',
  'instrument_id',
  'side',
  'type',
  'status',
  'leverage',
  'quantity',
  'price',
  'message',
  'created_at',
  'updated_at',
  'created_at_timestamp',
  'stop_loss',
  'take_profit',
  {
    margin_instrument: [
      'base_currency_id',
      'quote_currency_id',
      'name',
      'price_decimals',
      'quantity_decimals',
      'min_leverage',
      'max_leverage',
      {
        quote_currency: ['precision'],
      },
      {
        price: [
          'ask',
          'bid',
        ]
      }],
  }
];