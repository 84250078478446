import langConfig, { Languages } from '#/config/language/langConfig';
import { UrlSearchParamsEnum } from '#/types';

export const localStorageLangKey = 'currentLanguage';

const getCurrentLang = (): Languages => {
  // eslint-disable-next-line no-restricted-globals
  const url = new URL(location.href);
  const urlLang = url.searchParams.get(UrlSearchParamsEnum.Lang);

  // eslint-disable-next-line no-restricted-globals
  // history.replaceState && history.replaceState(
  //   // eslint-disable-next-line no-restricted-globals, no-useless-escape
  //   null, '', location.pathname + location.search.replace(/[\?&]lang=[^&]+/, '').replace(/^&/, '?')
  // );

  if (urlLang) {
    localStorage.setItem(localStorageLangKey, urlLang);
  }

  let currentLang = window.localStorage.getItem(localStorageLangKey);

  // Check if currentLanguage exists on the site. If it doesn't, use the default one.
  if (currentLang && !Object.keys(langConfig.items).includes(currentLang)) {
    currentLang = langConfig.default;
  }

  return (currentLang || langConfig.default || Languages.EnUs) as Languages;
};

export default getCurrentLang;
