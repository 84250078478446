import { IFiatPropertyInput } from '#/api/trade/dto';
import { IDepositFiatFundingMethod, IWithdrawFiatResult, PaymentProperty, FavoriteFiatDestination, PaymentProperties, IDepositFiatResult, EFiatCurrency, FiatWalletFields, PaymentProvider } from '#/types';
import { EFieldsType, getFieldsFiat } from './configWithdraw';

export interface IFiatWithdrawFields {
  fiat_bank_name: string,
  fiat_bank_address: string,
  fiat_reference: string,
  fiat_beneficiary_name: string,
  fiat_beneficiary_account_number: string,
  fiat_beneficiary_address_line_1: string,
  fiat_bank_country: string,
  fiat_bank_city: string,
  fiat_beneficiary_country: string,
  fiat_beneficiary_city: string,
  fiat_bank_bic: string | undefined,
  fiat_routing_number: string | undefined,
  fiat_beneficiary_address_line_2: string | undefined,
  fiat_bank_region: string | undefined,
  fiat_bank_postal_code: string | undefined,
  fiat_beneficiary_region: string | undefined,
  fiat_beneficiary_postal_code: string | undefined,
  fiat_beneficiary_tax_id: string,
  fiat_notes: string | undefined,
};

const transferTypeKey = 'fiat_transfer_type';

export enum FiatFieldErrorType {
  Required = 'required',
  MinMaxLength = 'minMaxLenght',
  Exists = 'exists',
  Length = 'length',
  OnlyDigits = 'onlyDigits',
  OnlyEnglishWithSpace = 'OnlyEnglishWithSpace',
}

export interface IFiatError {
  message: string,
  type: FiatFieldErrorType | null,
}

export interface IFieldsErrors {
  [field: string]: IFiatError,
}

const defineErrorRequired = (value?: string): IFiatError => {
  if (!!value?.replaceAll(' ', '')) {
    return {
      message: '',
      type: null,
    };
  }

  return {
    message: _t('Required field', 'NOVA_WITHDRAW.FIELD_REQUIRED_TEXT_ERROR'),
    type: FiatFieldErrorType.Required,
  };
};

export const defineErrorMinMaxSymbols = (
  value: string | undefined,
  min: number,
  max: number,
): IFiatError => {
  const isInvalid = value && (value.length < min || value.length > max)

  if (!isInvalid) {
    return {
      message: '',
      type: null,
    };
  }

  return {
    message: _t('Field must contain {min}-{max} symbols', 'NOVA_WITHDRAWAL.FIELD_MIN_MAX_SYMBOLS_ERROR', { min, max }),
    type: FiatFieldErrorType.MinMaxLength,
  };
};

export const defineFieldLengthError = (
  value: string | undefined,
  length: number,
): IFiatError => {
  const isInvalid = value && (value.length !== length);

  if (!isInvalid) {
    return {
      message: '',
      type: null,
    };
  }

  return {
    message: _t('Field must contain {length} symbols', 'NOVA_WITHDRAWAL.FIELD_MIN_SYMBOLS_ERROR', { length }),
    type: FiatFieldErrorType.Length,
  };
};

export const defineOnlyDigitsError = (
  value: string | undefined,
): IFiatError => {
  const onlyDigitsRegex = new RegExp('^[0-9]*$');
  const isInvalid = value && !onlyDigitsRegex.test(value);

  if (!isInvalid) {
    return {
      message: '',
      type: null,
    };
  }

  return {
    message: _t('Field must contain only digits', 'NOVA_WITHDRAWAL.FIELD_ONLY_DIGITS_ERROR'),
    type: FiatFieldErrorType.OnlyDigits,
  };
};

export const defineOnlyEnglishWithSpaceError = (
  value: string | undefined,
): IFiatError => {
  const onlyDigitsRegex = new RegExp("^[a-zA-Z\\s']+$");
  const isInvalid = value && !onlyDigitsRegex.test(value);

  if (!isInvalid) {
    return {
      message: '',
      type: null,
    };
  }

  return {
    message: _t('Field must contain only English letters', 'NOVA_WITHDRAWAL.FIELD_ONLY_ENGLISH'),
    type: FiatFieldErrorType.OnlyEnglishWithSpace,
  };
};

interface ICheckFieldsFiat<T> {
  withdrawFiat: IWithdrawFiatResult | T,
  withdrawMethod?: IDepositFiatFundingMethod | null,
  fieldsForValidation?: Array<string>,
  selectedCurrency?: string,
  psp_service_id?: string,
}

export const checkFieldsFiat = <T>({
  withdrawFiat,
  withdrawMethod,
  fieldsForValidation,
  selectedCurrency,
  psp_service_id,
}: ICheckFieldsFiat<T>
) => {
  const fields = fieldsForValidation?.length ? fieldsForValidation : getFieldsFiat({ selectedCurrency, withdrawMethod, fieldType: EFieldsType.Withdraw, psp_service_id });

  const fieldsErrors: IFieldsErrors = fields.reduce((acc, field) => {
    const requiredValidation = defineErrorRequired(withdrawFiat?.[field]);
    const onlyDigits = defineOnlyDigitsError(withdrawFiat?.[field]);

    acc[field] = requiredValidation;

    if (field === FiatWalletFields.BankBic && !requiredValidation.type) {
      acc[field] = defineErrorMinMaxSymbols(withdrawFiat?.[field], 8, 12);
    }

    /* custom validation for MXN account number (Clabe) */
    if (selectedCurrency === EFiatCurrency.MXN
      && field === FiatWalletFields.BeneficiaryAccountNumber
      && !requiredValidation.type
    ) {
      acc[field] = onlyDigits.type ? onlyDigits : defineFieldLengthError(withdrawFiat?.[field], 18);
    }

    /* custom validation for Arion */
    if (psp_service_id === PaymentProvider.ARION
      && field === FiatWalletFields.BeneficiaryName
      && !requiredValidation.type
    ) {
      acc[field] = defineOnlyEnglishWithSpaceError(withdrawFiat?.[field])
    }

    return acc;
  }, {} as IFieldsErrors);

  const isFiatInvalid = !withdrawFiat || Object.values(fieldsErrors).some((err) => err?.message);

  return { fieldsErrors, isFiatInvalid };
};

interface ICreateFiatProperties{
  withdrawFiat: IWithdrawFiatResult,
  transferType: string | null,
  selectedCurrency: string,
  psp_service_id?: string
}

export const createFiatProperties = ({
  withdrawFiat,
  transferType,
  selectedCurrency,
  psp_service_id,
}: ICreateFiatProperties): Array<IFiatPropertyInput> => {
  const includedKeys = getFieldsFiat({ selectedCurrency, fieldType: EFieldsType.FiatProperties, psp_service_id });

  const properties = Object.entries(withdrawFiat).reduce((acc, [name, value]) => {
    if (includedKeys.some((k) => k === name) && value) {
      acc.push({
        name,
        value,
      });
    }

    return acc;
  }, [] as Array<IFiatPropertyInput>);

  if (transferType) {
    properties.push({
      name: transferTypeKey,
      value: transferType,
    });
  }

  return properties;
};

export const prepareFiatBookProperties = (fiatBook: Array<FavoriteFiatDestination>): Array<FavoriteFiatDestination> & PaymentProperties => {
  //TODO: fix type & remove ...obj when properties is on
  //@ts-ignore
  return fiatBook.map(obj => ({
    ...obj,
    properties: Object.keys(obj).filter(key => key != 'properties').map(key => {
      return ({
        name: key,
        value: obj[key] || '',
      })
    })
  }));
}

export const propToFiatProp = (propName: string) => `fiat_${propName}`;

export const getPropertyValue = (properties: Array<PaymentProperty>, propertyName: FiatWalletFields) => {
  const property = properties?.find(prop => (prop.name === propertyName) || (propToFiatProp(prop.name) === propertyName));
  
  return property ? property.value : null;
}

export const transformPropertiesToFields = (addressList: Array<FavoriteFiatDestination>) => {
  return addressList.map(address => {
    /* we need this to support fiat addresses with empty properties array */
    const addressHasProperties = !!address.properties.length;
    const [preparedFiatAddress] = prepareFiatBookProperties([address]);
    const preparedFiatAddressProperties = preparedFiatAddress?.properties || [];

    //@ts-ignore
    const transformedDestiantion: FiatDestination = {
      ...address,
      properties: addressHasProperties ? address.properties : preparedFiatAddressProperties,
    };

    address.properties.forEach((property: PaymentProperty) => {
      transformedDestiantion[property.name] = property.value;
    });

    return transformedDestiantion;
  });
}

export const getPaymentProperties = (payment: IWithdrawFiatResult | IDepositFiatResult): Array<PaymentProperty> =>
  Object.keys(payment)
    .filter(key => Object.values(FiatWalletFields).includes(key as FiatWalletFields))
    .map(key => ({
      name: key,
      value: payment[key]
    }));