import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { Bar } from '#/util/chart/datafeed-api';
import { handleErrorThunk, prepareChartBars, prepareMarginChartBars, throttle } from '#/util'
import { Periodicity, RequestStatus } from '#/types/enums';
import { IDerivativesInstrument, IDerivativesStrategie } from '#/api/derivatives/dto';

import DerivativesService from '#/api/derivatives/DerivativesService';
import { 
  derivativesInstrumentsRequestStatus,
  updateDerivativesInstrumentHeaderData,
  updateDerivativesInstruments,
  updateDerivativesInstrumentsStrategies,
  updateSelectedDerivativesInstrumentPriceBars,
  // updateMarginSelectedInstrument,
} from './derivativesInstruments';
import { LoadInstrumentBars } from '#reducers/trade';
import { DerivativesInstrumentOperations } from '#/api/derivatives/derivatives-gql/instruments';

export const getDerivativestruments = createAsyncThunk(
  'derivatives/getInstruments',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    dispatch(derivativesInstrumentsRequestStatus(RequestStatus.Pending));
    try {      
      const { margin_instruments }: { margin_instruments: Array<IDerivativesInstrument> } = await (extra.DerivativesService as DerivativesService).getDerivativesIntruments();
      dispatch(updateDerivativesInstruments(margin_instruments));
      dispatch(derivativesInstrumentsRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(derivativesInstrumentsRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get Derivatives instruments failed', dispatch);
    }
  }, 5000),
) as unknown as () => AnyAction;

export const getDerivativestrumentsStrateies = createAsyncThunk(
  'derivatives/getInstrumentsStrategies',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    try {      
      const { margin_instruments_strategies }: { [DerivativesInstrumentOperations.Strategies]: Array<IDerivativesStrategie> } = await (extra.DerivativesService as DerivativesService).getDerivativesInstrumentsStrategies();
      dispatch(updateDerivativesInstrumentsStrategies(margin_instruments_strategies));
    } catch (error) {
      handleErrorThunk(error, 'Get Derivatives Instruments Strategies failed', dispatch);
    }
  }, 5000),
) as unknown as () => AnyAction;

export const getDerivativesInstrumentPriceBars = createAsyncThunk(
  'derivatives/getInstrumentsPriceBars',
  async (payload: LoadInstrumentBars, { dispatch, extra }: any) => {
    if (!payload.params.instrument_id) return
    try {
      const { margin_instrument_price_bars } = await (extra.DerivativesService as DerivativesService).getDerivativesPriceBars(payload.params);
      dispatch(updateSelectedDerivativesInstrumentPriceBars(margin_instrument_price_bars));
      const preparedBars = prepareMarginChartBars(margin_instrument_price_bars, payload.resolution).reverse();
      payload.callback && payload.callback(preparedBars, { noData: !margin_instrument_price_bars.length });
    } catch (error) {
      payload.onErrorCallback && payload.onErrorCallback(error);
      handleErrorThunk(error, 'Get instrument price bars failed', dispatch);
    }
  },
) as unknown as (payload: LoadInstrumentBars) => void;

// ---- we need a separate request for interval updating of the header with new data 
// ---- while avoiding re - rendering of the widget with the graph
export const getUpdatedDerivativesInstrumentHeaderData = createAsyncThunk(
  'derivatives/getUpdatedDerivativesInstrumentHeaderData',
  async (instrument_id: string, { dispatch, extra }: any) => {
    try {
      const {margin_instruments} = await (extra.DerivativesService as DerivativesService).getDerivativesIntruments();
      const selectedInstrument = margin_instruments.find((instrument) => instrument.margin_instrument_id === instrument_id);
      selectedInstrument && dispatch(updateDerivativesInstrumentHeaderData(selectedInstrument));
    } catch (error) {
      handleErrorThunk(error, 'Get Derivatives instrument failed', dispatch);
    }
  },
) as unknown as (payload: string) => void;

interface SubscribeDerivativesInstrumentPriceBar {
  instrument: string | Array<string>,
  periodicity: Periodicity,
  callbacks: Array<(payload: Bar) => void>,
  unsubscribe?: boolean
}

export interface IDerivativesInstrumentPriceBar {
  margin_instrument_id: string,
  high: number,
  low: number,
  updated_at: string,
  updated_at_iso: string,
  open: number,
  close: number,
}

export const subscribeDerivativesInstrumentPriceBar = createAsyncThunk(
  'derivatives/subscribeInstrumentPriceBar',
  async ({ instrument = [], periodicity, unsubscribe = false, callbacks = [] }: SubscribeDerivativesInstrumentPriceBar, { dispatch, extra }: any) => {
    try {
      (extra.DerivativesService as DerivativesService).subscribeInstrumentPriceBar({ instrument, periodicity, unsubscribe }, {
        next: ({ data: { margin_instrument_price_bar }}: { data: { margin_instrument_price_bar: IDerivativesInstrumentPriceBar }}) => {
          callbacks.forEach((_) => _(prepareMarginChartBars([margin_instrument_price_bar], periodicity)[0]));
        },
        error: (error: any) => {
          handleErrorThunk(error, 'Subscribe instrument price bars failed', dispatch);
        },
        complete: () => {},
      });
    } catch (error) {
      handleErrorThunk(error, 'Subscribe instrument price bars failed', dispatch);
    }
  }
) as unknown as (params: SubscribeDerivativesInstrumentPriceBar) => AnyAction;