import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { novaToast } from '#/nova/components/other/toast/novaToast';
import { OrderEstimation, OrderResult } from '#/types/interfaces';
import TradeService from '#/api/trade/TradeService';
import { debounce, handleErrorThunk, throttle } from '#/util';
import { OrderStatus, OrdersGetTypes, RequestStatus, TradeType } from '#/types/enums';
import { ExecuteOrderPayload, ConversionQuote, UpdateOpenOrderPayload } from '#/api/trade/trade-qql';
import { getOrders, ordersRequestStatus, selectAllOrders, updateOrders } from '#reducers/trade/orders';
import { OrderSummaryRender } from '#/nova/components/other/exchange/order-summary/order-summary-total/NovaOrderSummaryTotal';
import { EstimateOrder } from './types';
import { ConversionResult, ExecutedConversionResult } from '../interfaces';
import {
  orderRequestStatus,
  orderSummaryRender,
  createConversionStatus,
  orderEstimationStatus,
  executeConversionStatus
} from './order';
import { getBalances } from '../balances';
import { selectUserMarketCurrency } from '#reducers/user/user';

export const estimateOrder = createAsyncThunk(
  'trade/estimateOrder',
  debounce(1500, async ({ order, callback }: EstimateOrder, { dispatch, extra }: any) => {
    try {
      const { estimate_order }: { estimate_order: OrderEstimation } = await (extra.tradeService as TradeService).estimateMarketOrder(order);
      callback(estimate_order);
    } catch (error) {
      dispatch(orderEstimationStatus(RequestStatus.Failed));
      callback({} as OrderEstimation);
      handleErrorThunk(error, 'Estimate order failed', dispatch);
    }
  })
) as unknown as ({ order, callback }: EstimateOrder) => AnyAction;

interface ConversionWithCallback {
  conversion: ConversionQuote,
  callback: (conversionResult: ConversionResult) => void,
}
export const createConversionQuote = createAsyncThunk(
  'trade/createConversionQuote',
  debounce(1000, async ({ conversion, callback }: ConversionWithCallback, { dispatch, extra }: any) => {
    try {
      const { alias_create_conversion_quote }: { alias_create_conversion_quote: ConversionResult } = await (extra.tradeService as TradeService).createConversionQuote(conversion);
      callback(alias_create_conversion_quote);
      dispatch(createConversionStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(createConversionStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Create conversion quote failed', dispatch);
    }
  })
) as unknown as ({ conversion, callback }: ConversionWithCallback) => AnyAction;

export const cancelOrder = createAsyncThunk(
  'trade/cancelOrder',
  async (orderId: string, { dispatch, extra, getState }: any) => {
    try {
      await (extra.tradeService as TradeService).cancelOrder(orderId);
      const orders = selectAllOrders(getState());
      dispatch(updateOrders(orders.filter((_: OrderResult) => _.order_id !== orderId)));
    } catch (error) {
      handleErrorThunk(error, `Order ${orderId} has not cancelled.`, dispatch);
    }
  }
) as unknown as (orderId: string) => AnyAction;

export const cancelAllOrders = createAsyncThunk(
  'trade/cancelAllOrders',
  async (_, { dispatch, extra }: any) => {
    try {
      await (extra.tradeService as TradeService).cancelAllOrders();
      dispatch(updateOrders([]));
    } catch (error) {
      handleErrorThunk(error, 'Cancel orders failed', dispatch);
    }
  }
) as unknown as () => AnyAction;

export const executeOrder = createAsyncThunk(
  'trade/executeOrder',
  throttle(async ({ orderPayload, orderSummary, typeOrderHistory, marketCurrency }: { orderPayload: ExecuteOrderPayload, orderSummary: OrderSummaryRender, typeOrderHistory: any, marketCurrency:any }, { dispatch, extra }: any) => {
    try {
      await (extra.tradeService as TradeService).executeOrder(orderPayload);
      batch(() => {
        dispatch(orderRequestStatus(RequestStatus.Success));
        dispatch(orderSummaryRender(orderSummary));
        if (orderPayload.type === TradeType.Limit){
          dispatch(ordersRequestStatus(RequestStatus.Pending));
          dispatch(getOrders({ type: typeOrderHistory }));
        }
      });
    } catch (error) {
      handleErrorThunk(error, 'Order executing failed', dispatch);
      dispatch(orderRequestStatus(RequestStatus.Failed));
    }
  }, 2000)
) as unknown as ({ orderPayload, orderSummary, typeOrderHistory, marketCurrency }: { orderPayload: ExecuteOrderPayload, orderSummary: OrderSummaryRender, typeOrderHistory: any, marketCurrency:any }) => AnyAction;

export interface IUpdateOrderParams {
  orderPayload: UpdateOpenOrderPayload,
  setStatusCallback: (status: RequestStatus) => void,
}

export const updateOpenOrder = createAsyncThunk(
  'trade/updateOpenOrder',
  throttle(async ({ orderPayload, setStatusCallback }: IUpdateOrderParams, { dispatch, extra, getState }: any) => {
    setStatusCallback(RequestStatus.Pending);
    try {
      const { update_open_order } = await (extra.tradeService as TradeService).updateOpenOrder(orderPayload);
      novaToast.success(`Order ${update_open_order.instrument_id} updated successfully`);
      setStatusCallback(RequestStatus.Success);
      dispatch(getOrders({ type: OrdersGetTypes.Open }));
      const marketCurrency = selectUserMarketCurrency(getState());
      dispatch(getBalances({ marketCurrency, isShowLoader: true }));
    } catch (error) {
      handleErrorThunk(error, 'Order update failed', dispatch);
      setStatusCallback(RequestStatus.Failed);
    }
  }, 2000)
) as unknown as ({ orderPayload, setStatusCallback }: IUpdateOrderParams) => AnyAction;

export const executeConversion = createAsyncThunk(
  'trade/executeConversion',
  throttle(async (conversionId: string, { dispatch, extra, getState }: any) => {
    dispatch(executeConversionStatus(RequestStatus.Pending));
    try {
      const { alias_create_conversion_order: create_conversion_order }: { alias_create_conversion_order: ExecutedConversionResult } = await (extra.tradeService as TradeService).executeConversion(conversionId);

      if (create_conversion_order.status === OrderStatus.Rejected) {
        novaToast.error(`Conversion failed, ${create_conversion_order.message || 'try again please'}`);
      } else {
        batch(() => {
          const orderResult: OrderSummaryRender = {
            amount: String(create_conversion_order.source_currency_amount),
            amountProduct: create_conversion_order.source_currency_id,
            fee: create_conversion_order.fee_currency_amount,
            feeProduct: create_conversion_order.fee_currency_id,
            total: create_conversion_order.target_currency_amount,
            totalProduct: String(create_conversion_order.target_currency_amount),
          }
          dispatch(orderSummaryRender(orderResult));
          dispatch(executeConversionStatus(RequestStatus.Success));
        });
      }
    } catch (error) {
      handleErrorThunk(error, 'Conversion failed', dispatch);
      dispatch(executeConversionStatus(RequestStatus.Failed));
    }
  }, 2000)
) as unknown as (conversionId: string) => AnyAction;
