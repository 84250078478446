import { IsActive, TradePager, TradeSide } from '#/types';

export enum DerivativesInstrumentsCType {
  Quote = 'quote',
  Base = 'base',
  CQCQ = 'cqcq', // cross quote
  CBCB = 'cbcb', // cross base
}
export enum EDerivativesInstrumentStrategy {
  Perpetual = 'perpetual',
  Futures = 'futures',
  }

export enum EDerivativesOrderType {
  Market = 'market',
  Limit = 'limit',
}

export enum EDerivativesOrderStatus {
  Open = 'open',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
}

export interface DerivativesInstrumentPrice {
  margin_instrument_id: string,
  ask: number,
  bid: number,
  updated_at: string,
}
export interface DerivativesCurrency {
  currency_id: string,
  precision: number,
  is_active: IsActive,
}

// ---- margin account configs
export interface IDerivativesInstrument {
  margin_instrument_id: string,
  type: DerivativesInstrumentsCType,
  name: string,
  base_currency_id: string,
  quote_currency_id: string,
  min_leverage: number,
  max_leverage: number,
  min_quantity: number,
  max_quantity: number,
  price_decimals: number,
  quantity_decimals: number,
  price: DerivativesInstrumentPrice,
  base_currency: DerivativesCurrency,
  quote_currency: DerivativesCurrency,
  funding_rate_1h: number,
  price_24h_change: number,
  price_24h_low: number,
  price_24h_high: number,
  cross_1_margin_instrument_id?: string,
  cross_2_margin_instrument_id?: string, 
}

export interface IDerivativesConfig {
  margin_currency: string,
}

export interface IDerivativesBalance {
  free_balance: number,
  liquidation_left_margin: number,
  margin_call_left_margin: number,
  currently_used_margin: number,
  margin_ratio_percentage: number,
  risk_level: string,
  equity: number,
  pnl: number,
}

export interface IAccountDerivativesData {
  max_position_size: number,
  order_required_margin: number,
  liquidation_left_margin: number,
  margin_call_left_margin: number,
  equity: number,
  pnl: number,
}

export interface IDerivativesStrategie {
  margin_instrument_id: string,
  type: EDerivativesInstrumentStrategy
  is_active: IsActive,
  //instrument_strategy_id
  //margin_hedging_adapter_id
  //remote_instrument_id
}

// ---- estimate trade
export interface IEstimatePositionPayload {
  instrument_id: string,
  amount: number,
  leverage: number,
  side: TradeSide,
}

export type TDrivativesOrderFee = {
  fee: number,
  value: string,
  source: string,
}

export interface IEstimatedPositionData {
  order_required_margin: number,
  fee: number,
  max_position_size: number,
  currently_used_margin: number,
  liquidation_left_margin: number,
  margin_call_left_margin: number,
  order_available_margin: number,
  fees_breakdown: Array<TDrivativesOrderFee>,
}

// ---- trade
export interface IUpdateOpenedPositionPayload {
  margin_position_id: string,
  leverage: number,
  stop_loss?: number | null,
  take_profit?: number | null,
}

export interface IUpdateOpenedDerivaivesOrderPayload {
  instrument_id: string,
  margin_order_id: string,
  leverage: number,
  quantity: number,
  price?: number,
  side?: TradeSide,
  stopLoss?: number,
  takeProfit?: number,
}

export interface IOpenPositionPayload {
  instrument_id: string,
  side: TradeSide,
  leverage: number,
  amount: number,
  limit_price?: number,
  stop_loss?: number,
  take_profit?: number,
}

export interface ICreateDerivativesOrderPayload {
  instrument_id: string,
  side: TradeSide,
  type: EDerivativesOrderType,
  leverage: number,
  quantity: number,
  price?: number,
  stop_loss?: number,
  take_profit?: number,
}

export interface IClosePositionPayload {
  margin_position_id: string,
}

export interface ICancelDerivativesOrderPayload {
  margin_order_id: string,
  instrument_id: string,
  amount: number,
  price?: number,
  side?: TradeSide,
}

// ---- history
export interface IGetOpenClosedPositionsPayload {
  pager?: TradePager,
}

export interface IDerivativesPosition {
  margin_position_id: string,
  // user_id: string,
  instrument_id: string,
  side: TradeSide,
  leverage: number,
  amount: number,
  position_fee_amount: number,
  funding_fee_amount: number,
  entry_price: number,
  limit_price?: number,
  active: IsActive,
  start_ts: string,
  end_ts?: string,
  start_price_ts: string,
  end_price_ts?: string,
  start_ask_price: number,
  start_bid_price: number,
  start_ts_timestamp: string,
  end_ask_price?: number,
  end_bid_price?: number,
  pnl: number,
  max_profit: number,
  max_loss: number,
  stop_loss?: number,
  take_profit?: number,
  on_limit: string,
  close_reason?: string,
  updated_at: string,
  margin_instrument: IDerivativesInstrument,
  exit_price: number,
}

export interface IDerivativesOrder {
  margin_order_id: string,
  margin_position_id: string,
  user_id: string,
  instrument_id: string,
  side: TradeSide,
  type: EDerivativesOrderType,
  status: EDerivativesOrderStatus,
  leverage: number,
  quantity: number,
  created_at: string,
  updated_at: string,
  created_at_timestamp: string,
  price?: number,
  message?: string,
  margin_instrument: IDerivativesInstrument,
  take_profit: number,
  stop_loss: number,
}

export interface IDerivativesInstrumentPriceBar {
  margin_instrument_id: string,
  high: number,
  low: number,
  updated_at: string,
  updated_at_iso: string,
  open: number,
  close: number,
}