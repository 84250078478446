import * as gql from 'gql-query-builder';

import { 
  derivativesOpenClosedPositionsFields,
  subscribeDerivativesOrderbookFields,
  subscribeDerivativesUserBalancePositionFields,
} from '../fields';
import { IGetOpenClosedPositionsPayload } from '../dto';


export enum DerivativesPositionsOperations {
  // ---- query,
  OpenPositions = 'open_margin_positions',
  ClosedPositions = 'closed_margin_positions',
  // ---- mutations
  // ---- sunscription
  Orderbook = 'margin_orderbook',
  OpenOrdersUpdateWs = 'open_margin_position_update',
  UserBalancePositionWs = 'user_balance_position',
}


export default {
  getOpenPositions: ({ pager }: IGetOpenClosedPositionsPayload) => {
    return gql.query({
      operation: DerivativesPositionsOperations.OpenPositions,
      fields: derivativesOpenClosedPositionsFields(),
      variables: {
        pager: { value: pager, required: false, type: 'PagerInput' },
      },
    });
  },

  getClosedPositions: ({ pager }: IGetOpenClosedPositionsPayload) => {
    return gql.query({
      operation: DerivativesPositionsOperations.ClosedPositions,
      fields: derivativesOpenClosedPositionsFields(true),
      variables: {
        pager: { value: pager, required: false, type: 'PagerInput' },
      },
    });
  },

  subscribeOrderbook: (instrument_id: string) => {   
    return gql.subscription({
      operation: DerivativesPositionsOperations.Orderbook,
      variables: {
        instrument_id: { value: instrument_id, required: true },
      },
      fields: subscribeDerivativesOrderbookFields,
    });
  },

  subscribeOpenPositionsUpdate: () => {   
    return gql.subscription({
      operation: DerivativesPositionsOperations.OpenOrdersUpdateWs,
      variables: {},
      fields: derivativesOpenClosedPositionsFields(),
    });
  },

  subscribeUserBalancePosition: () => {   
    return gql.subscription({
      operation: DerivativesPositionsOperations.UserBalancePositionWs,
      variables: {},
      fields: subscribeDerivativesUserBalancePositionFields,
    });
  },
}