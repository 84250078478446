import { useEffect, useState } from 'react';

interface Props {
  idElement: string,
  onClickOutside: (e: any) => void,
}

export const useClickOutside = ({ idElement, onClickOutside }: Props) => {
  const [listen, setListen] = useState<boolean>(false);

  const onOutside = (e: any) => {
    if (!e.target.closest(`#${idElement}`) && listen) {
      onClickOutside(e);
    }
  }

  const subscribe = () => document.addEventListener('mousedown', onOutside);
  const unsubscribe = () => document.removeEventListener('mousedown', onOutside);

  useEffect(() => {
    listen ? subscribe() : unsubscribe();
    return () => unsubscribe();
  }, [listen]);

  return {
    listen,
    setListen,
  }
}
