import { Themes } from '#/types/enums';
import { selectSiteTheme } from '#reducers/config';
import { useSelector } from 'react-redux';
import Logo from '../components/other/logo/Logo';
import customLoader from '../../assets/images/custom-loader.gif';
import './fallback.scss';

const NovaFallback = () => (
  <div className="fallback">
    <Logo isDark={useSelector(selectSiteTheme) === Themes.Dark} isLoading={true}/>
    <img src={customLoader} className='custom-loader' />
    {/* <span className='loader'></span> */}
  </div>
);

export default NovaFallback;
