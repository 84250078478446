import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types/enums';
import { getSavedDerivativesInstrumentStorage, initialStateDerivativesInstruments, saveDerivativesInstrumentStorage } from './helper';
import { DerivativesInstrumentsState } from './types';
import { IDerivativesInstrument, IDerivativesStrategie, IDerivativesPosition } from '#/api/derivatives/dto';
import { IDerivativesInstrumentPriceBar } from './thunks';

const derivativesInstrumentsSlice = createSlice({
  name: 'derivativesInstruments',
  initialState: initialStateDerivativesInstruments,
  reducers: {
    derivativesInstrumentsRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.derivativesInstrumentsRequestStatus = action.payload;
    },
    updateDerivativesSelectedInstrument(state, action: PayloadAction<IDerivativesInstrument>) {
      state.selectedDerivativesInstrument = action.payload;
      saveDerivativesInstrumentStorage(action.payload.name);
    },
    updateDerivativesSelectedInstrumentLastMidPrice(state, action: PayloadAction<string>) {
      state.last_mid_price = action.payload;
    },
    updateSelectedDerivativesInstrumentPriceBars(state, action: PayloadAction<IDerivativesInstrumentPriceBar[]>) {
      state.selectedInstrumentPriceBars = action.payload
    },
    updateDerivativesInstruments(state, action: PayloadAction<IDerivativesInstrument[]>) {
      const arrayInstruments = [...action.payload];
      state.derivativesInstruments = arrayInstruments;

      if (!state.selectedDerivativesInstrument.margin_instrument_id && arrayInstruments.length) {
        state.selectedDerivativesInstrument = arrayInstruments.find((_) => _.name === getSavedDerivativesInstrumentStorage()) || arrayInstruments[0];
      }
    },
    updateDerivativesInstrumentsStrategies(state, action: PayloadAction<IDerivativesStrategie[]>) {
      state.derivativesInstrumentsStrategies = action.payload;
    },
    updateDerivativesSelectedInstrumentOpenedPosition(state, action: PayloadAction<IDerivativesPosition>) {
      state.selectedDerivativesInstrumentOpenedPosition = action.payload;
    },
    updateDerivativesInstrumentHeaderData(state, action: PayloadAction<IDerivativesInstrument>) {
      state.selectedDerivativesInstrumentHeaderData = action.payload;
    },
  },
});

export const {
  derivativesInstrumentsRequestStatus,
  updateDerivativesInstruments,
  updateDerivativesSelectedInstrument,
  updateDerivativesSelectedInstrumentLastMidPrice,
  updateDerivativesSelectedInstrumentOpenedPosition,
  updateSelectedDerivativesInstrumentPriceBars,
  updateDerivativesInstrumentsStrategies,
  updateDerivativesInstrumentHeaderData,
} = derivativesInstrumentsSlice.actions;

export default derivativesInstrumentsSlice.reducer;

export const selectDerivativesInstrumentsState = (state: Store): DerivativesInstrumentsState => state.derivatives.derivativesInstruments;

export const selectDerivativesInstruments: (store: Store) => IDerivativesInstrument[] = createDraftSafeSelector(
  selectDerivativesInstrumentsState,
  (instruments: DerivativesInstrumentsState) => instruments.derivativesInstruments,
);

export const selectDerivativesInstrumentsStrategies: (store: Store) => IDerivativesStrategie[] = createDraftSafeSelector(
  selectDerivativesInstrumentsState,
  (instruments: DerivativesInstrumentsState) => instruments.derivativesInstrumentsStrategies,
);

export const selectDerivativesInstrumentsRequestStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativesInstrumentsState,
  (instruments: DerivativesInstrumentsState) => instruments.derivativesInstrumentsRequestStatus,
);

export const selectSelectedDerivativesInstrument: (store: Store) => IDerivativesInstrument = createDraftSafeSelector(
  selectDerivativesInstrumentsState,
  (instrumentsState: DerivativesInstrumentsState) => instrumentsState.selectedDerivativesInstrument,
);

export const selectSelectedDerivativesInstrumentMidPrice: (store: Store) => string | null = createDraftSafeSelector(
  selectDerivativesInstrumentsState,
  (instrumentsState: DerivativesInstrumentsState) => instrumentsState.last_mid_price,
);

export const selectSelectedDerivativesInstrumentPriceBars: (store: Store) => IDerivativesInstrumentPriceBar[] = createDraftSafeSelector(
  selectDerivativesInstrumentsState,
  (instrumentsState: DerivativesInstrumentsState) => instrumentsState.selectedInstrumentPriceBars,
);

export const selectSelectedDerivativesInstrumentOpenedPosition: (store: Store) => IDerivativesPosition = createDraftSafeSelector(
  selectDerivativesInstrumentsState,
  (instrumentsState: DerivativesInstrumentsState) => instrumentsState.selectedDerivativesInstrumentOpenedPosition,
);

export const selectUpdatedDerivativesInstrumentHeaderData: (store: Store) => IDerivativesInstrument = createDraftSafeSelector(
  selectDerivativesInstrumentsState,
  (instrumentsState: DerivativesInstrumentsState) => instrumentsState.selectedDerivativesInstrumentHeaderData,
);