import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectParentUserID, selectUserID } from '#reducers/user/user';
import { claimReferral } from '#reducers/referral';

import { EReferralSearchParams, getReferralCodeFromStorage } from './helpers';

// referral can be claimed after success login
export const useReferralClaim = () => {
  const dispatch = useDispatch();

  const userId: string = useSelector(selectUserID);
  const parentUserId: string | null = useSelector(selectParentUserID);

  useEffect(() => {
    const referralCode: string | null = getReferralCodeFromStorage();

    // in case there is a referral code and the current user is not related to another - claim referral and remove code from ls
    if (userId && referralCode && !parentUserId) {
      dispatch(claimReferral({ referral_id: referralCode }));

      localStorage.removeItem(EReferralSearchParams.Code);
    }
  }, [userId, parentUserId]);
};
