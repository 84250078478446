import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types/enums';
import { initialStateDerivativesConfig } from './helper';
import { DerivativesConfigState } from './types';
import { IDerivativesConfig } from '#/api/derivatives/dto';

const derivativesConfigSlice = createSlice({
  name: 'marginConfig',
  initialState: initialStateDerivativesConfig,
  reducers: {
    derivativesConfigRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.derivativesConfigRequestStatus = action.payload;
    },
    updateDerivativesConfig(state, action: PayloadAction<IDerivativesConfig>) {
      const { margin_currency } = action.payload;
      state.derivativesConfig = { margin_currency };
    },
    updateIsOpenedOrderbook(state, action: PayloadAction<boolean>) {
      state.isOpenedOrderbook = action.payload;
    },
    updateIsOnOpenedPositionTab(state, action: PayloadAction<boolean>) { // TODO check to remove
      state.isOnOpenedPositionsTab = action.payload;
    },
  },
});

export const {
  derivativesConfigRequestStatus,
  updateDerivativesConfig,
  updateIsOpenedOrderbook,
  updateIsOnOpenedPositionTab,
} = derivativesConfigSlice.actions;

export default derivativesConfigSlice.reducer;

export const selectDerivativesConfigState = (state: Store): DerivativesConfigState => state.derivatives.derivativesConfig;

export const selectDerivativesConfig: (store: Store) => IDerivativesConfig = createDraftSafeSelector(
  selectDerivativesConfigState,
  (config: DerivativesConfigState) => config.derivativesConfig,
);

export const selectDerivativesConfigRequestStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativesConfigState,
  (config: DerivativesConfigState) => config.derivativesConfigRequestStatus,
);

export const selectIsOpenedMarginOrderbook: (store: Store) => boolean = createDraftSafeSelector(
  selectDerivativesConfigState,
  (config: DerivativesConfigState) => config.isOpenedOrderbook,
);

export const selectIsOnOpenedPosition: (store: Store) => boolean = createDraftSafeSelector(
  selectDerivativesConfigState,
  (config: DerivativesConfigState) => config.isOnOpenedPositionsTab,
);