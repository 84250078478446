import * as gql from 'gql-query-builder';

import { calculatePaymentsLimitsFields } from './fields';
import { GetCalculatedPaymentLimitsPaylod } from './dto';

export enum WalletsOperations {
  CalculatePaymentsLimits = 'calculate_payments_limits',
}

export default {
  getCalculatedPaymentLimits: (params: GetCalculatedPaymentLimitsPaylod) => {
    return gql.mutation({
      operation: WalletsOperations.CalculatePaymentsLimits,
      variables: {
        currency_id: { value: params.currencyId, required: true, type: 'String' },
        type: { value: params.paymentType, required: true, type: 'PaymentType' },
        notion_currency: { value: params.currencyId, required: true, type: 'String' },
      },
      fields: calculatePaymentsLimitsFields,
    });
  },
};