import React from 'react';

import { FiatFields, FiatWalletFields, NovaElementsOptions, NovaElementsSizes } from '#/types';
import { BankFields, ICountry } from '../../NovaBankFields';
import NovaTextInput from '#/nova/components/controls/form/text-input/NovaTextInput';
import NovaTradeTextField from '#/nova/components/controls/form/trade-text-field/NovaTradeTextField';
import NovaTradeLabel from '#/nova/components/text/trade-label/NovaTradeLabel';
import Select from 'react-select';
import NovaButton from '#/nova/components/controls/button/NovaButton';
import { checkIsRequiredFiatFields } from '#/nova/components/other/wallet/payments/add-modal/helpers';

import './withdrawFiatArionFields.scss';

export const requiredAddressArionFields = [
  FiatFields.BankCountry,
  FiatFields.BankAddress,
  FiatFields.BankCity,
  FiatFields.BeneficiaryName,
  FiatFields.BeneficiaryAccountNumber,
  FiatFields.BankBic,
]

export const requiredArionWalletFields = [
  FiatWalletFields.BankCountry,
  FiatWalletFields.BankAddress,
  FiatWalletFields.BankCity,
  FiatWalletFields.BeneficiaryName,
  FiatWalletFields.BeneficiaryAccountNumber,
  FiatWalletFields.BankBic,
]

export const arionFiatProperties = [
  ...requiredArionWalletFields,
]

export interface IWithdrawFiatWireFieldsProps {
  fields: BankFields,
  setFields: (fields: BankFields) => void,
  getFieldKey: (fieldKeyAddressbook: FiatFields) => string,
  getFieldValue: (field: FiatFields) => string,
  onFieldTouch: (field: FiatFields) => () => void,
  defineFieldClassName: (field: FiatFields) => string,
  renderErrorMessage: (field: FiatFields) => string,
  setOpenModalFiat: (value: boolean) => void,
  countryList: ICountry[],
  showAddressbookImportButton: boolean,
}

const WithdrawFiatArionFields = (
  {
    fields,
    countryList,
    setFields,
    getFieldKey,
    getFieldValue,
    onFieldTouch,
    defineFieldClassName,
    renderErrorMessage,
    setOpenModalFiat,
    showAddressbookImportButton,
  }: IWithdrawFiatWireFieldsProps) => {

  return (
    <>
      {showAddressbookImportButton && (
        <NovaButton
          onClick={() => setOpenModalFiat(true)}
          className="nova-wallet-address-book"
          variant={NovaElementsOptions.Secondary}
          size={NovaElementsSizes.MediumSmall}
          value={_t('Import from Fiat Address Book', 'NOVA_WITHDRAW.IMPORT_FIAT_ADDRESS_BOOK')}
        />
      )}

      <NovaTradeTextField
        className={defineFieldClassName(FiatFields.BeneficiaryName)}
        labelMain={<NovaTradeLabel
          isRequired={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryName, requiredArionWalletFields)}
          label={_t('Customer name', 'NOVA_WITHDRAW.CUSTOMER_NAME')} />}
        value={getFieldValue(FiatFields.BeneficiaryName) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryName)]: e.target.value })}
        placeholder={''}
        error={renderErrorMessage(FiatFields.BeneficiaryName)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryName)}
      />

      <NovaTextInput
        label={_t('Bank Address', 'NOVA_WITHDRAW.BANK_STREET_ADDRESS')}
        value={getFieldValue(FiatFields.BankAddress) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankAddress)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BankAddress, requiredArionWalletFields)}
        detailsError={renderErrorMessage(FiatFields.BankAddress)}
        className={defineFieldClassName(FiatFields.BankAddress)}
        onBlur={onFieldTouch(FiatFields.BankAddress)}
      />

      <NovaTextInput
        label={_t('Bank City', 'NOVA_WITHDRAW.BANK_CITY')}
        value={getFieldValue(FiatFields.BankCity) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankCity)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BankCity, requiredArionWalletFields)}
        detailsError={renderErrorMessage(FiatFields.BankCity)}
        className={defineFieldClassName(FiatFields.BankCity)}
        onBlur={onFieldTouch(FiatFields.BankCity)}
      />

      <div className="input-label">
        <span>{_t('Bank Country', 'NOVA_WITHDRAW.BANK_COUNTRY')}</span><span className='form-required'>*</span>
        <span className="input-error-message">{renderErrorMessage(FiatFields.BankCountry)}</span>
      </div>
      <Select
        value={{ value: getFieldValue(FiatFields.BankCountry), label: getFieldValue(FiatFields.BankCountry) }}
        onChange={(e: any) => setFields({ ...fields, [getFieldKey(FiatFields.BankCountry)]: e.value })}
        options={countryList}
        className={`select-container ${defineFieldClassName(FiatFields.BankCountry)}`}
        classNamePrefix="country-select"
        required={checkIsRequiredFiatFields(FiatWalletFields.BankCountry, requiredArionWalletFields)}
        onBlur={onFieldTouch(FiatFields.BankCountry)}
      />

      <NovaTextInput
        required={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryAccountNumber, requiredArionWalletFields)}
        label={_t('Account number (IBAN)', 'NOVA_WITHDRAW.BANK_BENEFICIARY_ACCOUNT_IBAN')}
        value={getFieldValue(FiatFields.BeneficiaryAccountNumber) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryAccountNumber)]: e.target.value })}
        detailsError={renderErrorMessage(FiatFields.BeneficiaryAccountNumber)}
        className={defineFieldClassName(FiatFields.BeneficiaryAccountNumber)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryAccountNumber)}
      />

      <NovaTradeTextField
        labelMain={<NovaTradeLabel
          isRequired={checkIsRequiredFiatFields(FiatWalletFields.BankBic, requiredArionWalletFields)}
          label={_t('Swift(BIC code)/SC/FW/ABA', 'NOVA_WITHDRAW.SWIFT_BIC_CODE_SC_FW_ABA')} />}
        value={getFieldValue(FiatFields.BankBic) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankBic)]: e.target.value })}
        maxLength={12}
        placeholder={''}
        error={renderErrorMessage(FiatFields.BankBic)}
        //length conditions for overriding styles so that the error does not intersect with the field label
        className={`${defineFieldClassName(FiatFields.BankBic)} ${renderErrorMessage(FiatFields.BankBic).length > 15 ? 'swift-arion-field' : ''}`}
        onBlur={onFieldTouch(FiatFields.BankBic)}
      />
    </>
  );
};

export default WithdrawFiatArionFields;