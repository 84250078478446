import walletsGql from './wallets-gql';
import { GqlClient } from '../gql';

import { GetCalculatedPaymentLimitsPaylod } from './dto';

import { CalculatedPaymentsLimits } from '#reducers/wallets/wallets';

export default class WalletsService {
  public gqlRequestClient: GqlClient;

  constructor(gqlRequestClient: GqlClient) {
    this.gqlRequestClient = gqlRequestClient;
  }

  public getCurrentPaymentLimits(
    params: GetCalculatedPaymentLimitsPaylod,
  ): Promise<{ calculate_payments_limits: CalculatedPaymentsLimits }> {
    return this.gqlRequestClient.request(walletsGql.getCalculatedPaymentLimits(params));
  }
}
