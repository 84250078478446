import instruments from './derivatives-gql/instruments';
import positions from './derivatives-gql/positions';
import trade from './derivatives-gql/trade';
import orders from './derivatives-gql/orders';

export default {
  ...instruments,
  ...positions,
  ...orders,
  ...trade,
}