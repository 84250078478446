import { GqlClient } from '../gql';
import GqlConnection, { HeadersClient, RequestCallbacks } from '#/api/gql/connection';

import derivativesTradeQql from './derivatives-gql';
import { 
  IClosePositionPayload,
  IEstimatePositionPayload,
  IEstimatedPositionData,
  IDerivativesConfig,
  IDerivativesInstrument,
  IGetOpenClosedPositionsPayload,
  IDerivativesPosition,
  IDerivativesInstrumentPriceBar,
  IUpdateOpenedPositionPayload,
  IAccountDerivativesData,
  IDerivativesBalance,
  IDerivativesStrategie,
  IDerivativesOrder,
  ICreateDerivativesOrderPayload,
  ICancelDerivativesOrderPayload,
  IUpdateOpenedDerivaivesOrderPayload,
} from './dto';
import { DerivativesPositionsOperations } from './derivatives-gql/positions';
import { DerivativesInstrumentOperations } from './derivatives-gql/instruments';
import { DerivativesTradeOperations } from './derivatives-gql/trade';
import { ClientWithInstrument } from '#/api/trade/TradeService';

import configEnv from '#/config/config-env';
import { Periodicity } from '#/types';
import { PriceBarsPayload } from '#/api/trade/dto';
import { DerivativesOrdersOperations } from './derivatives-gql/orders';

export default class DerivativesService {
  public gqlRequestClient: GqlClient;
  public orderBookWsClient: ClientWithInstrument;
  public openedPositionsWsClient: GqlConnection;
  public userBalancePositionWsClient: GqlConnection;
  public ordersWsClient: GqlConnection;
  public priceBars: { [instrument: string]: GqlConnection };

  constructor(gqlRequestClient: GqlClient) {
    this.priceBars = {};
    this.gqlRequestClient = gqlRequestClient;
    this.orderBookWsClient = { client: new GqlConnection(this._getGqlHeaders()), instrument: null };
    this.openedPositionsWsClient = new GqlConnection(this._getGqlHeaders());
    this.userBalancePositionWsClient = new GqlConnection(this._getGqlHeaders());
    this.ordersWsClient = new GqlConnection(this._getGqlHeaders());
  }

  private _getGqlHeaders(headers?: HeadersClient, authorization?: string) {
    return {
      ...(headers || {}),
      authorization: authorization || this.gqlRequestClient.authorization,
    }
  }

  // ---- instruments
  public getDerivativesIntruments(): Promise<{ [DerivativesInstrumentOperations.Instruments]: Array<IDerivativesInstrument> }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getDerivativesInstruments());
  }

  public getDerivativesConfig(): Promise<{ [DerivativesInstrumentOperations.Config]: IDerivativesConfig }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getDerivativesConfig());
  }

  public getDerivativesPriceBars(params: PriceBarsPayload): Promise<{ [DerivativesInstrumentOperations.PriceBars]: Array<IDerivativesInstrumentPriceBar> }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getDerivativesPriceBars(params));
  }

  public getDerivativesInstrumentsStrategies(): Promise<{ [DerivativesInstrumentOperations.Strategies]: Array<IDerivativesStrategie> }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getDerivativesInstrumentsStrategies());
  }

  // ---- orders
  public getOpenPositions(params: IGetOpenClosedPositionsPayload): Promise<{ [DerivativesPositionsOperations.OpenPositions]: Array<IDerivativesPosition> }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getOpenPositions(params));
  }

  public getClosedPositions(params: IGetOpenClosedPositionsPayload): Promise<{ [DerivativesPositionsOperations.ClosedPositions]: Array<IDerivativesPosition> }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getClosedPositions(params));
  }

  // ---- trade
  public closePosition(params: IClosePositionPayload): Promise<{ [DerivativesTradeOperations.ClosePosition]: boolean }> {
    return this.gqlRequestClient.request(derivativesTradeQql.closePosition(params));
  }

  public closeAllPositions(): Promise<{ [DerivativesTradeOperations.CloseAllPositions]: boolean }> {
    return this.gqlRequestClient.request(derivativesTradeQql.closeAllPositions());
  }

  public estimatePosition(params: IEstimatePositionPayload): Promise<{ [DerivativesTradeOperations.EstimatePosition]: IEstimatedPositionData }> {
    return this.gqlRequestClient.request(derivativesTradeQql.estimatePosition(params));
  }

  public availableMargin(params: IEstimatePositionPayload): Promise<{ [DerivativesTradeOperations.EstimatePosition]: IEstimatedPositionData }> {
    return this.gqlRequestClient.request(derivativesTradeQql.availableMargin(params));
  }

  public getDerivativesBalance(params: IEstimatePositionPayload): Promise<{ [DerivativesTradeOperations.EstimatePosition]: IDerivativesBalance }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getDerivativesBalance(params));
  }

  public getAccountDerivativesData(params: IEstimatePositionPayload): Promise<{ [DerivativesTradeOperations.EstimatePosition]: IAccountDerivativesData }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getAccountDerivativesData(params));
  }

  public updateOpenedPosition(params: IUpdateOpenedPositionPayload): Promise<{ [DerivativesTradeOperations.UpdateOpenedPosition]: IDerivativesPosition }> {
    return this.gqlRequestClient.request(derivativesTradeQql.updateOpenedPosition(params));
  }

  // ---- orders
  public getDerivativesOpenOrders(params: IGetOpenClosedPositionsPayload): Promise<{ [DerivativesOrdersOperations.OpenOrders]: Array<IDerivativesOrder> }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getDerivativesOpenOrders(params));
  }

  public getDerivativesClosedOrders(params: IGetOpenClosedPositionsPayload): Promise<{ [DerivativesOrdersOperations.ClosedOrders]: Array<IDerivativesOrder> }> {
    return this.gqlRequestClient.request(derivativesTradeQql.getDerivativesClosedOrders(params));
  }

  public createDerivativesOrder(params: ICreateDerivativesOrderPayload): Promise<{ [DerivativesTradeOperations.CreateOrder]: IDerivativesOrder }> {
    return this.gqlRequestClient.request(derivativesTradeQql.createDerivativesOrder(params));
  }

  public updateOpenedDerivativesOrder(params: IUpdateOpenedDerivaivesOrderPayload): Promise<{ [DerivativesTradeOperations.UpdateOpenedOrder]: boolean }> {
    return this.gqlRequestClient.request(derivativesTradeQql.updateOpenedOrder(params));
  }

  public cancelDerivativesOrder(params: ICancelDerivativesOrderPayload): Promise<{ [DerivativesTradeOperations.CancelOrder]: boolean }> {
    return this.gqlRequestClient.request(derivativesTradeQql.cancelDerivativesOrder(params));
  }

  public cancelAllDerivativesOrders(): Promise<{ [DerivativesTradeOperations.CancelAllOrders]: boolean }> {
    return this.gqlRequestClient.request(derivativesTradeQql.cancelAllDerivativesOrders());
  }

  // ---- websockets
  public subscribeOrderbook(unsubscribe: boolean, instrument_id: string, callbacks: RequestCallbacks) {
    if (unsubscribe) {
      this.orderBookWsClient.instrument = null;
      this.orderBookWsClient.client.disconnect();
      return
    }

    if (instrument_id !== this.orderBookWsClient.instrument && instrument_id) {
      this.orderBookWsClient.client.disconnect();
      this.orderBookWsClient = { client: new GqlConnection(this._getGqlHeaders()), instrument: null }; 
      this.orderBookWsClient.instrument = instrument_id;
      this.orderBookWsClient.client.subscribe(derivativesTradeQql.subscribeOrderbook(instrument_id), callbacks, configEnv.derivativesTradeWs);
    }
  }

  public subscribeUserBalancePosition(callbacks: RequestCallbacks, unsubscribe: boolean) {
    if (unsubscribe) {
      this.userBalancePositionWsClient.disconnect();
      return
    }
    this.userBalancePositionWsClient = new GqlConnection(this._getGqlHeaders());    
    this.userBalancePositionWsClient.subscribe(derivativesTradeQql.subscribeUserBalancePosition(), callbacks, configEnv.derivativesTradeWs);
  }

  public subscribeOpenPositionsUpdate(callbacks: RequestCallbacks, unsubscribe: boolean) {
    if (unsubscribe) {
      this.openedPositionsWsClient.disconnect();
      return
    }
    this.openedPositionsWsClient = new GqlConnection(this._getGqlHeaders());    
    this.openedPositionsWsClient.subscribe(derivativesTradeQql.subscribeOpenPositionsUpdate(), callbacks, configEnv.derivativesTradeWs);
  }

  public subscribeOrdersUpdate(callbacks: RequestCallbacks, unsubscribe: boolean) {
    if (unsubscribe) {
      this.ordersWsClient.disconnect();
      return
    }
    this.ordersWsClient = new GqlConnection(this._getGqlHeaders());    
    this.ordersWsClient.subscribe(derivativesTradeQql.subscribeOrdersUpdate(), callbacks, configEnv.derivativesTradeWs);
  }

  public subscribeInstrumentPriceBar({ instrument, periodicity, unsubscribe }: { instrument: string | Array<string>, periodicity: Periodicity, unsubscribe?: boolean }, callbacks: RequestCallbacks) {
    const subscribeFn = (_instrument: string) => {
      if (!this.priceBars[_instrument]) {
        this.priceBars[_instrument] = new GqlConnection(this._getGqlHeaders());
        this.priceBars[_instrument].subscribe(derivativesTradeQql.subscribeDerivativesInstrumentPriceBar(_instrument, periodicity), callbacks,  configEnv.derivativesTradeWs);
      }
    }

    const unsubscribeFn = (_instrument: string) => {
      this.priceBars[_instrument]?.disconnect();
      this.priceBars[_instrument] = undefined as unknown as GqlConnection;
    }

    const subscribeUnsubscribeFn = unsubscribe ? unsubscribeFn : subscribeFn;

    Array.isArray(instrument)
      ? instrument.forEach((_instrument) => subscribeUnsubscribeFn(_instrument))
      : subscribeUnsubscribeFn(instrument);
  }

  public unsubscribeOrderbook() {
    this.orderBookWsClient.instrument = null;
    this.orderBookWsClient.client?.disconnect();
  }
}