import * as gql from 'gql-query-builder';

import { 
  derivativesOpenClosedOrdersFields,
} from '../fields';
import { IGetOpenClosedPositionsPayload } from '../dto';


export enum DerivativesOrdersOperations {
  // ---- query,
  OpenOrders = 'open_margin_orders',
  ClosedOrders = 'closed_margin_orders',
  // ---- mutations
  // ---- sunscription
  OrdersUpdateWs = 'margin_order_update',
}

export default {
  getDerivativesOpenOrders: ({ pager }: IGetOpenClosedPositionsPayload) => {
    return gql.query({
      operation: DerivativesOrdersOperations.OpenOrders,
      fields: derivativesOpenClosedOrdersFields,
      variables: {
        pager: { value: pager, required: false, type: 'PagerInput' },
      },
    });
  },

  getDerivativesClosedOrders: ({ pager }: IGetOpenClosedPositionsPayload) => {
    return gql.query({
      operation: DerivativesOrdersOperations.ClosedOrders,
      fields: derivativesOpenClosedOrdersFields,
      variables: {
        pager: { value: pager, required: false, type: 'PagerInput' },
      },
    });
  },

  subscribeOrdersUpdate: () => {   
    return gql.subscription({
      operation: DerivativesOrdersOperations.OrdersUpdateWs,
      variables: {},
      fields: derivativesOpenClosedOrdersFields,
    });
  },
}