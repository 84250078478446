import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createReferral,
  getReferrals,
  selectCreateReferralStatus,
  selectGetReferralStatus,
  selectReferralCode,
} from '#reducers/referral';
import { selectUserID } from '#reducers/user/user';

import { RequestStatus } from '#/types';
import { Paths } from '#/config/templates/nova/nova';
import { EReferralSearchParams } from './helpers';

interface IUseReferralInviteResult {
  referralLink: string,
  isLoading: boolean,
  isReferralCreateLoading: boolean,
  generateNewReferralCode: () => void,
}

export const useReferralInvite = (): IUseReferralInviteResult => {
  const dispatch = useDispatch();

  const userId: string = useSelector(selectUserID);
  const referralCode: string = useSelector(selectReferralCode);
  const getLinkStatus: RequestStatus = useSelector(selectGetReferralStatus);
  const createLinkStatus: RequestStatus = useSelector(selectCreateReferralStatus);

  useEffect(() => {
    dispatch(getReferrals());
  }, []);

  const generateNewReferralCode = useCallback(() => {
    dispatch(createReferral({ user_id: userId }));
  }, [userId]);

  return {
    referralLink: referralCode ? `${window.location.origin}${Paths.Login}?${EReferralSearchParams.Code}=${referralCode}` : '',
    isLoading: [RequestStatus.None, RequestStatus.Pending].includes(getLinkStatus),
    isReferralCreateLoading: RequestStatus.Pending === createLinkStatus,
    generateNewReferralCode,
  };
};
