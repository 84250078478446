import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import TradeService from '#/api/trade/TradeService';
import { setDepositCrypto, setDepositFiat, setDepositStatus } from './deposit';
import { RequestStatus } from '#/types/enums';
import { throttle, handleErrorThunk } from '#/util';
import { IDepositCryptoResult, IPaymentRoutes } from '#/types/interfaces';
import { CreateDepositCryptoPayload } from '#/api/trade/dto';
import { selectUserClientNotifications } from '#reducers/user/user';
import { eventCryptoDepositToast, eventCryptoDepositFiatToast } from '#reducers/user/user/toasts';

export const createDepositCrypto = createAsyncThunk(
  'wallets/createDepositCrypto',
  throttle(async (deposit: CreateDepositCryptoPayload, { dispatch, extra,  getState}: any) => {
    const state = getState();
    const notifications_settings = selectUserClientNotifications(state);

    dispatch(setDepositStatus(RequestStatus.Pending));
    try {
      const { deposit_address_crypto }: { deposit_address_crypto: IDepositCryptoResult } = await (extra.tradeService as TradeService).createDepositCrypto(deposit);
      batch(() => {
        dispatch(setDepositCrypto(deposit_address_crypto));
        dispatch(setDepositStatus(RequestStatus.Success));
      });
      eventCryptoDepositToast(notifications_settings)
    } catch (error) {
      dispatch(setDepositStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Create deposit crypto failed', dispatch);
    }
  }, 500),
) as unknown as (deposit: CreateDepositCryptoPayload) => AnyAction;

export const getFiatDepositBankDetails = createAsyncThunk(
  'wallets/getFIatDepositBankDetails',
  throttle(async ({ payment_route_id }: { payment_route_id: string }, { dispatch, extra, getState }: any) => {
    const state = getState();
    const notifications_settings = selectUserClientNotifications(state);
    dispatch(setDepositStatus(RequestStatus.Pending));
    try {
      const { payments_routes }: { payments_routes: IPaymentRoutes } = await (extra.tradeService as TradeService).getFiatDepositBankDetails({ payment_route_id });
      const [currentRoute] = payments_routes;
      batch(() => {
        dispatch(setDepositFiat(currentRoute.fiat_deposit_properties));
        dispatch(setDepositStatus(RequestStatus.Success));
      });
      eventCryptoDepositFiatToast(notifications_settings);
    } catch (error) {
      dispatch(setDepositStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get Fiat Deposit Bank Details failed', dispatch);
    }
  }, 500),
) as unknown as ({ payment_route_id }: { payment_route_id: string }) => AnyAction;
