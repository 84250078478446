import { IDepositFiatFundingMethod, IPaymentRoute, RequestStatus } from '#/types';

export interface IWalletsState {
  currency: string;
  currentPaymentLimits: CalculatedPaymentsLimits | null;
  currentPaymentLimitsStatus: RequestStatus;
}

export interface FiatPaymentMethodsToRender extends IPaymentRoute {
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  value: IDepositFiatFundingMethod;
  title: string;
}

export type LimitsStatusStructure = {
  limit: number;
  filled: number;
  remaining: number;
};

export type PaymentLimitStatusPeriods = {
  daily: LimitsStatusStructure;
  weekly: LimitsStatusStructure;
  monthly: LimitsStatusStructure;
};

export type CalculatedPaymentsLimits = {
  payments: PaymentLimitStatusPeriods;
  aggregated: PaymentLimitStatusPeriods;
};

export enum WalletsPrefixes {
  getCalculatedPaymentLimits = 'wallets/getCalculatedPaymentLimits',
}
