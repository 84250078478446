import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { IDepositWithdrawModalState } from './types';
import { initialDepositWithdrawModalState } from './helpers';

import { DepositWithdraw } from '#/types/enums';
import { Store } from '#/store/defaultState';
import { IPaymentRoute, VerificationType } from '#/store/reducers/trade/interfaces';

const depositWithdrawModalSlice = createSlice({
  name: 'depositWithdrawModalSlice',
  initialState: initialDepositWithdrawModalState,
  reducers: {
    setDepositWithdrawModalOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
      state.activeSide = DepositWithdraw.Deposit;
    },
    setDepositWithdrawModalActiveSide(state, action: PayloadAction<DepositWithdraw>) {
      state.activeSide = action.payload;
    },
    setDepositWithdrawModalCurrency(state, action: PayloadAction<string>) {
      state.currency = action.payload;
    },
    setDepositWithdrawModalPaymentRoute(state, action: PayloadAction<IPaymentRoute | undefined>) {
      state.paymentRoute = action.payload;
    },
    setIsWithdrawConfirmShown(state, action: PayloadAction<boolean>) {
      state.isWithdrawConfirmShown = action.payload;
    },
  },
});

export const {
  setDepositWithdrawModalOpen,
  setDepositWithdrawModalActiveSide,
  setDepositWithdrawModalCurrency,
  setDepositWithdrawModalPaymentRoute,
  setIsWithdrawConfirmShown,
} = depositWithdrawModalSlice.actions;

export default depositWithdrawModalSlice.reducer;

const selectDepositWithdrawState = (state: Store) => state.wallets.depositWithdrawModal;

export const selectDepositWithdrawModalOpen: (state: Store) => boolean = createDraftSafeSelector(
  selectDepositWithdrawState,
  (depositWithdraw: IDepositWithdrawModalState) => depositWithdraw.isOpen
);

export const selectDepositWithdrawModalActiveSide: (state: Store) => DepositWithdraw = createDraftSafeSelector(
  selectDepositWithdrawState,
  (depositWithdraw: IDepositWithdrawModalState) => depositWithdraw.activeSide
);

export const selectCurrency: (state: Store) => string = createDraftSafeSelector(
  selectDepositWithdrawState,
  (depositWithdraw: IDepositWithdrawModalState) => depositWithdraw.currency
);

export const selectPaymentRoute: (state: Store) => IPaymentRoute | undefined = createDraftSafeSelector(
  selectDepositWithdrawState,
  (depositWithdraw: IDepositWithdrawModalState) => depositWithdraw.paymentRoute
);

export const selectIsVerificationRequired: (state: Store) => boolean = createDraftSafeSelector(
  selectDepositWithdrawState,
  ({ paymentRoute }: IDepositWithdrawModalState) => !!paymentRoute && paymentRoute?.verification_type !== VerificationType.None
);

export const selectIsWithdrawConfirmShown: (state: Store) => boolean = createDraftSafeSelector(
  selectDepositWithdrawState,
  (depositWithdraw: IDepositWithdrawModalState) => depositWithdraw.isWithdrawConfirmShown
);
