import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { CryptoTypes, IPaymentRoute, IPaymentRoutes, IsActive, RequestStatus } from '#/types';
import { initialPaymentRoutesState } from './helpers';
import { ICurrencyRoutes, IPaymentRoutesState } from './types';

const paymentRoutesSlice = createSlice({
  name: 'paymentRoutes',
  initialState: initialPaymentRoutesState,
  reducers: {
    updatePaymentRoutesRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.paymentRoutesRequestStatus = action.payload;
    },
    updatePaymentRoutes(state, action: PayloadAction<{ [key: string]: ICurrencyRoutes }>) {
      state.paymentRoutes = action.payload;
      state.paymentRoutesRequestStatus = RequestStatus.Success;
    },
  },
});

export const {
  updatePaymentRoutesRequestStatus,
  updatePaymentRoutes,
} = paymentRoutesSlice.actions;

export default paymentRoutesSlice.reducer;

export const selectPaymentRoutesState = (state: Store): IPaymentRoutesState => state.trade.paymentRoutes;



export const selectCurrencyPaymentMethods = createDraftSafeSelector(
  selectPaymentRoutesState,
  (paymentRoutesState: IPaymentRoutesState) => (currency: string, isDevelopment: boolean) => {
    const paymentRoutes = paymentRoutesState.paymentRoutes;

    if (!currency || !Object.keys(paymentRoutes).length || !paymentRoutes[currency]) {
      return [];
    }

    return paymentRoutes[currency].paymentRoutes.filter((paymentRoute: IPaymentRoute) =>
      paymentRoute.is_active === IsActive.On && (isDevelopment || paymentRoute.is_development === IsActive.Off)
    );
  }
);

export const selectPaymentMethods = createDraftSafeSelector(
  selectPaymentRoutesState,
  (paymentRoutesState: IPaymentRoutesState) => (isDevelopment: boolean) => {
    const paymentRoutes = paymentRoutesState.paymentRoutes;

    if (!Object.keys(paymentRoutes).length) {
      return {};
    }

    const filteredPaymentRoutes = Object.entries(paymentRoutes).filter(([_, { paymentRoutes }]) =>
      paymentRoutes.filter(route =>
        route.is_active === IsActive.On && (isDevelopment || route.is_development === IsActive.Off)).length > 0);

    return Object.fromEntries(filteredPaymentRoutes);
  }
);

export const selectPaymentMethodsArray: (state: Store) => ICurrencyRoutes[] = createDraftSafeSelector(
  selectPaymentRoutesState,
  (paymentRoutesState: IPaymentRoutesState) => {
    return [...Object.values(paymentRoutesState.paymentRoutes)]
  }
);

export const selectPaymentMethodsFiatArray: (state: Store) => ICurrencyRoutes[] = createDraftSafeSelector(
  selectPaymentMethodsArray,
  (arrayPaymentRoutes: ICurrencyRoutes[]) => {
    return arrayPaymentRoutes.filter((route) => route.type === CryptoTypes.Fiat)
  }
);

export const selectPaymentRoutesRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectPaymentRoutesState,
  (paymentRoutesState: IPaymentRoutesState) => paymentRoutesState.paymentRoutesRequestStatus,
);
