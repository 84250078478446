import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from '@reduxjs/toolkit';

import { debounce, handleErrorThunk, throttle } from '#/util'
import { RequestStatus, TradeSide } from '#/types/enums';
import { 
  ICancelDerivativesOrderPayload,
  IClosePositionPayload,
  ICreateDerivativesOrderPayload,
  IEstimatePositionPayload,
  IEstimatedPositionData,
  IUpdateOpenedDerivaivesOrderPayload,
  IUpdateOpenedPositionPayload,
} from '#/api/derivatives/dto';

import DerivativesService from '#/api/derivatives/DerivativesService';
import { 
  updateCloseMarginPositionRequest,
  updateOpenedMarginPositionRequest,
  updateAccountMarginData,
  updateOpenDerivativesOrderRequest,
  updateCancelDerivativesOrderRequest,
  updateMarginMaxPositionSize,
  updateAccountAvailableMargin,
} from './derivativesTrade';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import { getDerivativesOpenOrders } from '../derivativesOrders';
import { marginTradeSideTerms } from './helper';

export const openMarginPosition = createAsyncThunk(
  'derivatives/openMarginPosition',
  throttle(async (params: ICreateDerivativesOrderPayload, { dispatch, extra }: any) => {
    dispatch(updateOpenedMarginPositionRequest(RequestStatus.Pending));
    try { 
      await (extra.DerivativesService as DerivativesService).createDerivativesOrder(params);
      novaToast.success(`Position ${params.instrument_id} is opened`)
      dispatch(updateOpenedMarginPositionRequest(RequestStatus.Success));    
    } catch (error) {
      dispatch(updateOpenedMarginPositionRequest(RequestStatus.Failed));
      handleErrorThunk(error, 'Open margin position failed', dispatch);
    }
  }, 5000),
) as unknown as (params: ICreateDerivativesOrderPayload) => AnyAction;

export const closeMarginPosition = createAsyncThunk(
  'derivatives/closeMarginPosition',
  throttle(async (params: IClosePositionPayload, { dispatch, extra }: any) => {
    dispatch(updateCloseMarginPositionRequest(RequestStatus.Pending));
    try {      
      await (extra.DerivativesService as DerivativesService).closePosition(params);
      dispatch(updateCloseMarginPositionRequest(RequestStatus.Success));    
    } catch (error) {
      dispatch(updateCloseMarginPositionRequest(RequestStatus.Failed));
      handleErrorThunk(error, 'Close margin position failed', dispatch);
    }
  }, 5000),
) as unknown as (params: IClosePositionPayload) => AnyAction;

export const closeAllMarginPositions = createAsyncThunk(
  'derivatives/closeAllMarginPositions',
  throttle(async (_: any, { dispatch, extra }: any) => {
    dispatch(updateCloseMarginPositionRequest(RequestStatus.Pending));
    try {      
      const { close_all_margin_positions } = await (extra.DerivativesService as DerivativesService).closeAllPositions();
      novaToast.success(`All Positions are closed`)
      dispatch(updateCloseMarginPositionRequest(RequestStatus.Success));      
    } catch (error) {
      dispatch(updateCloseMarginPositionRequest(RequestStatus.Failed));
      handleErrorThunk(error, 'Close All Positions failed', dispatch);
    }
  }, 5000),
) as unknown as () => AnyAction;

interface EstimateMarginPositionThunkParams extends IEstimatePositionPayload {
  callback: (data: IEstimatedPositionData) => void,
  setIsLoading: (status: boolean) => void,
}

export const estimateMarginPosition = createAsyncThunk(
  'derivatives/estimateMarginPosition',
  debounce(300, async ({ 
    amount,
    instrument_id,
    leverage,
    side,
    callback,
    setIsLoading,
  }: EstimateMarginPositionThunkParams, { dispatch, extra }: any) => {
    setIsLoading(true);
    try {     
      const { estimate_margin_user_position } = await (extra.DerivativesService as DerivativesService).estimatePosition({ amount, instrument_id, leverage, side });
      callback(estimate_margin_user_position);
      setIsLoading(false);   
    } catch (error) {
      handleErrorThunk(error, 'Margin trade estimation failed', dispatch);
      setIsLoading(false);
    }
  })
) as unknown as (params: EstimateMarginPositionThunkParams) => AnyAction;

export const derivativesMaxPositionSize = createAsyncThunk(
  'derivatives/estimateMarginPosition',
  debounce(1500, async (_: unknown, { dispatch, extra }: any) => {
    try {     
      const { estimate_margin_user_position: { max_position_size } } = await (extra.DerivativesService as DerivativesService)
        .estimatePosition({ amount: 0, instrument_id: "BTCUSD", leverage: 10, side: TradeSide.Buy });
      dispatch(updateMarginMaxPositionSize(max_position_size));
    } catch (error) {
      handleErrorThunk(error, 'Margin trade max position size failed', dispatch);
    }
  })
) as unknown as () => AnyAction;

export const updateOpenedMarginPosition = createAsyncThunk(
  'derivatives/updateOpenedMarginPosition',
  throttle(async (params: IUpdateOpenedPositionPayload, { dispatch, extra }: any) => {
    try {      
      const { update_open_margin_position } = await (extra.DerivativesService as DerivativesService).updateOpenedPosition(params);

      novaToast.success(`Position has updated`, {
        [_t('ID', 'EXECUTE_ORDER.ID')]: `${update_open_margin_position.margin_position_id}`,
        [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${update_open_margin_position.amount}`,
        [_t('Side', 'NOVA_ORDER.SIDE')]: marginTradeSideTerms(update_open_margin_position.side),
      });
    } catch (error) {
      handleErrorThunk(error, 'Update open margin position is failed', dispatch);
    }
  }, 5000),
) as unknown as (params: IUpdateOpenedPositionPayload) => AnyAction;

export const updateOpenedDerivativesOrder = createAsyncThunk(
  'derivatives/updateOpenedDerivativesOrder',
  throttle(async (params: IUpdateOpenedDerivaivesOrderPayload, { dispatch, extra }: any) => {
    try {      
      await (extra.DerivativesService as DerivativesService).updateOpenedDerivativesOrder(params);

      novaToast.success(`Order ${params.instrument_id} has updated`, {
        [_t('ID', 'EXECUTE_ORDER.ID')]: `${params.margin_order_id}`,
        [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${params.quantity}`,
        [_t('Price', 'MARGING_ORDERS.PRICE')]: `${params.price}`,
        [_t('Side', 'NOVA_ORDER.SIDE')]: marginTradeSideTerms(params.side),
      });
      // MARGIN_TODO remove after ws implemenatation
      dispatch(getDerivativesOpenOrders({ pager: { limit: 7, offset: 0 } }));  
    } catch (error) {
      handleErrorThunk(error, 'Update open margin position is failed', dispatch);
    }
  }, 5000),
) as unknown as (params: IUpdateOpenedDerivaivesOrderPayload) => AnyAction;

export const getAccountDerivativesData = createAsyncThunk(
  'derivatives/getAccountDerivativesData',
  async (params: IEstimatePositionPayload, { dispatch, extra }: any) => {
    try {    
      const { estimate_margin_user_position } = await (extra.DerivativesService as DerivativesService).getAccountDerivativesData(params);      
      dispatch(updateAccountMarginData(estimate_margin_user_position));  
    } catch (error) {
      handleErrorThunk(error, 'Get Account Margin data failed', dispatch);
    }
  }
) as unknown as (params: IEstimatePositionPayload) => AnyAction;

// ---- orders thunk

export const createDerivativesOrder = createAsyncThunk(
  'derivatives/openOrder',
  throttle(async (params: ICreateDerivativesOrderPayload, { dispatch, extra }: any) => {
    dispatch(updateOpenDerivativesOrderRequest(RequestStatus.Pending));
    try {      
      await (extra.DerivativesService as DerivativesService).createDerivativesOrder(params);
      dispatch(updateOpenDerivativesOrderRequest(RequestStatus.Success));
    } catch (error) {
      dispatch(updateOpenDerivativesOrderRequest(RequestStatus.Failed));
      handleErrorThunk(error, 'Open order failed', dispatch);
    }
  }, 5000),
) as unknown as (params: ICreateDerivativesOrderPayload) => AnyAction;

export const cancelDerivativesOrder = createAsyncThunk(
  'derivatives/cancelDerivativesOrder',
  throttle(async (params: ICancelDerivativesOrderPayload, { dispatch, extra }: any) => {
    dispatch(updateCancelDerivativesOrderRequest(RequestStatus.Pending));
    try {      
      await (extra.DerivativesService as DerivativesService).cancelDerivativesOrder(params);
      dispatch(updateCancelDerivativesOrderRequest(RequestStatus.Success));

      // MARGIN_TODO remove after WS implementation
      setTimeout(() => {
        dispatch(getDerivativesOpenOrders({ pager: { limit: 7, offset: 0 } }));
      }, 1500);
      novaToast.success(`Order ${params.instrument_id} cancelled`, {
        [_t('ID', 'EXECUTE_ORDER.ID')]: `${params.margin_order_id}`,
        [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${params.amount}`,
        [_t('Price', 'MARGING_ORDERS.PRICE')]: `${params.price}`,
        [_t('Side', 'NOVA_ORDER.SIDE')]: marginTradeSideTerms(params.side),
      });
    } catch (error) {
      dispatch(updateCancelDerivativesOrderRequest(RequestStatus.Failed));
      handleErrorThunk(error, 'Cancel order is failed', dispatch);
    }
  }, 5000),
) as unknown as (params: ICancelDerivativesOrderPayload) => AnyAction;

export const closeAllDerivativesOrders = createAsyncThunk(
  'derivatives/cancelAllDerivativesOrders',
  throttle(async (_: any, { dispatch, extra }: any) => {
    dispatch(updateCloseMarginPositionRequest(RequestStatus.Pending));
    try {      
      await (extra.DerivativesService as DerivativesService).cancelAllDerivativesOrders();
      novaToast.success(`All orders are canceled`);
      dispatch(updateCancelDerivativesOrderRequest(RequestStatus.Success));      
    
      // MARGIN_TODO remove after WS implementation
      setTimeout(() => {
        dispatch(getDerivativesOpenOrders({ pager: { limit: 7, offset: 0 } }));
      }, 1500);
    } catch (error) {
      dispatch(updateCancelDerivativesOrderRequest(RequestStatus.Failed));
      handleErrorThunk(error, 'Cancel All Orders failed', dispatch);
    }
  }, 5000),
) as unknown as () => AnyAction;

// remove after ws update
export const updateDerivativesAvailableMargin = createAsyncThunk(
  'derivatives/updateAvailableMargin',
  debounce(100, async ({ 
    amount,
    instrument_id,
    leverage,
    side,
  }: IEstimatePositionPayload, { dispatch, extra }: any) => {
    try {     
      const { estimate_margin_user_position: { order_available_margin } } = await (extra.DerivativesService as DerivativesService).availableMargin({ amount, instrument_id, leverage, side }); 
      dispatch(updateAccountAvailableMargin(order_available_margin));
    } catch (error) {
      handleErrorThunk(error, 'Available Margin Update Failed', dispatch);
    }
  })
) as unknown as (params: IEstimatePositionPayload) => AnyAction;