import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types/enums';
import { initialStateDerivativesPositions } from './helper';
import { DerivativesPositionsState } from './types';
import { IDerivativesPosition } from '#/api/derivatives/dto';

const derivativesPositionsSlice = createSlice({
  name: 'derivativesPositions',
  initialState: initialStateDerivativesPositions,
  reducers: {
    positionsRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.openClosedPositionsRequest = action.payload;
    },
    updateOpenPositions(state, action: PayloadAction<IDerivativesPosition[]>) {
      state.openPositions = action.payload;
    },
    updateOpenPosition(state, action: PayloadAction<IDerivativesPosition>) {
      const stateOrders = [...state.openPositions];
      const updatedPosition = action.payload;
      const isPositionInList = !!stateOrders.find(position => position.margin_position_id === updatedPosition.margin_position_id);
      
      if (!isPositionInList) stateOrders.unshift(updatedPosition);

      state.openPositions = isPositionInList 
        ? stateOrders.map(position => position.margin_position_id === updatedPosition.margin_position_id ? updatedPosition : position)
        : stateOrders;
    },
    removeClosedPositionFromOpenedList(state, action: PayloadAction<IDerivativesPosition>) {
      const stateOrders = [...state.openPositions];
      const updatedPosition = action.payload;
      state.openPositions = stateOrders.filter(position => position.margin_position_id !== updatedPosition.margin_position_id);
    },
    updateClosedPositionsListWithClosedPosition(state, action: PayloadAction<IDerivativesPosition>) {
      const updatedPosition = action.payload;
      state.closedPositions.unshift(updatedPosition);
    },
    updateClosedPositions(state, action: PayloadAction<IDerivativesPosition[]>) {
      state.closedPositions = action.payload;
    },
    updateNextPositionsExists(state, action: PayloadAction<boolean>) {
      state.nextPagePositionsExists = action.payload;
    },
  },
});

export const {
  updateOpenPositions,
  updateClosedPositions,
  updateNextPositionsExists,
  positionsRequestStatus,
  updateOpenPosition,
  removeClosedPositionFromOpenedList,
  updateClosedPositionsListWithClosedPosition,
} = derivativesPositionsSlice.actions;

export default derivativesPositionsSlice.reducer;

export const selectDerivativesPositionsState = (state: Store): DerivativesPositionsState => state.derivatives.derivativesPositions;

export const selectDerivativesOpenPositions: (store: Store) => IDerivativesPosition[] = createDraftSafeSelector(
  selectDerivativesPositionsState,
  (positions: DerivativesPositionsState) => positions.openPositions,
);

export const selectDerivativesClosedPositions: (store: Store) => IDerivativesPosition[] = createDraftSafeSelector(
  selectDerivativesPositionsState,
  (positions: DerivativesPositionsState) => positions.closedPositions,
);

export const selectDerivativesPositionsRequestStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativesPositionsState,
  (instruments: DerivativesPositionsState) => instruments.openClosedPositionsRequest,
);

export const selectNextPageDerivativesPositionsExists: (state: Store) => boolean = createDraftSafeSelector(
  selectDerivativesPositionsState,
  (positions: DerivativesPositionsState) => positions.nextPagePositionsExists,
);