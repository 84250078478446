import { initialSettingsState, SettingsState } from '#reducers/settings';
import { ITradeState, initialTradeState } from '#reducers/trade/types';
import { initialUserWalletsState } from '#reducers/wallets/helpers';
import { UserWalletsState } from '#reducers/wallets/types';
import config, { Config } from '../config/config';
import { User, initialStateUser } from './reducers/user/user';
import { initialKycState, IKycState } from '#reducers/kyc';

import { intitialVaultsStatev2 } from '#/store/reducers/vaults/store/initialState';
import { IVaultsState } from '#/store/reducers/vaults/store/interface';

import { createMockStore } from 'redux-test-utils';
import { IHomeState, initialHomeState } from '#reducers/home/types';
import { IDerivativesState, initialDerivativesState } from '#reducers/derivatives/types';

import { ICryptoPayState, initialCryptoPayState } from '#/store/reducers/cryptoPay/types';
import { initialReferralState, IReferralState } from '#reducers/referral';

export interface Store {
  config: Config,
  user: User,
  wallets: UserWalletsState,
  settings: SettingsState,
  trade: ITradeState,
  derivatives: IDerivativesState,
  vaults_v2: IVaultsState,
  kyc: IKycState,
  home: IHomeState,
  cryptoPay: ICryptoPayState;
  referral: IReferralState;
}

const state: Store = {
  config,
  user: initialStateUser,
  wallets: initialUserWalletsState,
  settings: initialSettingsState,
  trade: initialTradeState,
  derivatives: initialDerivativesState,
  kyc: initialKycState,
  vaults_v2: intitialVaultsStatev2,
  home: initialHomeState,
  cryptoPay: initialCryptoPayState,
  referral: initialReferralState,
};

const mockStore = createMockStore(state);

export type MockStore = typeof mockStore;

export default state;
