import { CustomTimezones } from "../chart/datafeed-api";
import { getTimeZoneSettings } from "./getTimeZoneSettings";
import getTimezone from "./getTimezone";
import { TimeZoneName, TimeZoneTypes } from "./types";

const supportedTimeZones: CustomTimezones[] = [
  "Africa/Cairo",
  "Africa/Johannesburg",
  "Africa/Lagos",
  "America/Argentina/Buenos_Aires",
  "America/Bogota",
  "America/Caracas",
  "America/Chicago",
  "America/El_Salvador",
  "America/Juneau",
  "America/Lima",
  "America/Los_Angeles",
  "America/Mexico_City",
  "America/New_York",
  "America/Phoenix",
  "America/Santiago",
  "America/Sao_Paulo",
  "America/Toronto",
  "America/Vancouver",
  "Asia/Almaty",
  "Asia/Ashkhabad",
  "Asia/Bahrain",
  "Asia/Bangkok",
  "Asia/Chongqing",
  "Asia/Dubai",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Jakarta",
  "Asia/Jerusalem",
  "Asia/Kathmandu",
  "Asia/Kolkata",
  "Asia/Kuwait",
  "Asia/Muscat",
  "Asia/Qatar",
  "Asia/Riyadh",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Tehran",
  "Asia/Tokyo",
  "Atlantic/Reykjavik",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Brussels",
  "Europe/Copenhagen",
  "Europe/Helsinki",
  "Europe/Istanbul",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Vilnius",
  "Europe/Warsaw",
  "Europe/Zurich",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "Pacific/Fakaofo",
  "Pacific/Honolulu",
  "Pacific/Norfolk",
  "US/Mountain",
];

//get user timezone offset in format 'GMT+02:00'
export const getOffset = (timeZone: string) => {
  return new Intl.DateTimeFormat('en', {
    timeZoneName: TimeZoneTypes.LongOffset,
    timeZone
  }).formatToParts()
    .filter(el => el.type === TimeZoneName)[0].value
};

export const getChartTimezone = () => {
  const { timeZone } = getTimeZoneSettings();
  let userTimeZone; 

  if (timeZone) {
    userTimeZone = timeZone as CustomTimezones;
  }
 
  if (!userTimeZone) {
    userTimeZone = getTimezone() as CustomTimezones;
  }

  if (supportedTimeZones.includes(userTimeZone)) {
    return userTimeZone
  }

  //build timezone config obj in format {'GMT+02:00':'Europe/Vilnius'}
  const config = supportedTimeZones.reduce((configObj, timeZone) => {
    configObj[getOffset(timeZone)] = timeZone;
    return configObj
  }, {})

  return config[getOffset(userTimeZone)] || 'Etc/UTC';
};
