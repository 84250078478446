import { IDepositCryptoResult, IPaymentRoute } from '#reducers/trade/interfaces';
import { PaymentProvider, Themes, UrlSearchParamsEnum } from '#/types';

interface Props {
  theme: Themes,
  url: string,
  user_id: string,
  deposit?: IDepositCryptoResult,
  paymentRoute: IPaymentRoute | undefined,
  sessionId: string,
  currency?: string,
  ignoreSessionId: Function,
}

export const generateIframeUrl = ({ currency, theme, url, deposit, user_id, paymentRoute, sessionId, ignoreSessionId }: Props) => {
  const { address , currency_id } = deposit || {};
  const newUrl = new URL(url);
  const urlParams = new URLSearchParams(newUrl.search);
  if ( paymentRoute?.psp_service_id === PaymentProvider.C14 && currency === currency_id) {
    urlParams.append(UrlSearchParamsEnum.SourceCurrencyCode, currency_id || '');
    urlParams.append(UrlSearchParamsEnum.TargetAddress, address || '');
  }
  !ignoreSessionId(paymentRoute?.psp_service_id) && urlParams.append(UrlSearchParamsEnum.SessionId, sessionId);
  urlParams.append(UrlSearchParamsEnum.Theme, theme);
  newUrl.search = urlParams.toString();

  return newUrl.href;
};