import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { handleErrorThunk, throttle } from '#/util'
import { RequestStatus } from '#/types/enums';
import { IGetOpenClosedPositionsPayload, IDerivativesPosition } from '#/api/derivatives/dto';

import DerivativesService from '#/api/derivatives/DerivativesService';
import { 
  updateOpenPositions,
  updateClosedPositions,
  updateNextPositionsExists,
  positionsRequestStatus,
  updateOpenPosition,
  removeClosedPositionFromOpenedList,
  updateClosedPositionsListWithClosedPosition,
} from './derivativesPosiions';
import { preparePagerDifferencePlus1, prepareResultPagerDifference } from '#/util/pager';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import { derivativesMaxPositionSize } from '../derivativesTrade';

export const getDerivativesOpenPositions = createAsyncThunk(
  'derivatives/getOpenPositions',
  throttle(async (params: IGetOpenClosedPositionsPayload, { dispatch, extra }: any) => {
    dispatch(positionsRequestStatus(RequestStatus.Pending));
    try {      
      const { open_margin_positions } = await (extra.DerivativesService as DerivativesService).getOpenPositions(preparePagerDifferencePlus1(params));

      const pagerOrders = prepareResultPagerDifference(open_margin_positions, params?.pager?.limit);
      batch(() => {
        dispatch(updateOpenPositions(pagerOrders));
        dispatch(updateNextPositionsExists(open_margin_positions.length > pagerOrders.length));
        dispatch(positionsRequestStatus(RequestStatus.Success));    
      });
    } catch (error) {
      dispatch(positionsRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get margin open positions failed', dispatch);
    }
  }, 100),
) as unknown as (params: IGetOpenClosedPositionsPayload) => AnyAction;

export const getDerivativesClosedPositions = createAsyncThunk(
  'derivatives/getClosedPositions',
  throttle(async (params: IGetOpenClosedPositionsPayload, { dispatch, extra }: any) => {
    dispatch(positionsRequestStatus(RequestStatus.Pending));
    try {      
      const { closed_margin_positions } = await (extra.DerivativesService as DerivativesService).getClosedPositions(preparePagerDifferencePlus1(params));
      
      const pagerOrders = prepareResultPagerDifference(closed_margin_positions, params?.pager?.limit);
      batch(() => {
        dispatch(updateClosedPositions(pagerOrders));
        dispatch(updateNextPositionsExists(closed_margin_positions.length > pagerOrders.length));
        dispatch(positionsRequestStatus(RequestStatus.Success));    
      });
    } catch (error) {
      dispatch(positionsRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get margin closed positions failed', dispatch);
    }
  }, 100),
) as unknown as (params: IGetOpenClosedPositionsPayload) => AnyAction;

interface SubscribeOpenedPositions {
  unsubscribe?: boolean
}

export const subscribeOpenDerivativesPositionsUpdate = createAsyncThunk(
  'derivatives/subscribeInstrumentPriceBar',
  async ({ unsubscribe = false}: SubscribeOpenedPositions, { dispatch, extra }: any) => {
    try {
      (extra.DerivativesService as DerivativesService).subscribeOpenPositionsUpdate({
        next: ({ data: { open_margin_position_update }}: { data: { open_margin_position_update: IDerivativesPosition }}) => {          
          if (!!open_margin_position_update.close_reason) {
            dispatch(removeClosedPositionFromOpenedList(open_margin_position_update));
            dispatch(updateClosedPositionsListWithClosedPosition(open_margin_position_update));
            novaToast.success(`Position ${open_margin_position_update.instrument_id} is closed:`, {
              [_t('Close reason', 'MARGIN_ORDER.CLOSE_REASON')]: `${open_margin_position_update.close_reason}`,
              [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${open_margin_position_update.amount}`,
            });
            dispatch(derivativesMaxPositionSize());
          } else {
            dispatch(updateOpenPosition(open_margin_position_update));
          }
        },
        error: (error: any) => {         
          handleErrorThunk(error, 'Subscribe Opened Positions Update failed', dispatch);
        },
        complete: () => {},
      }, unsubscribe);
    } catch (error) {     
      handleErrorThunk(error, 'Subscribe Opened Positions Update failed', dispatch);
    }
  }
) as unknown as (params: SubscribeOpenedPositions) => AnyAction;