import {
  createShareLinkParamsFromObj,
  checkIsMobileOrTablet,
  checkIsNavigatorShareAvailable,
} from './helpers';
import {
  EShareOptions,
  IShareFacebookMessangerProps,
  IShareViberProps,
  IShareWhatsAppProps,
} from './types';

export const shareApi = {
  [EShareOptions.Messages]: async (props: ShareData) => {
    if (checkIsNavigatorShareAvailable()) {
      try {
        await navigator.share(props);
      } catch (e) {
        console.log('Failed to share', e);
      }
    }
  },

  [EShareOptions.FacebookMessenger]: (props: IShareFacebookMessangerProps) => {
    const link = `https://www.facebook.com/dialog/send${createShareLinkParamsFromObj(props)}`;

    window.open(link, '_blank');
  },

  [EShareOptions.Viber]: (props: IShareViberProps) => {
    const link = `viber://forward${createShareLinkParamsFromObj(props)}`;

    window.open(link, '_blank');
  },

  [EShareOptions.WhatsApp]: (props: IShareWhatsAppProps) => {
    const link = `https://${checkIsMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send${createShareLinkParamsFromObj(props)}`;

    window.open(link, '_blank');
  },
};
