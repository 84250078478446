import React, { ReactElement, ReactNode } from 'react';
import ErrorBoundary from '#/elements/ErrorBoundary';
import './nova-component.scss';

interface Props {
  children: Array<ReactElement> | ReactElement | ReactNode,
  header?: string | ReactElement,
  className?: string,
  inRow?: boolean,
  noWrapper?: boolean,
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=> void,
}
const NovaComponent = (props: Props) => {
  const {
    children,
    header,
    className = '',
    inRow,
    noWrapper = false,
    onClick,
  } = props;

  return (
    <ErrorBoundary>
      <div className={`${className} nova-component `} onClick={(e) => onClick && onClick(e)}>
        { header && (
          <div className="nova-component-header">{ header }</div>
        )}
        <div className={`nova-component-content ${inRow ? 'in-row' : ''} ${noWrapper ? 'no-wrapper' : ''}`}>
          { children }
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default NovaComponent;
