import React from 'react';

import { NovaElementsOptions, NovaElementsSizes } from '#/types/enums';

import './nova-button.scss';

export enum TypeNovaButton {
  Submit = 'submit',
  Button = 'button',
}
export enum TypeActionButton {
  Default = 'default-action',
  Minor = 'minor-action',
}
interface Props {
  onClick: Function,
  name?: string,
  value?: string,
  className?: string,
  isDisabled?: boolean,
  onFocus?: () => void,
  onBlur?: () => void,
  onMouseOver?: React.MouseEventHandler<HTMLButtonElement>,
  isLoading?: boolean,
  type?: TypeNovaButton,
  size?: NovaElementsSizes,
  children?: any,
  variant?: NovaElementsOptions,
  typeAction?: TypeActionButton,
  isDark?: boolean,
  isTransparent?: boolean,
  smallFont?: boolean,
  isBorderLess?: boolean,
  isDanger?: boolean,
  beforeIcon?: string,
  afterIcon?: string,
  icon?: React.ReactNode,
  withValue?: boolean,
}

const NovaButton = (props: Props) => {
  const {
    onClick,
    name = '',
    value = _t('Submit', 'COMMON.SUBMIT'),
    type = TypeNovaButton.Submit,
    className = '',
    isDisabled = false,
    onFocus = () => {},
    onBlur = () => {},
    onMouseOver = () => {},
    isLoading = false,
    children = null,
    size = NovaElementsSizes.Medium,
    variant = NovaElementsOptions.Primary,
    typeAction = TypeActionButton.Default,
    isDark = false,
    isTransparent = false,
    smallFont = false,
    isBorderLess = false,
    isDanger = false,
    beforeIcon = '',
    afterIcon = '',
    icon = null,
    withValue = true,
  } = props;

  const renderValue = children || value;
  const classes = [
    'nova-button',
    className,
    beforeIcon && 'before-icon',
    smallFont && 'font-small',
    isDark && 'isDark',
    isDisabled && 'disabled',
    typeAction,
    size,
    variant,
    isLoading && 'loading',
    isTransparent && 'transparent',
    isBorderLess && 'with-out-border',
    isDanger && 'danger',
  ].filter(Boolean);

  const renderLoadingIcon = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <span className="icon-container">
        <i className="fa fa-spinner fa-spin" />
      </span>
    );
  };

  return (
    <button
      type={type}
      value={value}
      name={name}
      className={classes.join(' ')}
      onClick={(e) => onClick(e)}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      disabled={isDisabled}
    >
      {beforeIcon && <i className={`fas fa-${beforeIcon}`}></i>}
      {icon}
      {withValue && renderValue}
      {renderLoadingIcon()}
      {afterIcon && <i className={`fas fa-${afterIcon}`}></i>}
    </button>
  );
};

export default NovaButton;
