import { RequestStatus } from '#/types/enums';
import { IReferralState } from './types';

export const initialReferralState: IReferralState = {
  referralCode: '',
  referralTrackInfo: {
    totalReferralReward: 0,
    totalReferralsCount: 0,
  },
  referralRewardsHistory: [],
  isNextReferralRewardsHistoryPageExists: false,
  getReferralRewardsHistoryStatus: RequestStatus.None,
  getReferralTrackInfoStatus: RequestStatus.None,
  createReferralRequestStatus: RequestStatus.None,
  claimReferralRequestStatus: RequestStatus.None,
  getReferralsRequestStatus: RequestStatus.None,
}
