import { GqlClient } from '../gql';
import {
  IClaimReferralPayload,
  ICreateReferralPayload,
  IReferralRewardsHistory,
  IGetReferralRewardsHistoryPayload,
  IReferral,
  IGetReferralRewardTotalPayload,
} from './dto';
import referralGql, { ReferralOperations } from './referral-gql';

export default class ReferralService {
  public gqlRequestClient: GqlClient;

  constructor(gqlRequestClient: GqlClient) {
    this.gqlRequestClient = gqlRequestClient;
  }

  public async getReferrals(): Promise<{ [ReferralOperations.GetReferrals]: Array<IReferral> }> {
    return await this.gqlRequestClient.request(referralGql.getReferrals());
  }

  public async getReferralRewardsHistory(payload: IGetReferralRewardsHistoryPayload): Promise<{ [ReferralOperations.GetReferralRewardsHistory]: Array<IReferralRewardsHistory> }> {
    return await this.gqlRequestClient.request(referralGql.getReferralRewardsHistory(payload));
  }

  public async getReferralRewardTotal(payload: IGetReferralRewardTotalPayload): Promise<{ [ReferralOperations.GetReferralRewardTotal]: number }> {
    return await this.gqlRequestClient.request(referralGql.getReferralRewardTotal(payload));
  }

  public async getReferredUsersCount(userId: string): Promise<{ [ReferralOperations.GetReferredUsersCount]: number }> {
    return await this.gqlRequestClient.request(referralGql.getReferredUsersCount(userId));
  }

  public async createReferral(payload: ICreateReferralPayload): Promise<{ [ReferralOperations.CreateReferral]: IReferral }> {
    return await this.gqlRequestClient.request(referralGql.createReferral(payload));
  }
  
  public async claimReferral(payload: IClaimReferralPayload): Promise<{ [ReferralOperations.ClaimReferral]: boolean }> {
    return await this.gqlRequestClient.request(referralGql.claimReferral(payload));
  }
}
