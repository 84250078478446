import capitalize from "./capitalize";

const toCapsAndAbbreviations = (str: string, separator: string = '_') => {
  const sep = new RegExp(separator, 'g');
  str = str
    .replace(sep, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .trim()
    .replace(/\b[a-z]/g, _ => _.toUpperCase());

  const specialStrings = ['id', 'psp', 'url', 'ip', 'kyc', 'api', 'etc'];

  const regex = new RegExp(`\\b(${specialStrings.join('|')})\\b`, 'gi');

  str = str.replace(regex, match => match.toUpperCase());

  return capitalize(str);
};

export default toCapsAndAbbreviations;