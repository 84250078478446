import {
  TradeSide,
  TradeType,
  TradeMode,
  OrderStatus,
  OrdersGetTypes,
  CryptoAddressTagType,
  DepositWithdraw,
  ConversionStatus,
  AccountTransactionClass,
  AccountTransactionType,
  IsActive,
  CryptoTypes,
 } from './enums';
import { OrdersPayload } from '#/api/trade/trade-qql';
import { IHealthCheckResponse } from '#/api/settings/dto';
import { User } from '#reducers/user/user';

export interface InstrumentPrice {
  instrument_id: string,
  ask: number,
  bid: number,
  price_24h_change: number,
  ts_iso: string, // timestamp "2023-08-29T12:44:00+00:00"
}

export interface InstrumentPriceBar {
  instrument_id: string,
  high: number,
  low: number,
  ts_iso: string,
  open: number,
  close: number,
}

export interface InstrumentFees {
  instrument_id: string,
  maker_progressive: number,
  taker_progressive: number,
  maker_flat: number,
  taker_flat: number,
}

export interface ISelectedInstrument {
  name: string,
  instrument_id: string,
  base_currency_id: string,
  quote_currency_id: string,
  price_decimals: number,
  quantity_decimals: number,
  min_quantity: number,
  max_quantity: number,
  min_quote_quantity: number,
  max_quote_quantity: number,
  price: InstrumentPrice,
  trading_fees: InstrumentFees,
  is_active: IsActive,
  quote_currency: {
    precision: number,
    name: string,
  },
  base_currency: {
    precision: number,
    name: string,
  },
  recent_price_bar: InstrumentPriceBar,
  price_bars: Array<InstrumentPriceBar>,
  quote_quantity_decimals: number,
}

export enum CryptoFiat {
  Crypto = 'crypto',
  Fiat = 'fiat',
}

export type CryptoNetworkDefault = 'default';
export type FiatNetworkDefault = 'default';

export interface IFiatDepositProperty {
  name: string,
  value: string,
}

export interface IPaymentRoute {
  payment_route_id: string,
  currency_id: string,
  psp_service_id: string,
  name: string,
  crypto_network: string | CryptoNetworkDefault,
  crypto_address_generate_new: IsActive,
  crypto_address_tag_type: null | CryptoAddressTagType,
  fiat_iframe_deposit_url: string | null,
  fiat_iframe_withdrawal_url: string | null,
  fiat_transfer_type: string | FiatNetworkDefault,
  is_active: IsActive,
  extend_network_fee: IsActive,
  is_development: IsActive,
  verification_type: VerificationType,
  fiat_deposit_properties: IFiatDepositProperty[],
  deposit_enabled: IsActive,
  withdrawal_enabled: IsActive,
  currency: {
    type: CryptoTypes,
  },
}

export type IPaymentRoutes = Array<IPaymentRoute>

export interface IBalanceMinimum {
  currency_id: string,
  total_balance: number,
  exposed_balance: number,
  free_balance: number,
  currency: {
    is_active: IsActive,
    precision: number,
    type: CryptoFiat,
    name: string,
  }
}

export interface IBalance extends IBalanceMinimum {
  free_balance_USD: number | null,
  free_balance_BTC: number | null,
  free_balance_USDT: number | null,
  free_balance_ETH: number | null,
}

export interface IBalanceExtended extends IBalance {
  percentage: number,
  balanceInQuoteDecimals: number,
  balanceInQuote: number,
  balanceInQuoteTotal: number,
  priceInQuote: number,
  priceInUsd: number,
  isFiat: boolean,
  isActive: boolean,
}

export interface Balances {
  [instrument_id: string]: IBalanceExtended,
}

export interface Order {
  side: TradeSide,
  type: TradeType,
  quantity: number,
}

export interface OrderExecutionFee {
  currency_id: string,
  amount: number,
}

export interface OrderEstimation {
  type: TradeType,
  price: number,
  quantity: number,
  side: TradeSide,
  quantity_mode: TradeMode,
  instrument: { instrument_id: string }, // can be ISelectedInstrument
  fees: Array<OrderExecutionFee>,
}

export interface IEstimateNetworkFee {
  fee_per_byte: number | null,
  gas_price: number | null,
  network_fee: number | null,
  base_fee: number | null,
  priority_fee: number | null,
}


export interface Fee {
  currency_id: string,
  amount: number,
  currency: {
    precision: number
  }
}

export interface OrderResult {
  order_id: string,
  type: TradeType,
  side: TradeSide,
  status: OrderStatus,
  price: number,
  stop_price: number,
  quantity: number,
  executed_quantity: number,
  remaining_quantity: number,
  quantity_mode: TradeMode,
  instrument_id: string,
  message: string,
  updated_at: number,
  created_at: number,
  expires_at: number,
  time_in_force: string,
  fees: Array<Fee>, 
  instrument: {
    base_currency_id: string,
    quote_currency_id: string,
    quantity_decimals: number,
    quote_quantity_decimals: number,
    name: string,
  },
  total?: number,
}

export interface Conversion {
  price: number,
  fee_currency_id: string,
  fee_currency_amount: number,
  source_currency_id: string
  target_currency_id: string,
  target_currency_amount: number,
  source_currency_amount: number,
}

export interface ConversionHistory extends Conversion {
  conversion_id: string,
  conversion_quote_id: string,
  reference: string | null,
  instrument_id: string,
  status: ConversionStatus,
  message: string | null,
  error_message: string | null,
  created_at_iso: string,
  updated_at_iso: string,
  instrument?: {
    name: string,
  },
}

export interface ConversionResult extends Conversion {
  conversion_quote_id: string,
  expires_at: string, // DATE
  expires_at_iso: string,
  current_time_iso: string,
  fees: Array<Fee>,
}

export interface ExecutedConversionResult extends Conversion {
  status: OrderStatus,
  message: string | null,
  created_at: string, // DATE
  updated_at: string, // DATE
}

export interface TransactionResult extends AccountTransaction {

}

export interface AccountTransaction { // TODO refact interface
  account_transaction_id: string,
  parent_transaction_id: string,
  client_transaction_id: string,
  payment_id: string,
  currency_id: string,
  transaction_class: AccountTransactionClass,
  type: AccountTransactionType,
  order_id: string,
  trade_id: string,
  conversion_id: string,
  amount: number,
  post_balance: number,
  comment: string,
  created_at: string,
  created_at_iso: string,
  currency: TransactionCurrency,
  // payment: Payment !!!
  //order: Order
  //user: User
  //trade: Trade
  //conversion: Conversion
  //user_id: String!
  //account_id: String!
  //serial_id: number
}

export interface Trade {
  serial_id: number,
  trade_id: string,
  trade_session_id: string,
  instrument_id: string,
  order_id: string,
  side: string,
  counter_order_id: string,
  user_id: string,
  quantity: number,
  price: number,
  created_at: string,
  created_at_iso: string,
  user: User,
  order: Order,
  }

export interface TransactionCurrency {
  currency_id: string,
  type: CryptoFiat,
  precision: number,
}


export interface Account {
  account_id: string,
  balance: number,
  currency_id: string,
  ts: string,
}

export interface TradePager {
  limit: number,
  offset: number,
}

export interface FavoriteAddressCrypto {
  currency_id: string,
  name?: string,
  address: string,
  network?: string,
  networkName?: string,
  address_tag_type?: CryptoAddressTagType,
  address_tag_value?: string,
  network_name?: string,
}

export interface FavoriteFiatDestination {
  name: string | null,
  bank_name: string,
  bank_address: string,
  bank_bic: string,
  routing_number: string | null,
  reference: string | null,
  notes: string | null,
  beneficiary_name: string,
  beneficiary_account_number: string,
  beneficiary_address_line_1: string | null,
  beneficiary_address_line_2: string | null,
  bank_country: string | undefined,
  bank_region: string | undefined,
  bank_city: string | undefined,
  bank_postal_code: string | undefined,
  beneficiary_country: string | undefined,
  beneficiary_region: string | undefined,
  beneficiary_city: string | undefined,
  beneficiary_postal_code: string | undefined,
  properties: PaymentProperties,
}

export interface PaymentProperty {
  name: string,
  value: string,
}

export type PaymentProperties = Array<PaymentProperty>;

export interface IBankDetailsResult {
  name: string,
  fiat_bank_name: string,
  fiat_bank_address: string | undefined,
  fiat_bank_bic: string | undefined,
  fiat_routing_number: string | undefined,
  fiat_reference: string | undefined,
  fiat_beneficiary_name: string,
  fiat_beneficiary_account_number: string,
  fiat_beneficiary_address_line_1: string | undefined,
  fiat_beneficiary_address_line_2: string | null | undefined,
  fiat_bank_country: string | undefined,
  fiat_bank_region: string | undefined,
  fiat_bank_city: string | undefined,
  fiat_bank_postal_code: string | undefined,
  fiat_beneficiary_country: string | undefined,
  fiat_beneficiary_region: string | undefined,
  fiat_beneficiary_city: string | undefined,
  fiat_beneficiary_postal_code: string | undefined,
  fiat_notes: string | undefined,
  payment_route?: { name: string },
  properties: PaymentProperties,
  fiat_transfer_type?: string,
}

export interface IWithdrawMinimalResult {
  payment_id: string,
  user_id: string,
  amount: string,
  currency_id: string,
  type: CryptoFiat, // TODO ???
  psp_service_id: string | null,
  status: IPaymentStatus,
  error_message: string | null,
  message: string | null,
  ip_address: string | null,
  fee_amount: number,
  approval_status: string | null,
}

export interface IDepositCryptoResult {
  deposit_address_crypto_id: string | null,
  currency_id: string,
  address: string | null,
  address_tag_type: CryptoAddressTagType | null,
  address_tag_value: string | null,
  network: string | undefined,
  psp_service_id: string | null,
  created_at: string, // Date
  updated_at: string,
}

export interface IWithdrawCryptoResult extends IWithdrawMinimalResult {
  crypto_network: string,
  crypto_address: string,
  crypto_address_tag_type?: CryptoAddressTagType,
  crypto_address_tag_value?: string,
  amount: string,
  isTagType?: boolean,
  fees_included?: IsActive,
}

export interface IWithdrawFiatResult extends IBankDetailsResult, IWithdrawMinimalResult {
  fees_included?: IsActive,

}

export interface IDepositFiatResult extends IBankDetailsResult {
  currency_id: string,
}

export enum IPaymentStatus {
  Processing = 'processing',
  Completed = 'completed',
  Rejected = 'rejected',
  New = 'new',
}

export enum IPaymentApproval {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum IDepositFiatFundingMethod {
  Wire = 'wire',
  Bank = 'bank',
  Card = 'card',
  Ach = 'ach',
}

export interface IPaymentResult extends IBankDetailsResult {
  payment_id: string,
  currency_id: string,
  amount: number,
  type: DepositWithdraw,
  crypto_transaction_id: string | null,
  approval_status: IPaymentApproval,
  crypto_address: string,
  crypto_address_tag_type: CryptoAddressTagType | null,
  crypto_address_tag_value: string | null,
  crypto_network: string | null,
  status: IPaymentStatus,
  message: string | null,
  error_message: string | null,
  created_at: string, // Date
  updated_at: string,
  fee_amount: number,
  account_transactions: [],
  manual_transaction_date_iso: string,
  payment_route?: {
    name: string,
    payment_route_id: string,
  },
  fees_included: IsActive,
  amount_quoted: number,
  fee_quoted: number,
}

export interface IManualPaymentId {
  account_transaction_id: string,
  amount: number,
  type: string,
  currency_id: string,
  transaction_class: AccountTransactionClass,
}

export interface GetOrders extends OrdersPayload {
  type: OrdersGetTypes,
}

export interface GetOrdersWithPager extends GetOrders {
  pager: TradePager,
}

export interface NotificationEvent {
  title?: string,
  body: string,
  reference_nr?: string,
  class: 'error' | 'success' | 'info',
}

export interface UserEventResult {
  order: OrderResult | null,
  account_transaction: AccountTransaction | null,
  conversion: ConversionHistory | null,
  payment: IPaymentResult | null,
  account: Account | null,
  notification: NotificationEvent | null,
  healthcheck: IHealthCheckResponse | null,
}

export interface IUpdateWalletFiatOptions {
  isEdit?: boolean;
  isDelete?: boolean;
}

export enum VerificationType {
  None = 'none',
  Totp='totp',
  BankId = 'bank_id',
  Sms = 'sms',
  LocalMfa = 'local_mfa',
  CognitoMfa = 'cognito_mfa',
}
