import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IReferralTrackInfo,
  getReferralTrackInfo,
  selectReferralTrackInfo,
  selectReferralTrackInfoStatus,
} from '#reducers/referral';
import { selectUserID, selectUserMarketCurrency } from '#reducers/user/user';

import { RequestStatus } from '#/types';
import { selectUserMarketCurrencyPrecision } from '#reducers/trade';
import { formatNumberToLocale } from '#/util';
import { currencySymbols } from '#/config/buy-sell/buy-sell-amounts';

interface IUseReferralsTrackInfo {
  totalReferralsCount: number,
  totalReferralReward: string,
  totalReferralCurrencySymbol: string,
  isLoading: boolean,
  isHistoryVisible: boolean,
  toggleHistoryVisibility: () => void,
}

export const useReferralsTrackInfo = (): IUseReferralsTrackInfo => {
  const dispatch = useDispatch();

  const userId: string = useSelector(selectUserID);
  const currencyId: string = useSelector(selectUserMarketCurrency);
  const decimals: number = useSelector(selectUserMarketCurrencyPrecision);
  const getTrackInfoStatus: RequestStatus = useSelector(selectReferralTrackInfoStatus);
  const { totalReferralReward, totalReferralsCount }: IReferralTrackInfo = useSelector(selectReferralTrackInfo);

  const [isHistoryVisible, setIsHistoryVisible] = useState<boolean>(false);

  useEffect(() => {
    if (userId && currencyId) {
      dispatch(getReferralTrackInfo({ quote_currency_id: currencyId, user_id: userId }));
    }
  }, [userId, currencyId]);

  const toggleHistoryVisibility = () => {
    setIsHistoryVisible(!isHistoryVisible);
  };

  return {
    totalReferralsCount,
    totalReferralReward: formatNumberToLocale(totalReferralReward, decimals),
    totalReferralCurrencySymbol: currencySymbols[currencyId],
    isLoading: [RequestStatus.None, RequestStatus.Pending].includes(getTrackInfoStatus),
    isHistoryVisible,
    toggleHistoryVisibility,
  };
};
