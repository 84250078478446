import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { selectInvertedTheme } from '#reducers/config';
import ReactTooltip from 'react-tooltip';

import './tooltip.scss'

enum TooltipTypes {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export interface MessageProps {
  faIcon?: string,
  id: string,
  messages: string[] | string;
  type?: TooltipTypes,
  children?: ReactElement,
}

const Message: FC<MessageProps> = (props) => {
  const {
    messages = [],
    type = TooltipTypes.error,
    faIcon = '',
    id = '',
    children = null,
  } = props;

  if (messages.length === 0) {
    return <p></p>;
  }

  return (
    <div 
      data-for={id}
      className="form-icon-container tooltip-message"
      data-tip={Array.isArray(messages) ? messages.join('<br />') : messages}
      data-html
    >
      <i className={faIcon ? faIcon : `far ${type}-icon fa-info-circle`} />
      { children }
    </div>
  );
};

const TooltipMessage = React.memo(Message);

interface TooltipProps extends Omit<MessageProps, 'id'> {
  id?: string,
  wrapped?: boolean,
  type?: TooltipTypes,
}

const Tooltip: FC<TooltipProps> = (props) => {
  const invertedTheme = useSelector(selectInvertedTheme);

  const { id = '', type } = props;
  const tooltipId = id || String(Math.random());

  return (
    <TooltipMessage {...props} id={tooltipId}>
      <ReactTooltip className="react-tooltip-message" id={tooltipId} type={type || invertedTheme }/>
    </TooltipMessage>
  );
}


export default React.memo(Tooltip);

export { TooltipTypes, TooltipMessage };
