import { useCallback, useEffect, useState } from 'react';

import { useClickOutside } from '#/hooks/dom/useClickOutside';

import { shareApi } from './api';
import { createShareOptionsConfig } from './helpers';

import { EShareOptions, IShareOption } from './types';

interface IUseShareReferralCode {
  isMenuOpen: boolean,
  toggleMenuOpen: () => void,
  onShare: (refLink: string, shareOptionType: EShareOptions) => void,
  options: Array<IShareOption>,
}

export const useShareReferralCode = (optionsContainerId: string): IUseShareReferralCode => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { setListen } = useClickOutside({ idElement: optionsContainerId, onClickOutside: () => setIsMenuOpen(false) });

  useEffect(() => setListen(isMenuOpen), [isMenuOpen]);

  const toggleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onShare = useCallback((refLink: string, shareOptionType: EShareOptions) => {
    const title = _t('Hey, I think you would like this! Register today and receive rewards', 'NOVA_REFERRALS.INVITE_MESSAGE');
    const separator = ': ';
    const messageWithTitle = `${title}${separator}${refLink}`;

    switch (shareOptionType) {
      case EShareOptions.FacebookMessenger: shareApi.facebookMessenger({
        app_id: 942634114272317,      // needs to be discussed - https://developers.facebook.com/ created app is required to work with facebook api
        link: refLink,
        redirect_uri: window.location.href,
      });
        break;
      case EShareOptions.Viber: shareApi.viber({ text: messageWithTitle });
        break;
      case EShareOptions.WhatsApp: shareApi.whatsapp({ text: messageWithTitle });
        break;
      default: shareApi.messages({ title, text: `${title}${separator}`, url: refLink });
    }

    setIsMenuOpen(false);
  }, []);

  return {
    options: createShareOptionsConfig(),
    isMenuOpen,
    toggleMenuOpen,
    onShare,
  };
};
