import { DerivativesPositionsState, initialStateDerivativesPositions } from './derivativesPositions';
import { DerivativesConfigState, initialStateDerivativesConfig } from './derivativesConfig';
import { DerivativesInstrumentsState, initialStateDerivativesInstruments } from './derivativesInstruments';
import { DerivativesTradeState, initialStateDerivativesTrade } from './derivativesTrade';
import { DerivativesOrderbookState, initialStateDerivativesOrderbook } from './derivativesOrderbook';
import { DerivativesBalanceState, initialStateDerivativesBalance } from './derivativesBalances';
import { DerivativesOrdersState, initialStateDerivativesOrders } from './derivativesOrders';

export interface IDerivativesState {
  derivativesInstruments: DerivativesInstrumentsState,
  derivativesConfig: DerivativesConfigState,
  derivativesOrderbook: DerivativesOrderbookState,
  derivativesTrade: DerivativesTradeState,
  derivativesPositions: DerivativesPositionsState,
  derivativesOrders: DerivativesOrdersState,
  derivativesBalances: DerivativesBalanceState,
}

export const initialDerivativesState: IDerivativesState = {
  derivativesInstruments: initialStateDerivativesInstruments,
  derivativesConfig: initialStateDerivativesConfig,
  derivativesPositions: initialStateDerivativesPositions,
  derivativesOrders: initialStateDerivativesOrders,
  derivativesTrade: initialStateDerivativesTrade,
  derivativesOrderbook: initialStateDerivativesOrderbook,
  derivativesBalances: initialStateDerivativesBalance,
}