import { useState } from 'react';
import { Button, TextField } from '@aws-amplify/ui-react';

import {
  getReferralCodeFromStorage,
  setReferralCodeToStorage,
  storeReferralCode,
} from './helpers';

export const useReferralCodeCognitoField = () => {
  const [isReferralCodeEditMode, setIsReferralCodeEditMode] = useState<boolean>(false);

  const renderReferralCodeComponent = () => (
    <TextField
      className="referral-code-field"
      label={_t('Referral code', 'COGNITO_FORM.REFERRAL_CODE')}
      placeholder={_t('Enter your referral code', 'COGNITO_FORM.REFERRAL_CODE_PLACEHOLDER')}
      disabled={!isReferralCodeEditMode}
      defaultValue={isReferralCodeEditMode ? getReferralCodeFromStorage() : storeReferralCode()}      // by default getting from params, in case of edit - from LS
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReferralCodeToStorage(e.target.value)} // override code in LS
      outerEndComponent={!isReferralCodeEditMode ? (
        <Button onClick={() => setIsReferralCodeEditMode(!isReferralCodeEditMode)}>
          <i className="fas fa-pencil-alt" />
        </Button>
      ) : undefined
      }
    />
  );

  return { renderReferralCodeComponent };
};
