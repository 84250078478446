export enum EShareOptions {
  Messages = 'messages',
  FacebookMessenger = 'facebookMessenger',
  Viber = 'viber',
  WhatsApp = 'whatsapp',
}

export interface IShareFacebookMessangerProps {
  link: string,
  redirect_uri: string,
  app_id: number,
  to?: string,
}

export interface IShareViberProps {
  text: string,
}

export interface IShareWhatsAppProps {
  text: string,
}

export interface IShareOption {
  faIcon: string,
  label: string,
  shareOptionType: EShareOptions,
}
