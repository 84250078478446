import * as gql from 'gql-query-builder';

import {
  IClaimReferralPayload,
  ICreateReferralPayload,
  IGetReferralRewardTotalPayload,
  IGetReferralRewardsHistoryPayload,
} from './dto';
import { referralFields, referralRewardsHistoryFields } from './fields';
import { AccountTransactionClass } from '#/types';

export enum ReferralOperations {
  GetReferrals = 'referrals',
  CreateReferral = 'create_referral',
  ClaimReferral = 'claim_referral',
  GetReferralRewardsHistory = 'account_transactions',
  GetReferralRewardTotal = 'referrer_total_reward_quoted',
  GetReferredUsersCount = 'referred_users_count',
}

export default {
  getReferrals: () => {
    return gql.query({
      operation: ReferralOperations.GetReferrals,
      fields: referralFields,
    });
  },

  getReferralRewardsHistory: (payload: IGetReferralRewardsHistoryPayload) => {
    return gql.query({
      operation: ReferralOperations.GetReferralRewardsHistory,
      variables: {
        currency_id: { value: payload.currency_id, required: false, type: 'String' },
        pager: { value: payload.pager, required: false, type: 'PagerInput' },
        dateRange: { value: payload.dateRange, required: false, type: 'DateRangeInput' },
        search: { value: payload.search, required: false },
        transaction_class: { value: AccountTransactionClass.ReferralReward, required: false, type: 'AccountTransactionClass' },
      },
      fields: referralRewardsHistoryFields,
    });
  },

  getReferralRewardTotal: (payload: IGetReferralRewardTotalPayload) => {
    return gql.query({
      operation: ReferralOperations.GetReferralRewardTotal,
      variables: {
        user_id: { value: payload.user_id, required: false },
        quote_currency_id: { value: payload.quote_currency_id, required: true },
      },
      fields: [],
    });
  },

  getReferredUsersCount: (userId: string) => {
    return gql.query({
      operation: ReferralOperations.GetReferredUsersCount,
      variables: {
        user_id: { value: userId, required: true },
      },
      fields: [],
    });
  },

  createReferral: (payload: ICreateReferralPayload) => {
    return gql.mutation({
      operation: ReferralOperations.CreateReferral,
      variables: {
        user_id: { value: payload.user_id, required: false },
      },
      fields: referralFields,
    });
  },

  claimReferral: (payload: IClaimReferralPayload) => {
    return gql.mutation({
      operation: ReferralOperations.ClaimReferral,
      variables: {
        referral_id: { value: payload.referral_id, required: true },
        user_id: { value: payload.user_id, required: false },
      },
      fields: [],
    });
  },
}
