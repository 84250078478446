export enum EReferralSearchParams {
  Code = 'ref-code',
}

export const getReferralCodeFromParams = (): string => {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(EReferralSearchParams.Code) || '';
}

// get referral code from params and set to localStorage
export const storeReferralCode = (): string => {
  const referralCode: string = getReferralCodeFromParams();

  localStorage.setItem(EReferralSearchParams.Code, referralCode);

  return referralCode;
};

export const setReferralCodeToStorage = (referralCode: string = '') => localStorage.setItem(EReferralSearchParams.Code, referralCode);

export const getReferralCodeFromStorage = (): string => localStorage.getItem(EReferralSearchParams.Code) || '';

// temporary solution, rm part with ids to make message more readable
export const prepareShortRewardComment = (comment: string): string => comment.substring(0, comment.indexOf("("));
