import { ReactElement } from 'react';
import useIframeSettings from '#/hooks/settings/iframe-settings/useIframeSettings';

const NovaIframeProvider = ({ children }: { children: ReactElement }) => {
  const { isRenderAllowed } = useIframeSettings();

  if (!isRenderAllowed) {
    return null
  }

  return children
}

export default NovaIframeProvider