import { useEffect, useState } from "react";
import { ERedirectLinks } from "./enums";
import { isIframe } from "#/util";
import { getCurrentUrl, isAllowedDomain, isAllowedParentHostname, isLocalDevelopmentHost } from "./helpers";

const useIframeSettings = () => {
  const [isRenderAllowed, setRenderAllowed] = useState<boolean>(false);

  const makeRedirect = () => window.location.replace(ERedirectLinks.NoonesProd);

  useEffect(() => {
    const currentUrl = getCurrentUrl();

    if (!currentUrl) {
      makeRedirect();
      return
    }

    const isInIframe = isIframe();
    const { host, hostname } = currentUrl;

    const isAllowed = isInIframe
      ? isAllowedParentHostname(hostname)
      : isAllowedDomain(hostname) || isLocalDevelopmentHost(host);

    if (!isAllowed) {
      makeRedirect();
    }

    setRenderAllowed(isAllowed);
  }, [])

  return {
    isRenderAllowed,
  }
};

export default useIframeSettings