import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from '@reduxjs/toolkit';
import UserService from '#/api/user/UserService';
import { RequestStatus } from '#/types/enums';
import { throttle, handleErrorThunk, arrayToObject } from '#/util';
import { updatePaymentRoutes, updatePaymentRoutesRequestStatus } from './paymentRoutes';
import { groupRoutesByCurrencyId } from './helpers';

export const getPaymentRoutes = createAsyncThunk(
  'trade/getPaymentRoutes',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    dispatch(updatePaymentRoutesRequestStatus(RequestStatus.Pending));
    try {
      const { payments_routes } = await (extra.userService as UserService).getUserPaymentRoutes();
      dispatch(updatePaymentRoutes(groupRoutesByCurrencyId(payments_routes)));
    } catch (error) {
      dispatch(updatePaymentRoutesRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get payment limits failed', dispatch);
    }
  }, 30000)
) as unknown as () => AnyAction;
