import * as gql from 'gql-query-builder';

import { 
  derivativesInstrumentsFields,
  derivativesConfigFields,
  derivativesPriceBarFields,
  derivativesStrategiesFields,
} from '../fields';
import { Periodicity } from '#/types';
import { PriceBarsPayload } from '#/api/trade/dto';

export enum DerivativesInstrumentOperations {
  // ---- query
  Instruments = 'margin_instruments',
  Config = 'margin_trading_config',
  PriceBars = 'margin_instrument_price_bars',
  Strategies = 'margin_instruments_strategies',
  // ---- mutations
  // ---- subscription
  SubscribePriceBar = 'margin_instrument_price_bar',
}

export default {

  getDerivativesInstruments: () => {
    return gql.query({
      operation: DerivativesInstrumentOperations.Instruments,
      fields: derivativesInstrumentsFields,
      variables: {}
    });
  },

  getDerivativesConfig: () => {
    return gql.query({
      operation: DerivativesInstrumentOperations.Config,
      fields: derivativesConfigFields,
      variables: {}
    });
  },

  getDerivativesPriceBars: (payload: PriceBarsPayload) => {
    return gql.query({
      operation: DerivativesInstrumentOperations.PriceBars,
      variables: {
        instrument_id: { value: payload.instrument_id, required: true },
        limit: { value: payload.limit, required: false },
        date_range: { value: payload.date_range, required: false, type: 'DateRangeInput' },
        periodicity: { value: payload.periodicity, required: false, type: 'MarginInstrumentHistoryPeriodicity' },
      },
      fields: derivativesPriceBarFields,
    });
  },

  getDerivativesInstrumentsStrategies: () => {
    return gql.query({
      operation: DerivativesInstrumentOperations.Strategies,
      variables: {},
      fields: derivativesStrategiesFields,
    });
  },

  subscribeDerivativesInstrumentPriceBar: (instrument_id: string, periodicity: Periodicity) => {
    return gql.subscription({
      operation: DerivativesInstrumentOperations.SubscribePriceBar,
      variables: {
        instrument_id: { value: instrument_id, required: true },
        periodicity: { value: periodicity, required: true, type: 'MarginInstrumentHistoryPeriodicity' },
      },
      fields: derivativesPriceBarFields,
    });
  },
}