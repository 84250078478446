import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { selectConfigRedirectAfterLogin } from '#reducers/config';
import { selectIsAuth } from '#reducers/user/user';
import { Paths } from '#/config/templates/nova/nova';

export const useRedirectAuth = () => {
  const navigate = useNavigate();
  const link = useLocation();

  const isAuth = useSelector(selectIsAuth);
  const redirectAfterLogin = useSelector(selectConfigRedirectAfterLogin);
  
  useEffect(() => {
    if (isAuth) {
      navigate(redirectAfterLogin);
    }
    if (!isAuth && link.pathname === Paths.Home) {
      navigate(Paths.Exchange)
    }
  }, [isAuth]);
};
