import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import './nova-trade-label.scss';

export interface Props {
  label: string,
  isDark?: boolean,
  icon?: ReactElement | null,
  link?: string,
  isBold?: boolean
  isRequired?:boolean
}
export const NovaTradeLabel = ({ label, isDark, icon, isBold, link = '', isRequired = false, }: Props) => {
  const navigate = useNavigate();

  const redirect = () => {
    if (link) {
      navigate(link);
    }
  }

  return (
    <p className={`nova-markets-trade-field-label${link ? ' with-link' : ''}${isDark ? ' dark' : ''}${isBold ? ' bold-text' : ''}`} onClick={redirect}>
      {label}
      {isRequired ? '*' : ''}
      { icon }
    </p>
  );
};

export default NovaTradeLabel;
