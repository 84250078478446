import { IDerivativesInstrument, IDerivativesPosition, DerivativesInstrumentsCType } from '#/api/derivatives/dto';
import { IsActive, RequestStatus, TradeSide } from '#/types';
import { DerivativesInstrumentsState } from './types';

const savedDerivativesInstrumentStorageKey = 'derivatives-instrument';

export const getSavedDerivativesInstrumentStorage = () => {
  return localStorage.getItem(savedDerivativesInstrumentStorageKey) || 'BTCUSD';
}

export const saveDerivativesInstrumentStorage = (instrumentId: string) => {
  localStorage.setItem(savedDerivativesInstrumentStorageKey, instrumentId);
}

const defaultInstrument: IDerivativesInstrument = { 
  margin_instrument_id: '',
  min_leverage: 1,
  max_leverage: 1,
  max_quantity: 0,
  min_quantity: 0,
  base_currency_id: '',
  quote_currency_id: '',
  price_decimals: 0,
  quantity_decimals: 0,
  funding_rate_1h: 0,
  price_24h_change: 0,
  price_24h_high: 0,
  price_24h_low: 0,
  name: '',
  type:  DerivativesInstrumentsCType.Base,
  price: { 
    ask: 0,
    bid: 0,
    margin_instrument_id: '',
    updated_at: '',
  },
  base_currency: {
    currency_id: '',
    precision: 0,
    is_active: IsActive.Off,
  },
  quote_currency: {
    currency_id: '',
    precision: 0,
    is_active: IsActive.Off,
  }
}

export const defaultPosition: IDerivativesPosition = {
  instrument_id: '',
  active: IsActive.Off,
  amount: 0,
  funding_fee_amount: 0,
  position_fee_amount: 0,
  leverage: 1,
  margin_position_id: '',
  max_loss: 0,
  max_profit: 0,
  on_limit: '',
  pnl: 0,
  side: TradeSide.Buy,
  entry_price: 0,
  start_ask_price: 0,
  start_bid_price: 0,
  start_price_ts: '',
  start_ts: '',
  updated_at: '',
  start_ts_timestamp: '',
  exit_price: 0,
  margin_instrument: {
    base_currency: {
      currency_id: '',
      is_active: IsActive.Off,
      precision: 0,
    },
    base_currency_id: '',
    funding_rate_1h: 0,
    margin_instrument_id: '',
    price_decimals: 0,
    quantity_decimals: 0,
    max_leverage: 0,
    min_quantity: 0,
    max_quantity: 0,
    min_leverage: 0,
    name: '',
    price: { ask: 0, bid: 0, margin_instrument_id: '', updated_at: '' },
    price_24h_change: 0,
    quote_currency: {
      currency_id: '',
      is_active: IsActive.Off,
      precision: 0,
    },
    quote_currency_id: '',
    type: DerivativesInstrumentsCType.Base,
    price_24h_high: 0,
    price_24h_low: 0,
  }
}

export const initialStateDerivativesInstruments: DerivativesInstrumentsState = {
  selectedDerivativesInstrument: defaultInstrument,
  last_mid_price: null,
  selectedInstrumentPriceBars: [],
  selectedDerivativesInstrumentOpenedPosition: defaultPosition,
  derivativesInstruments: [],
  derivativesInstrumentsStrategies: [],
  derivativesInstrumentsRequestStatus: RequestStatus.None,
  selectedDerivativesInstrumentHeaderData: defaultInstrument,
}