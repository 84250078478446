import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSecretCode as getSecretCodeAction,
  selectSecretCodeUri,
  selectUpdate2FaStatusRequest,
  updateMfa,
  selectIsUsing2fa,
  selectMfaStatusRequest,
  getMfaStatus,
  selectSecretCode,
} from '#reducers/settings';
import { selectIsAuth } from '#reducers/user/user';
import { RequestStatus } from '#/types/enums';
import { IsActive } from '#/types';
import { getTwoFaErrorMessages } from '#/nova/components/other/settings/content/security/security-2fa/helpers';

const use2Fa = () => {
  const dispatch = useDispatch();

  const usePassword = false; // TODO maybe later
  const is_using_2fa = useSelector(selectIsUsing2fa);
  const isAuth = useSelector(selectIsAuth);
  const mfaStatusRequest = useSelector(selectMfaStatusRequest);
  const secretCodeUri = useSelector(selectSecretCodeUri);
  const secretCodeRequest = useSelector(selectSecretCode);
  const updateStatusRequest = useSelector(selectUpdate2FaStatusRequest);
  const [twoFaTouched, setTwoFaTouched] = useState<boolean>(false);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const errorMessages = getTwoFaErrorMessages(code);

  const getSecretCode = () => dispatch(getSecretCodeAction());

  useEffect(() => {
    if (isAuth && mfaStatusRequest === RequestStatus.None) {
      dispatch(getMfaStatus());
    }
    if (updateStatusRequest === RequestStatus.Success) {
      setCode('');
    }
  }, [isAuth, updateStatusRequest]);

  const update2FA = (action: 1 | 0) => {
    setTwoFaTouched(false);
    dispatch(updateMfa({
      token: code,
      status: action === 1 ? IsActive.On : IsActive.Off,
      secret: secretCodeRequest,
      // password: password || undefined, // TODO maybe later
    }));
  };

  return {
    usePassword,
    code,
    secretCode: secretCodeUri,
    mfaStatusRequest,
    setCode,
    password,
    setPassword,
    getSecretCode,
    is_using_2fa,
    errorMessages,
    disableButton: Boolean(errorMessages.length || (usePassword && !password)),
    isLoading: [secretCodeRequest, updateStatusRequest].includes(RequestStatus.Pending),
    update2FA: useCallback(update2FA, [code, password]),
    twoFaTouched,
    setTwoFaTouched,
  }
}

export default use2Fa;
