import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types/enums';
import { initialStateDerivativesTrade } from './helper';
import { DerivativesTradeState } from './types';
import { IAccountDerivativesData } from '#/api/derivatives/dto';

const derivativesTradeSlice = createSlice({
  name: 'derivativesTrade',
  initialState: initialStateDerivativesTrade,
  reducers: {
    updateOpenedMarginPositionRequest(state, action: PayloadAction<RequestStatus>) {
      state.openMarginPositionRequest = action.payload;
    },
    updateCloseMarginPositionRequest(state, action: PayloadAction<RequestStatus>) {
      state.closeMarginPositionRequest = action.payload;
    },
    updateAccountMarginData(state, action: PayloadAction<IAccountDerivativesData>) {
      const { 
        max_position_size,
        liquidation_left_margin,
        order_required_margin,
        margin_call_left_margin,
      } = action.payload;
      state.maxPositionSize = max_position_size;
      state.requiredMargin = order_required_margin;
      state.availableMargin = liquidation_left_margin;
      state.marginCallLeftMargin = margin_call_left_margin;
    },
    updateAccountDerivativesEquity(state, action: PayloadAction<number>) {
      state.equity = action.payload;
    },
    updateAccountDerivativesPnl(state, action: PayloadAction<number>) {
      state.totalPnl = action.payload;
    },
    updateAccountAvailableMargin(state, action: PayloadAction<number>) {
      state.availableMargin = action.payload;
    },
    updateLiquidationLeftMargin(state, action: PayloadAction<number>) {
      state.liquidationLeftMargin = action.payload;
    },
    updateAccountUsedMargin(state, action: PayloadAction<number>) {
      state.usedMargin = action.payload;
    },
    updateMarginRatioPercent(state, action: PayloadAction<number>) {
      state.marginRatioPercentage = action.payload;
    },
    updateRiskLevelMessage(state, action: PayloadAction<string>) {
      state.riskLevelMessage = action.payload;
    },
    updateAccountMarginCallLeftMargin(state, action: PayloadAction<number>) {
      state.marginCallLeftMargin = action.payload;
    },
    updateMarginMaxPositionSize(state, action: PayloadAction<number>) {
      state.maxPositionSize = action.payload;
    },
    updateOpenDerivativesOrderRequest(state, action: PayloadAction<RequestStatus>) {
      state.openDerivativesOrderRequest = action.payload;
    },
    updateCancelDerivativesOrderRequest(state, action: PayloadAction<RequestStatus>) {
      state.closeDerivativesOrderRequest = action.payload;
    },
  },
});

export const {
  updateCloseMarginPositionRequest,
  updateOpenedMarginPositionRequest,
  updateAccountMarginData,
  updateAccountAvailableMargin,
  updateMarginMaxPositionSize,
  updateCancelDerivativesOrderRequest,
  updateOpenDerivativesOrderRequest,
  updateAccountMarginCallLeftMargin,
  updateAccountDerivativesEquity,
  updateAccountDerivativesPnl,
  updateAccountUsedMargin,
  updateMarginRatioPercent,
  updateRiskLevelMessage,
  updateLiquidationLeftMargin,
} = derivativesTradeSlice.actions;

export default derivativesTradeSlice.reducer;

export const selectDerivativesTradeState = (state: Store): DerivativesTradeState => state.derivatives.derivativesTrade;

export const selectOpenMarginPositionRequest: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.openMarginPositionRequest,
);

export const selectClosedMarginTradeRequest: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.closeMarginPositionRequest,
);

export const selectMarginMaxPosition: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.maxPositionSize,
);

export const selectAccountRequiredMargin: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.requiredMargin,
);

export const selectAccountAvailableMargin: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.availableMargin,     
);

export const selectAccountMarginCallLeftMargin: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.marginCallLeftMargin,     
);

export const selectAccountDerivativesEquity: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.equity,     
);

export const selectAccountDerivativesTotalPnl: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.totalPnl,     
);

export const selectOpenDerivativesOrderRequest: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.openDerivativesOrderRequest,
);

export const selectCancelDerivativesOrderRequest: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.closeDerivativesOrderRequest,
);

export const selectAccountUsedMargin: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.usedMargin,     
);

export const selectAccountMarginRatioPercent: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.marginRatioPercentage,     
);

export const selectAccountRiskLevelMessage: (store: Store) => string = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.riskLevelMessage,     
);

export const selectLiquidationLeftMargin: (store: Store) => number = createDraftSafeSelector(
  selectDerivativesTradeState,
  (trades: DerivativesTradeState) => trades.liquidationLeftMargin,     
);