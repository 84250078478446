import * as gql from 'gql-query-builder';
import {
  IVaultPayload,
  IVaultAccountPayload,
  IVaultTransactionPayload,
  IVaultTotalUserBalancePayload,
  IVaultAverageUserApyPayload,
  ICreateVaultDepositPayload,
  ICreateVaultWithdrawalPayload,
  IConvertCurrencyAmountPayload,
  IEstimateVaultInterestsPayload,
} from '#/api/vaults/ts/interface/payload';

import { vaultsFields, vaultsAccountsFields, vaultsTransactionsFields } from './fields';

export enum EVaultsQuery {
  getVaults = 'vaults',
  getvVultsAccounts = 'vaults_accounts',
  getVaultsTransactions = 'vaults_transactions',
  getVaultsTotalUserBalance = 'vaults_total_user_balance_quoted',
  getVaultsAverageUserApy = 'vaults_average_user_apy',
  createdVaultDeposit = 'create_vault_deposit',
  createdVaultWithdrawal = 'create_vault_withdrawal',
  getConvertCurrencyAmount = 'convert_currency_amount',
  getEstimateVaultInterests = 'estimate_vault_interests',
}

export default {
  getVaults: (data: IVaultPayload) =>
    gql.query({
      operation: EVaultsQuery.getVaults,
      fields: vaultsFields,
      variables: {
        vault_id: { value: data.vault_id, required: false, type: 'String' },
        currency_id: { value: data.currency_id, required: false, type: 'String' },
        is_active: { value: data.is_active, required: false, type: 'ToggleSwitch' },
        withdrawal_enabled: { value: data.withdrawal_enabled, required: false, type: 'ToggleSwitch' },
        deposit_enabled: { value: data.deposit_enabled, required: false, type: 'ToggleSwitch' },
        search: { value: data.search, required: false, type: 'String' },
        pager: { value: data.pager, required: false, type: 'PagerInput' },
        sort: { value: data.sort, required: false, type: 'SortInput' },
      },
    }),

  getVaultsAccounts: (data: IVaultAccountPayload) =>
    gql.query({
      operation: EVaultsQuery.getvVultsAccounts,
      fields: [
        ...vaultsAccountsFields,
        `balance_quoted: balance_quoted (quote_currency_id: "${data.quote_currency_id}")`,
      ],
      variables: {
        vault_account_id: { value: data.vault_account_id, required: false, type: 'String' },
        vault_id: { value: data.vault_id, required: false, type: 'String' },
        user_id: { value: data.user_id, required: false, type: 'String' },
        search: { value: data.search, required: false, type: 'String' },
        pager: { value: data.pager, required: false, type: 'PagerInput' },
        sort: { value: data.sort, required: false, type: 'SortInput' },
      },
    }),

  getVaultsTransactions: (data: IVaultTransactionPayload) =>
    gql.query({
      operation: EVaultsQuery.getVaultsTransactions,
      fields: vaultsTransactionsFields,
      variables: {
        vault_id: { value: data.vault_id, required: false, type: 'String' },
        user_id: { value: data.user_id, required: false, type: 'String' },
        vault_transaction_id: { value: data.vault_transaction_id, required: false, type: 'String' },
        transaction_type: { value: data.transaction_type, required: false, type: 'AccountTransactionType' },
        transaction_class: { value: data.transaction_class, required: false, type: 'VaultsTransactionClass' },
        search: { value: data.search, required: false, type: 'String' },
        currency_id: { value: data.currency_id, required: false, type: 'String' },
        pager: { value: data.pager, required: false, type: 'PagerInput' },
        date_range: { value: data.date_range, required: false, type: 'DateRangeInput' },
        sort: { value: data.sort, required: false, type: 'SortInput' },
      },
    }),

  getVaultsTotalUserBalance: (data: IVaultTotalUserBalancePayload) =>
    gql.query({
      operation: EVaultsQuery.getVaultsTotalUserBalance,
      fields: [],
      variables: {
        quote_currency_id: { value: data.quote_currency_id, required: true, type: 'String' },
        user_id: { value: data.user_id, required: false, type: 'String' },
      },
    }),

  getVaultsAverageUserApy: (data: IVaultAverageUserApyPayload) =>
    gql.query({
      operation: EVaultsQuery.getVaultsAverageUserApy,
      fields: [],
      variables: {
        user_id: { value: data.user_id, required: false, type: 'String' },
      },
    }),

  createVaultDeposit: (data: ICreateVaultDepositPayload) =>
    gql.mutation({
      operation: EVaultsQuery.createdVaultDeposit,
      fields: vaultsTransactionsFields,
      variables: {
        vault_id: { value: data.vault_id, required: true, type: 'String' },
        amount: { value: data.amount, required: true, type: 'Float' },
        user_id: { value: data.user_id, required: false, type: 'String' },
      },
    }),

  createVaultWithdrawal: (data: ICreateVaultWithdrawalPayload) =>
    gql.mutation({
      operation: EVaultsQuery.createdVaultWithdrawal,
      fields: vaultsTransactionsFields,
      variables: {
        vault_id: { value: data.vault_id, required: true, type: 'String' },
        amount: { value: data.amount, required: true, type: 'Float' },
        user_id: { value: data.user_id, required: false, type: 'String' },
      },
    }),

  getConvertCurrencyAmount: (data: IConvertCurrencyAmountPayload) =>
    gql.query({
      operation: EVaultsQuery.getConvertCurrencyAmount,
      fields: [],
      variables: {
        base_currency_id: { value: data.base_currency_id, required: true, type: 'String' },
        quote_currency_id: { value: data.quote_currency_id, required: true, type: 'String' },
        amount: { value: data.amount, required: true, type: 'Float' },
      },
    }),

  getEstimateVaultInterests: (data: IEstimateVaultInterestsPayload) =>
    gql.query({
      operation: EVaultsQuery.getEstimateVaultInterests,
      fields: [],
      variables: {
        vault_id: { value: data.vault_id, required: true, type: 'String' },
        amount: { value: data.amount, required: true, type: 'Float' },
        duration_seconds: { value: data.duration_seconds, required: false, type: 'Int' },
      },
    }),
};
