import { GqlClient } from './gql';
import configEnv from '#/config/config-env';
import TradeService from './trade/TradeService';
import UserService from './user/UserService';
import SettingsService from './settings/SettingsService';
import ThirdPartyApi from './third-party-api/ThirdPartyApi';
import VaultsService from './vaults/VaultsService';
import DerivativesService from './derivatives/DerivativesService';
import CryptoPayApi from './cryptoPay';
import ReferralService from './referral/ReferralService';
import WalletsService from './wallets/WalletsService';

const gqlRequestClientVako = new GqlClient(configEnv.vakoUrl);
const gqlRequestClientMarginTrade = new GqlClient(configEnv.derivativesTradeUrl);

export interface Services {
  gqlRequestClientVako: GqlClient,
  tradeService: TradeService,
  DerivativesService: DerivativesService,
  userService: UserService,
  settingsService: SettingsService,
  vaultsService: VaultsService,
  referralService: ReferralService,
  thirdPartyApi: ThirdPartyApi,
  walletsService: WalletsService,
}

export default {
  gqlRequestClientVako,
  gqlRequestClientMarginTrade,
  tradeService: new TradeService(gqlRequestClientVako),
  DerivativesService: new DerivativesService(gqlRequestClientMarginTrade),
  userService: new UserService(gqlRequestClientVako),
  settingsService: new SettingsService(gqlRequestClientVako),
  vaultsService: new VaultsService(gqlRequestClientVako),
  referralService: new ReferralService(gqlRequestClientVako),
  thirdPartyApi: new ThirdPartyApi(),
  cryptoPayApi: new CryptoPayApi(),
  walletsService: new WalletsService(gqlRequestClientVako),
};
