export const vaultsCurrency = ['serial_id', 'currency_id', 'type', 'precision', 'name', 'is_active'];

export const vaultsFields = [
  'serial_id',
  'vault_id',
  'currency_id',
  'name',
  'annual_rate_percent',
  'max_withdrawal_amount',
  'max_deposit_amount',
  'min_withdrawal_amount',
  'min_deposit_amount',
  'cut_off_time_seconds',
  'lock_in_period_seconds',
  'effective_date_from',
  'effective_date_to',
  'rate_type',
  'is_active',
  'withdrawal_enabled',
  'deposit_enabled',
  'last_interest_at',
  'created_at',
  'updated_at',
  'total_balance',
  {
    currency: vaultsCurrency,
  },
];

export const vaultsAccountsFields = [
  'serial_id',
  'vault_account_id',
  'vault_id',
  'user_id',
  'balance',
  'version',
  'created_at',
  'updated_at',
  {
    vault: vaultsFields,
  },
];

export const vaultsTransactionsFields = [
  'serial_id',
  'vault_id',
  'user_id',
  'vault_transaction_id',
  'vault_account_id',
  'amount',
  'balance_after',
  'currency_id',
  'transaction_type',
  'transaction_class',
  'comment',
  'created_by',
  'created_at',
  {
    vault: vaultsFields,
  },
  {
    currency: vaultsCurrency,
  },
];
