import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { makeSignIn, selectIsAuth, selectTokenRefreshing, updateAuthStatus, updateTokenRefreshing } from '#reducers/user/user';
import { getCookie, validateJwt } from '#/util';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import { RequestStatus } from '#/types';

const COOKIES_JWT_KEY = 'exchange_trading_auth';

export const useAuthWithCookies = () => {
  const dispatch = useDispatch();

  const isAuth = useSelector(selectIsAuth);
  const isTokenRefreshing = useSelector(selectTokenRefreshing);

  const jwt = getCookie(COOKIES_JWT_KEY) || '';
  const isJwtValid = validateJwt(jwt);

  const makeSignInWithCookies = ({ isRefresh }: { isRefresh?: boolean } = {}) => {
    try {
      const jwtDecoded = jwtDecode<JwtPayload>(jwt);
      const expiresAt = jwtDecoded.exp ? jwtDecoded.exp * 1000 : Date.now() + 1000 * 60 * 30;

      dispatch(makeSignIn({
        jwt,
        expires_at: expiresAt,
        checkIn: false,
      }));
    } catch (error) {
      novaToast.error('Login failed', { error: String(error) });
      dispatch(updateAuthStatus(RequestStatus.Failed));
      console.error(error);
    } finally {
      if (isRefresh) {
        dispatch(updateTokenRefreshing(false));
      }
    }
  }

  /* login via cookies */
  useEffect(() => {
    if (!isAuth && isJwtValid) {
      makeSignInWithCookies()
    }
  }, [isAuth, isJwtValid]);

  /* refresh token via cookies */
  useEffect(() => {
    if (isTokenRefreshing && isJwtValid) {
      makeSignInWithCookies({ isRefresh: true });
    }
  }, [isTokenRefreshing]);

  return {
    isValidCookiesJwt: isJwtValid,
  }
}