import { FiatFieldErrorType } from "#/hooks/trade/wallets/depositWithdraw/helpers/withdrawFiatHelper";
import { FavoriteFiatDestination, FiatFields, FiatWalletFields } from "#/types"

export const checkPaymentMethodName = (fields: FavoriteFiatDestination) => {
  const fieldsData = {...fields};
  !fieldsData.name?.length && (fieldsData.name = fieldsData.bank_name);

  return fieldsData;
};

export const getAccountNumberExistError = () => ({
  message: _t('Account number already exists', 'NOVA_PAYMENTS.ERROR_ACCOUNT_NUMBER_EXIST'),
  type: FiatFieldErrorType.Exists,
});

export const checkIsRequiredFiatFields = (fieldName: FiatFields | FiatWalletFields, configFields: FiatFields[] | FiatWalletFields[]) => {
  return configFields.some((field) => field === fieldName);
};

