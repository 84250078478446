import { ThunkDispatch, AnyAction, ListenerEffectAPI } from '@reduxjs/toolkit';

import { Store } from '../../defaultState';
import { RequestStatus } from '#/types/enums';
import { throttle } from '#/util';
import {
  AuthStorageItems,
  logout,
  logoutActionName,
  selectAuthStatus,
  selectTokenExpires,
  sessionInterval
} from '#reducers/user/user';

function logoutEffect(action: AnyAction, listenerApi: ListenerEffectAPI<Store, ThunkDispatch<Store, unknown, AnyAction>>) {
  const state: Store = listenerApi.getState();

  const isExpiredToken = Date.now() >= Number(selectTokenExpires(state)) || !selectTokenExpires(state);
  const isAuthStatusSuccess = selectAuthStatus(state) === RequestStatus.Success;
  const prevActionTime = localStorage.getItem(AuthStorageItems.PrevActionTime) || Date.now();

  if (isAuthStatusSuccess) {
    const isUserActive = prevActionTime && (Date.now() - Number(prevActionTime)) < sessionInterval;

    if (!isUserActive || isExpiredToken) {
      if (!action.type.includes(logoutActionName)) {
        // listenerApi.dispatch(logout()); /* client's custom - delegate logout to main platform*/
      }
    }
  }
}

export default throttle(logoutEffect, 1000) as (action: AnyAction, listenerApi: ListenerEffectAPI<Store, ThunkDispatch<Store, unknown, AnyAction>>) => void;
