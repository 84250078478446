import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePager } from '../helpers';
import { useCountNumItems } from '#/util';

import {
  getReferralRewardsHistory,
  selectReferralRewardsHistory,
  selectReferralRewardsHistoryNextPageExists,
  selectReferralRewardsHistoryStatus,
} from '#reducers/referral';

import { IGetReferralRewardsHistoryPayload, IReferralRewardsHistory } from '#/api/referral/dto';
import { RequestStatus } from '#/types';

interface IUseReferralsHistoryResult {
  history: Array<IReferralRewardsHistory>,
  isLoading: boolean,
  page: number,
  setPage: (page: number) => void,
  limit: number,
  numItems: number,
}

const DEFAULT_PAGE_LIMIT: number = 10;

export const useReferralsHistory = (): IUseReferralsHistoryResult => {
  const dispatch = useDispatch();

  const history: Array<IReferralRewardsHistory> = useSelector(selectReferralRewardsHistory);
  const status: RequestStatus = useSelector(selectReferralRewardsHistoryStatus);
  const nextPageExists: boolean = useSelector(selectReferralRewardsHistoryNextPageExists);

  const { page, pager, setPage } = usePager({ limit: DEFAULT_PAGE_LIMIT, offset: 0 });

  const requestParams: IGetReferralRewardsHistoryPayload = { pager };

  useEffect(() => {
    dispatch(getReferralRewardsHistory(requestParams))
  }, [JSON.stringify(requestParams)]);

  const numItems = useCountNumItems({
    array: history,
    pager,
    nextPageExists,
  });

  return {
    history,
    isLoading: [RequestStatus.None, RequestStatus.Pending].includes(status),
    page,
    setPage,
    numItems,
    limit: DEFAULT_PAGE_LIMIT,
  };
};
