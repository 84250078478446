import vaultIcon from '#images/icons/Vault_logo.svg';
import vaultIconActive from '#images/icons/Vault_logo_active.svg';
import vaultIconLight from '#images/icons/Vault_logo-light.svg';
import vaultIconActiveLight from '#images/icons/Vault_logo_active-light.svg';
import iconCryptoPay from '#images/icons/icon-crypto-pay.svg';
import iconCryptoPayHover from '#images/icons/icon-crypto-pay-hover.svg';

import referralsIcon from '#images/icons/icon-referrals.svg';
import referralsIconHover from '#images/icons/icon-referrals-hover.svg';

import { AnaliticsIds } from './analytics';
import { useCryptoPay, useVaults, useDerivatives, useReferrals } from './config-env';
import { Paths } from '#/config/templates/nova/nova';
import { extraPages } from '#helper-module';
import { NovaSettingsPages } from '#/nova/views/trade/settings/pages';

export type Nav = {
  title: string,
  translation: string,
  link?: string,
  customLink?: string,
  icon?: string,
  customIcon?: string,
  customIconHover?: string,
  customIconLight?: string,
  customIconLightHover?: string,
  use?: boolean,
  isPulsing?: boolean,
  handler?: Function,
  id?: string,
  isAuth?: boolean,
  cryptoPay?: boolean,
};

export const customLoginLink = 'https://noones.com/register';

export const sideDesktopNavigation: Array<Nav> = [
  {
    title: 'Dashboard',
    translation: 'NAV.DASHBOARD',
    link: Paths.Dashboard,
    icon: 'fas fa-th-large',
    isAuth: true,
    id: AnaliticsIds.linkHome,
  },
  {
    title: 'Join Us',
    translation: 'NOVA_AUTH_REQUIRED.JOIN_US',
    customLink: customLoginLink,
    icon: 'fas fa-arrow-right',
    isAuth: false,
    id: AnaliticsIds.accLogin,
  },
  {
    title: 'Exchange',
    translation: 'NAV.TRADES',
    link: Paths.Exchange,
    icon: 'fas fa-exchange',
    id: AnaliticsIds.linkTrade,
  },
  {
    title: 'Derivatives',
    translation: 'NAV.DERIVATIVES',
    link: '/derivatives',
    icon: 'fas fa-balance-scale-left',
    isAuth: true,
    use: useDerivatives,
    id: AnaliticsIds.linkDerivatives,
  },
  {
    title: 'Markets',
    translation: 'NAV.MARKETS',
    link: Paths.Markets,
    icon: 'fas fa-chart-bar',
    id: AnaliticsIds.linkMarket,
  },
  // { //client's custom
  //   title: 'Quick Trade',  
  //   translation: 'NAV.QUICK_TRADE',
  //   link: Paths.QuickBuySell,
  //   icon: 'fa fa-bolt',
  //   isAuth: true,
  //   id: AnaliticsIds.linkQuickBuySell,
  // },
  {
    title: 'Wallets',
    translation: 'NAV.WALLETS',
    link: Paths.Wallets,
    icon: 'fa fa-wallet',
    isAuth: true,
    id: AnaliticsIds.linkWallet,
  },
  {
    title: 'Vaults',
    translation: 'NAV.VAULTS',
    link: Paths.Vaults,
    customIcon: vaultIcon,
    customIconHover: vaultIconActive,
    customIconLight: vaultIconLight,
    customIconLightHover: vaultIconActiveLight,
    use: useVaults,
    id: AnaliticsIds.linkVaults,
    isAuth: true,
    isPulsing: true,
  },
  {
    title: 'Crypto Pay',
    translation: '',
    link: Paths.CryptoPay,
    customIcon: iconCryptoPay,
    customIconHover: iconCryptoPayHover,
    use: useCryptoPay,
    isAuth: true,
    id: AnaliticsIds.linkCryptoPay,
  },
  // { //client's custom
  //   title: 'Settings',
  //   translation: 'NAV.SETTINGS',
  //   link: `${Paths.Settings}/${NovaSettingsPages.Profile}`,
  //   icon: 'fa fa-wrench',
  //   isAuth: true,
  //   id: AnaliticsIds.linkSettings,
  // },
  {
    title: 'Referrals',
    translation: 'NAV.REFERRALS',
    link: Paths.Referrals,
    customIcon: referralsIcon,
    customIconHover: referralsIconHover,
    isAuth: true,
    id: AnaliticsIds.linkReferrals,
    use: useReferrals,
  },
  ...extraPages,
  // @ts-ignore
];

export const sideDesktopBottomNavigation: Array<Nav> = [];

export const sideMobileNavigation: Array<Nav> = [
  {
    title: 'Dashboard',
    translation: 'NAV.DASHBOARD',
    link: Paths.Dashboard,
    icon: 'fas fa-th-large',
    isAuth: true,
    id: AnaliticsIds.linkHome,
  },
  {
    title: 'Join Us',
    translation: 'NOVA_AUTH_REQUIRED.JOIN_US',
    customLink: customLoginLink,
    icon: 'fas fa-arrow-right',
    isAuth: false,
    id: AnaliticsIds.accLogin,
  },
  {
    title: 'Exchange',
    translation: 'NAV.TRADES',
    link: Paths.Exchange,
    icon: 'fas fa-exchange',
    id: AnaliticsIds.linkTrade,
  },
  {
    title: 'Derivatives',
    translation: 'NAV.DERIVATIVES',
    link: '/derivatives',
    icon: 'fas fa-balance-scale-left',
    isAuth: true,
    use: useDerivatives,
    id: AnaliticsIds.linkDerivatives,
  },
  {
    title: 'Markets',
    translation: 'NAV.MARKETS',
    link: Paths.Markets,
    icon: 'fas fa-chart-bar',
    id: AnaliticsIds.linkMarket,
  },
  // { //client's custom
  //   title: 'Quick Trade',
  //   translation: 'NAV.QUICK_TRADE',
  //   link: Paths.QuickBuySell,
  //   icon: 'fas fa-bolt',
  //   isAuth: true,
  //   id: AnaliticsIds.linkQuickBuySell,
  // },
  {
    title: 'Wallets',
    translation: 'NAV.WALLETS',
    link: Paths.Wallets,
    icon: 'fa fa-wallet',
    isAuth: true,
    id: AnaliticsIds.linkWallet,
  },
  {
    title: 'Vaults',
    translation: 'NAV.VAULTS',
    link: Paths.Vaults,
    customIcon: vaultIcon,
    customIconLight: vaultIconLight,
    use: useVaults,
    isAuth: true,
    id: AnaliticsIds.linkVaults,
  },
  {
    title: 'Crypto Pay',
    translation: '',
    link: Paths.CryptoPay,
    customIcon: iconCryptoPayHover,
    use: useCryptoPay,
    isAuth: true,
    id: AnaliticsIds.linkCryptoPay,
  },
  // { //client's custom
  //   title: 'Settings',
  //   translation: 'NAV.SETTINGS',
  //   link: Paths.Settings,
  //   icon: 'fa fa-wrench',
  //   isAuth: true,
  //   id: AnaliticsIds.linkSettings,
  // },
  {
    title: 'Referrals',
    translation: 'NAV.REFERRALS',
    link: Paths.Referrals,
    customIcon: referralsIconHover,
    isAuth: true,
    id: AnaliticsIds.linkReferrals,
    use: useReferrals,
  },
  ...extraPages,
];

export const headerNavigation: Array<Nav> = [
  // {
  //   title: 'FAQ',
  //   translation: 'NAV.FAQ',
  //   link: '/faq',
  //   icon: 'fas fa-question-circle',
  // },
  {
    title: 'Support',
    translation: 'NAV.SUPPORT',
    customLink: 'https://www.shiftmarkets.com/request-a-demo',
    icon: 'fad fa-comments',
  },
];
