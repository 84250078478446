/*
  for the request response and for the ws message the message may be slightly different,
  in this case trying to convert them into the same representation
  to prevent multiple toast notifications with the same ID from being displayed
*/

export const prepareErrorToastId = (errorMessage: string = ''): string | undefined => {
  if (!errorMessage) return undefined;

  let toastId = errorMessage.replace(/(?:Error: )?RRN: \w+, (.+)/, '$1');  // Replacing 'Error: ', 'RRN: ',

  // removing '.' if present
  if (toastId.endsWith('.')) {
    toastId = toastId.slice(0, -1);
  }

  return toastId;
};
