export const limitsStatusPeriods = [
  { daily: ['limit', 'filled', 'remaining'] },
  { weekly: ['limit', 'filled', 'remaining'] },
  { monthly: ['limit', 'filled', 'remaining'] },
];

export const calculatePaymentsLimitsFields = [
  {
    payments: limitsStatusPeriods,
  },
  {
    aggregated: limitsStatusPeriods,
  },
];
