import { RequestStatus } from '#/types';
import { DerivativesConfigState } from './types';

export const initialStateDerivativesConfig: DerivativesConfigState = {
  derivativesConfig: {
    margin_currency: '',
  },
  derivativesConfigRequestStatus: RequestStatus.None,
  isOpenedOrderbook: false,
  isOnOpenedPositionsTab: true,
}