import { createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import {
  updateClaimReferralStatus,
  updateCreateReferralStatus,
  updateGetReferralsStatus,
  updateReferralCode,
  updateReferralRewardsHistory,
  updateReferralRewardsHistoryNextPageExists,
  updateReferralRewardsHistoryStatus,
  updateReferralTrackInfo,
  updateReferralTrackInfoStatus,
} from './referral';

import { novaToast } from '#/nova/components/other/toast/novaToast';
import { throttle, handleErrorThunk, debounce } from '#/util';
import { preparePagerDifferencePlus1, prepareResultPagerDifference } from '#/util/pager';

import {
  IClaimReferralPayload,
  ICreateReferralPayload,
  IGetReferralRewardTotalPayload,
  IGetReferralRewardsHistoryPayload,
  IReferralRewardsHistory,
} from '#/api/referral/dto';
import { RequestStatus } from '#/types/enums';
import { IThunkApi } from '#/store/root';

export const getReferrals = createAsyncThunk(
  'referral/get',
  throttle(async (_: unknown, { dispatch, extra }: IThunkApi) => {
    dispatch(updateGetReferralsStatus(RequestStatus.Pending));
    try {
      const { referrals } = await extra.referralService.getReferrals();

      dispatch(updateReferralCode(referrals[0]?.referral_id || ''));
    } catch (error) {
      dispatch(updateGetReferralsStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get referral code error', dispatch);
    }
  }, 1500) as unknown as AsyncThunkPayloadCreator<Promise<void>, void, IThunkApi>
);

export const getReferralTrackInfo = createAsyncThunk(
  'referral/getReferralTrackInfo',
  throttle(async (payload: IGetReferralRewardTotalPayload, { dispatch, extra }: IThunkApi) => {
    dispatch(updateReferralTrackInfoStatus(RequestStatus.Pending));
    try {
      const [{ referrer_total_reward_quoted }, { referred_users_count }] = await Promise.all([
        extra.referralService.getReferralRewardTotal(payload),
        extra.referralService.getReferredUsersCount(payload.user_id),
      ]);

      dispatch(updateReferralTrackInfo({
        totalReferralReward: referrer_total_reward_quoted,
        totalReferralsCount: referred_users_count,
      }));
    } catch (error) {
      dispatch(updateReferralTrackInfoStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get referred users count failed', dispatch);
    }
  }, 800) as unknown as AsyncThunkPayloadCreator<Promise<void>, IGetReferralRewardTotalPayload, IThunkApi>
);

export const getReferralRewardsHistory = createAsyncThunk(
  'referral/getReferralRewardsHistory',
  debounce(800, async (payload: IGetReferralRewardsHistoryPayload, { dispatch, extra }: IThunkApi) => {
    dispatch(updateReferralRewardsHistoryStatus(RequestStatus.Pending));
    try {
      const { account_transactions } = await extra.referralService.getReferralRewardsHistory(preparePagerDifferencePlus1(payload));
      const pagerTransactions = prepareResultPagerDifference(account_transactions, payload.pager?.limit);

      dispatch(updateReferralRewardsHistory(pagerTransactions as Array<IReferralRewardsHistory>));
      dispatch(updateReferralRewardsHistoryNextPageExists(account_transactions.length > pagerTransactions.length));
    } catch (error) {
      dispatch(updateReferralRewardsHistoryStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get referred users count failed', dispatch);
    }
  }) as unknown as AsyncThunkPayloadCreator<Promise<void>, IGetReferralRewardsHistoryPayload, IThunkApi>
);

export const createReferral = createAsyncThunk(
  'referral/create',
  throttle(async (payload: ICreateReferralPayload, { dispatch, extra }: IThunkApi) => {
    dispatch(updateCreateReferralStatus(RequestStatus.Pending));
    try {
      const { create_referral } = await extra.referralService.createReferral(payload);

      dispatch(updateReferralCode(create_referral.referral_id));
    } catch (error) {
      dispatch(updateCreateReferralStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Create referral code error', dispatch);
    }
  }, 1500) as unknown as AsyncThunkPayloadCreator<Promise<void>, ICreateReferralPayload, IThunkApi>
);

export const claimReferral = createAsyncThunk(
  'referral/create',
  throttle(async (payload: IClaimReferralPayload, { dispatch, extra }: IThunkApi) => {
    dispatch(updateClaimReferralStatus(RequestStatus.Pending));
    try {
      const { claim_referral } = await extra.referralService.claimReferral(payload);
      console.log(claim_referral)

      dispatch(updateClaimReferralStatus(claim_referral ? RequestStatus.Success : RequestStatus.Failed));

      if (!claim_referral) novaToast.error(_t('Referral error', 'NOVA_REFERRAL.CLAIM_ERROR'))
    } catch (error) {
      dispatch(updateCreateReferralStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Referral error', dispatch);
    }
  }, 1500) as unknown as AsyncThunkPayloadCreator<Promise<void>, IClaimReferralPayload, IThunkApi>
);
