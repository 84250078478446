import { Languages } from '#/config/language/langConfig';
import { ISelectedInstrument, Themes } from '#/types';

export interface IUserPlatformParamsState {
  lang: string,
  theme: string,
  pair: string,
  isAllEmpty: boolean,
}

enum EUserPlatformSettingsParams {
  Language = 'lang',
  Theme = 'theme',
  Pair = 'pair',
}

export const getUserSettingsFromParams = (): IUserPlatformParamsState => {
  const urlParams = new URLSearchParams(window.location.search);

  // get search params
  const theme = urlParams.get(EUserPlatformSettingsParams.Theme) || '';
  const lang = urlParams.get(EUserPlatformSettingsParams.Language) || '';
  const pair = urlParams.get(EUserPlatformSettingsParams.Pair) || '';

  const isAllEmpty = !theme && !lang && !pair;

  return {
    theme,
    pair,
    lang,
    isAllEmpty,
  };
};

export const validateTheme = (theme: string = ''): boolean => {
  return !!theme && Object.values(Themes).includes(theme as Themes);
};

export const validateLanguage = (lang: string = ''): boolean => {
  return !!lang && Object.values(Languages).includes(lang as Languages);
};

export const findPair = (pair: string = '', instruments: Array<ISelectedInstrument> = []): ISelectedInstrument | undefined => {
  return instruments.find(({ instrument_id }) => instrument_id.toLowerCase() === pair.toLowerCase());
};
