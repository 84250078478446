export enum NovaElementsOptions {
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'default'
}

export enum NovaElementsSizes {
  Big = 'big',
  Small = 'small',
  Smaller = 'smaller',
  Smallest = 'smallest',
  Medium = 'medium',
  MediumSmall = 'medium-small'
}

export enum Cases {
  Lowercase = 'lowercase',
  Uppercase = 'uppercase',
}

export enum DropdownMode {
  Scroll = 'scroll',
  Select = 'select',
}

export enum NotificationTrigger {
  signin = 'signin',

  kyc_approved = 'kyc_approved',
  kyc_rejected = 'kyc_rejected',
  kyc_incomplete = 'kyc_incomplete',

  order_completed = 'order_completed',
  order_rejected = 'order_rejected',
  order_cancelled = 'order_cancelled',
  order_new = 'order_new',

  conversion_new = 'conversion_new',
  conversion_completed = 'conversion_completed',
  conversion_rejected = 'conversion_rejected',

  payment_new = 'payment_new',
  payment_rejected = 'payment_rejected',
  payment_completed = 'payment_completed',
  payment_unconfirmed = 'payment_unconfirmed',
  manual_balance_update = 'manual_balance_update',
  mfa_enabled = 'mfa_enabled',

  UserCreated = 'user_created',
  UserUpdated = 'user_updated',

  AccountCreated = 'account_created',

  CryptoAddressCreated = 'crypto_address_created',

  TradeCompleted = 'trade_completed',
  VaultExpired = 'vault_expired',
  VaultDeleted = 'vault_deleted',
}

export enum GroupNotifications {
  Orders = 'Orders',
  Transactions = 'Transactions',
  Conversions = 'Conversions',
  Account = 'Account',
  User = 'User',
  Kyc = 'KYC',
  Vaults = 'Vaults',
}

export enum OtherNotifications {
  KycStatus = 'kyc_status',
}

export enum TypeNotification {
  Client = 'Client',
  Email = 'Email',
  Push = 'Push',
  Sms = 'SMS',
}

export interface ToggleValues {
  name: string,
  value: string
}

export interface Toggle {
  [key: string]: ToggleValues[]
}

export type NotificationsOptions = {
  [key in TypeNotification]: NotificationTrigger[]
}

export enum ERegularExpression{
Symbols = '[!_@#*-+()-/="№;%:?*]+',
}