import React from 'react';

import { FiatFields, FiatWalletFields, NovaElementsOptions, NovaElementsSizes } from '#/types';
import { BankFields } from '../NovaBankFields';
import NovaTextInput from '#/nova/components/controls/form/text-input/NovaTextInput';
import NovaTradeTextField from '#/nova/components/controls/form/trade-text-field/NovaTradeTextField';
import NovaTradeLabel from '#/nova/components/text/trade-label/NovaTradeLabel';
import bookImage from '#images/book.svg';
import { checkIsRequiredFiatFields } from '../../../../payments/add-modal/helpers';
import NovaButton from '#/nova/components/controls/button/NovaButton';

export const requiredMxnAddressbookFields = [
  FiatFields.BeneficiaryName,
  FiatFields.BankName,
  FiatFields.BeneficiaryAccountNumber,
]

export const requiredMxnWalletFields = [
  FiatWalletFields.BeneficiaryName,
  FiatWalletFields.BeneficiaryTaxId,
  FiatWalletFields.BankName,
  FiatWalletFields.BeneficiaryAccountNumber,
]

export const mxnFiatProperties = [
  ...requiredMxnWalletFields,
  FiatWalletFields.Notes
]

export interface IWithdrawFiatMxnFieldsProps {
  fields: BankFields,
  setFields: (fields: BankFields) => void,
  getFieldKey: (fieldKeyAddressbook: FiatFields) => string,
  getFieldValue: (field: FiatFields) => string,
  onFieldTouch: (field: FiatFields) => () => void,
  defineFieldClassName: (field: FiatFields) => string,
  renderErrorMessage: (field: FiatFields) => string,
  setOpenModalFiat: (value: boolean) => void,
  showAddressbookImportButton: boolean,
}

const WithdrawFiatMxnFields = (
  {
    fields,
    setFields,
    getFieldKey,
    getFieldValue,
    onFieldTouch,
    defineFieldClassName,
    renderErrorMessage,
    setOpenModalFiat,
    showAddressbookImportButton,
  }: IWithdrawFiatMxnFieldsProps) => {

  return (
    <>
      {showAddressbookImportButton && (
        <NovaButton
          onClick={() => setOpenModalFiat(true)}
          className="nova-wallet-address-book"
          variant={NovaElementsOptions.Secondary}
          size={NovaElementsSizes.MediumSmall}
          value={_t('Import from Fiat Address Book', 'NOVA_WITHDRAW.IMPORT_FIAT_ADDRESS_BOOK')}
        />
      )}

      <NovaTradeTextField
        className={`beneficiary-name ${defineFieldClassName(FiatFields.BeneficiaryName)}`}
        labelMain={<NovaTradeLabel
          isRequired={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryName, requiredMxnWalletFields)}
          label={_t('Beneficiary Name', 'NOVA_WITHDRAW.BANK_BENEFICIARY_NAME')} />}
        value={getFieldValue(FiatFields.BeneficiaryName) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryName)]: e.target.value })}
        placeholder={''}
        error={renderErrorMessage(FiatFields.BeneficiaryName)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryName)}
      />

      <NovaTextInput
        label={_t('Beneficiary Tax ID', 'NOVA_WITHDRAW.BENEFICIARY_TAX_ID')}
        value={getFieldValue(FiatFields.BeneficiaryTaxId) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryTaxId)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryTaxId, requiredMxnWalletFields)}
        className={`beneficiary-tax-id ${defineFieldClassName(FiatFields.BeneficiaryTaxId)}`}
        detailsError={renderErrorMessage(FiatFields.BeneficiaryTaxId)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryTaxId)}
      />

      <NovaTextInput
        label={_t('Bank Name', 'NOVA_WITHDRAW.BANK_NAME')}
        value={getFieldValue(FiatFields.BankName) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankName)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BankName, requiredMxnWalletFields)}
        detailsError={renderErrorMessage(FiatFields.BankName)}
        className={`bank-name ${defineFieldClassName(FiatFields.BankName)}`}
        onBlur={onFieldTouch(FiatFields.BankName)}
      />

      <NovaTextInput
        required={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryAccountNumber, requiredMxnWalletFields)}
        label={_t('CLABE', 'NOVA_WITHDRAW.CLABE')}
        value={getFieldValue(FiatFields.BeneficiaryAccountNumber) || ''}
        size={NovaElementsSizes.Small}
        maxLength={18}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryAccountNumber)]: e.target.value })}
        detailsError={renderErrorMessage(FiatFields.BeneficiaryAccountNumber)}
        className={`clabe ${defineFieldClassName(FiatFields.BeneficiaryAccountNumber)}`}
        onBlur={onFieldTouch(FiatFields.BeneficiaryAccountNumber)}
      />

      <NovaTextInput
        label={_t('Notes', 'NOVA_WITHDRAW.NOTES')}
        value={getFieldValue(FiatFields.Notes) || ''}
        size={NovaElementsSizes.Small}
        className='notes'
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.Notes)]: e.target.value })}
      />
    </>
  );
};

export default WithdrawFiatMxnFields;