import { EShareOptions, IShareOption } from './types';

export const checkIsMobileOrTablet = (): boolean => /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);

export const checkIsNavigatorShareAvailable = () => !!navigator.canShare && navigator.canShare({ title: 'test', url: 'test' });

export const createShareLinkParamsFromObj = (obj: { [key: string]: any }) => {
  const linkParams: string = Object.entries(obj).reduce((acc = '', [key, value]) => {
    if (value) {
      return acc += `${acc.length ? '&' : ''}${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
    }

    return acc;
  }, '');

  return linkParams.length > 0 ? `?${linkParams}` : ''
};

export const createShareOptionsConfig = (): Array<IShareOption> => ([
  {
    faIcon: 'fab fa-facebook-messenger',
    label: _t('Messenger', 'NOVA_REFERRALS.SHARE_OPTION_FACEBOOK'),
    shareOptionType: EShareOptions.FacebookMessenger,
  },
  {
    faIcon: 'fab fa-viber',
    label: _t('Viber', 'NOVA_REFERRALS.SHARE_OPTION_VIBER'),
    shareOptionType: EShareOptions.Viber,
  },
  {
    faIcon: 'fab fa-whatsapp',
    label: _t('WhatsApp', 'NOVA_REFERRALS.SHARE_OPTION_WHATSAPP'),
    shareOptionType: EShareOptions.WhatsApp,
  },
  checkIsNavigatorShareAvailable() ? {
    faIcon: 'fas fa-share',
    label: _t('More options', 'NOVA_REFERRALS.SHARE_OPTION_MORE'),
    shareOptionType: EShareOptions.Messages,
  } : null,
].filter((o) => !!o) as Array<IShareOption>);
