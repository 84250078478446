import {
  IVaultPayload,
  IVaultAccountPayload,
  IVaultTransactionPayload,
  IVaultTotalUserBalancePayload,
  IVaultAverageUserApyPayload,
  ICreateVaultDepositPayload,
  ICreateVaultWithdrawalPayload,
  IConvertCurrencyAmountPayload,
  IEstimateVaultInterestsPayload,
} from '#/api/vaults/ts/interface/payload';

import {
  IVaultResponse,
  IVaultAccountResponse,
  IVaultTransactionResponse,
  IVaultTotalUserBalanceResponse,
  IVaultAverageUserApyResponse,
  ICreateVaultDepositResponse,
  ICreateVaultWithdrawalResponse,
  IConvertCurrencyAmountResponse,
  IEstimateVaultInterestsResponse,
} from '#/api/vaults/ts/interface/response';

import { GqlClient } from '../gql';
import vaultsGql from './vaults-gql';

export default class VaultsService {
  public gqlRequestClient: GqlClient;

  constructor(gqlRequestClient: GqlClient) {
    this.gqlRequestClient = gqlRequestClient;
  }

  getVaults(data: IVaultPayload): Promise<{ vaults: IVaultResponse[] }> {
    return this.gqlRequestClient.request(vaultsGql.getVaults(data));
  }

  getVaultsAccounts(data: IVaultAccountPayload): Promise<{ vaults_accounts: IVaultAccountResponse[] }> {
    return this.gqlRequestClient.request(vaultsGql.getVaultsAccounts(data));
  }

  getVaultsTransactions(data: IVaultTransactionPayload): Promise<{ vaults_transactions: IVaultTransactionResponse[] }> {
    return this.gqlRequestClient.request(vaultsGql.getVaultsTransactions(data));
  }

  getVaultsTotalUserBalance(
    data: IVaultTotalUserBalancePayload
  ): Promise<{ vaults_total_user_balance_quoted: IVaultTotalUserBalanceResponse }> {
    return this.gqlRequestClient.request(vaultsGql.getVaultsTotalUserBalance(data));
  }

  getVaultsAverageUserApy(
    data: IVaultAverageUserApyPayload
  ): Promise<{ vaults_average_user_apy: IVaultAverageUserApyResponse }> {
    return this.gqlRequestClient.request(vaultsGql.getVaultsAverageUserApy(data));
  }

  createVaultDeposit(
    data: ICreateVaultDepositPayload
  ): Promise<{ vaults_transactions: ICreateVaultDepositResponse[] }> {
    return this.gqlRequestClient.request(vaultsGql.createVaultDeposit(data));
  }

  createVaultWithdrawal(
    data: ICreateVaultWithdrawalPayload
  ): Promise<{ vaults_transactions: ICreateVaultWithdrawalResponse[] }> {
    return this.gqlRequestClient.request(vaultsGql.createVaultWithdrawal(data));
  }

  getConvertCurrencyAmount(
    data: IConvertCurrencyAmountPayload
  ): Promise<{ convert_currency_amount: IConvertCurrencyAmountResponse }> {
    return this.gqlRequestClient.request(vaultsGql.getConvertCurrencyAmount(data));
  }

  getEstimateVaultInterests(
    data: IEstimateVaultInterestsPayload
  ): Promise<{ estimate_vault_interests: IEstimateVaultInterestsResponse }> {
    return this.gqlRequestClient.request(vaultsGql.getEstimateVaultInterests(data));
  }
}
