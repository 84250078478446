import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { initialWalletsState } from './helpers';
import { IWalletsState, CalculatedPaymentsLimits } from './types';
import { RequestStatus } from '#/types';

const walletsSlice = createSlice({
  name: 'walletsSlice',
  initialState: initialWalletsState,
  reducers: {
    setWalletCurrency(state, action: PayloadAction<string>) {
      state.currency = action.payload;
    },
    setCalculatedPaymentLimits(state, action: PayloadAction<CalculatedPaymentsLimits>) {
      state.currentPaymentLimits = action.payload;
    },
    setCalculatedPaymentLimitsStatus(state, action: PayloadAction<RequestStatus>) {
      state.currentPaymentLimitsStatus = action.payload;
    },
  },
});

export const {
  setWalletCurrency,
  setCalculatedPaymentLimits,
  setCalculatedPaymentLimitsStatus,
} = walletsSlice.actions;

export default walletsSlice.reducer;

const selectWalletsState = (state: Store): IWalletsState => state.wallets.wallets;

export const selectWalletCurrency: (state: Store) => string = createDraftSafeSelector(
  selectWalletsState,
  (walletsState: IWalletsState) => walletsState.currency
);

export const selectCalculatedPaymentLimits: (store: Store) => CalculatedPaymentsLimits | null = createDraftSafeSelector(
  selectWalletsState,
  (userWalletsState: IWalletsState) => userWalletsState.currentPaymentLimits,
);

export const selectCalculatedPaymentLimitsStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectWalletsState,
  (userWalletsState: IWalletsState) => userWalletsState.currentPaymentLimitsStatus,
);
