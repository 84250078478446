import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types/enums';
import { initialStateDerivativesOrders } from './helper';
import { DerivativesOrdersState } from './types';
import { IDerivativesOrder, IDerivativesPosition } from '#/api/derivatives/dto';

const derivativesOrdersSlice = createSlice({
  name: 'derivativesOrders',
  initialState: initialStateDerivativesOrders,
  reducers: {
    derivativesOrdersRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.derivativesOpenClosedOrdersRequest = action.payload;
    },
    updateDerivativesOpenOrders(state, action: PayloadAction<IDerivativesOrder[]>) {
      state.openDerivativesOrders = action.payload;
    },
    removeClosedOrderFromOpenedList(state, action: PayloadAction<IDerivativesOrder>) {
      const stateOrders = [...state.openDerivativesOrders];
      const updatedOrder = action.payload;
      state.openDerivativesOrders = stateOrders.filter(order => order.margin_order_id !== updatedOrder.margin_order_id);
    },
    updateOpenOrder(state, action: PayloadAction<IDerivativesOrder>) {
      const stateOrders = [...state.openDerivativesOrders];
      const updatedOrder = action.payload;
      const isOrderInList = !!stateOrders.find(order => order.margin_order_id === updatedOrder.margin_order_id);
      
      if (!isOrderInList) stateOrders.unshift(updatedOrder);

      state.openDerivativesOrders = isOrderInList 
        ? stateOrders.map(order => order.margin_order_id === updatedOrder.margin_order_id ? updatedOrder : order)
        : stateOrders;
    },
    updateDerivativesClosedOrders(state, action: PayloadAction<IDerivativesOrder[]>) {
      state.closedDerivativesOrders = action.payload;
    },
    updateNextOrdersExists(state, action: PayloadAction<boolean>) {
      state.nextPageOrdersExists = action.payload;
    },
  },
});

export const {
  removeClosedOrderFromOpenedList,
  derivativesOrdersRequestStatus,
  updateDerivativesClosedOrders,
  updateDerivativesOpenOrders,
  updateNextOrdersExists,
  updateOpenOrder,
} = derivativesOrdersSlice.actions;

export default derivativesOrdersSlice.reducer;

export const selectDerivativesOrdersState = (state: Store): DerivativesOrdersState => state.derivatives.derivativesOrders;

export const selectDerivativesOpenOrders: (store: Store) => IDerivativesOrder[] = createDraftSafeSelector(
  selectDerivativesOrdersState,
  (orders: DerivativesOrdersState) => orders.openDerivativesOrders,
);

export const selectDerivativesClosedOrders: (store: Store) => IDerivativesOrder[] = createDraftSafeSelector(
  selectDerivativesOrdersState,
  (orders: DerivativesOrdersState) => orders.closedDerivativesOrders,
);

export const selectDerivativesOrdersRequestStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativesOrdersState,
  (orders: DerivativesOrdersState) => orders.derivativesOpenClosedOrdersRequest,
);

export const selectNextPageDerivativesOrdersExists: (state: Store) => boolean = createDraftSafeSelector(
  selectDerivativesOrdersState,
  (orders: DerivativesOrdersState) => orders.nextPageOrdersExists,
);