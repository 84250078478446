export const configChartColors = {
  colors: {
    alkemi: '#4C39FD',
    alk: '#4C39FD',
    btc: '#F7931A',
    eth: '#627EEA',
    weth: '#627EEA',
    dai: '#FAB62C',
    usd: '#2775CA',
    usdc: '#2775CA',
    wbtc: '#5a5564',
    gyen: '#005BAC',
    zusd: '#D62925',
    usdt: '#27A17C',
    brz: '#93D3CF',
    aave: '#3BB1C3',
    bilira: '#04134C',
    tryb: '#04134C',
    xrp: '#800080',
    xlm: '#000000',
    hbar: '#000000',
    euroc: '#0f8ff8',
  },

  colorsRgb: {
    alkemi: '76, 57, 253',
    alk: '76, 57, 253',
    btc: '247, 147, 26',
    eth: '98, 126, 234',
    weth: '98, 126, 234',
    dai: '250, 182, 44',
    usd: '39, 117, 202',
    usdc: '39, 117, 202',
    wbtc: '90, 85, 100',
    gyen: '0, 91, 172',
    zusd: '214, 41, 37',
    usdt: '39, 161, 124',
    brz: '147, 211, 207',
    bilira: '4, 19, 76',
    tryb: '4, 19, 76',
    xrp: '128, 0, 128',
    aave: '59, 177, 195',
    xlm: '0, 0, 0',
    hbar: '0, 0, 0',
    euroc: '15, 143, 248',
  },
};
