import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';

import { RequestStatus } from '#/types/enums';
import { IDerivativesBalance, IEstimatePositionPayload } from '#/api/derivatives/dto';
import { 
  updateAccountDerivativesEquity,
  updateAccountDerivativesPnl,
  updateAccountMarginCallLeftMargin,
  updateAccountUsedMargin,
  updateLiquidationLeftMargin,
  updateMarginRatioPercent,
  updateRiskLevelMessage,
} from '../derivativesTrade';

import DerivativesService from '#/api/derivatives/DerivativesService';
import { 
  derivativesBalanceRequestStatus,
  updateDerivativeBalances,
} from './derivativesBalances';
import { handleErrorThunk } from '#/util';

export const getDerivativesBalance = createAsyncThunk(
  'marginTrade/estimateMarginPosition',
  async (params: IEstimatePositionPayload, { dispatch, extra }: any) => {
    dispatch(derivativesBalanceRequestStatus(RequestStatus.Pending));
    try {     
      const { estimate_margin_user_position: { 
        free_balance,
        liquidation_left_margin,
        margin_call_left_margin,
        equity,
        pnl,
      } } = await (extra.DerivativesService as DerivativesService).getDerivativesBalance(params);

      dispatch(updateDerivativeBalances(free_balance));
      dispatch(updateAccountMarginCallLeftMargin(margin_call_left_margin));
      dispatch(updateAccountDerivativesEquity(equity));
      dispatch(updateAccountDerivativesPnl(pnl));
      dispatch(derivativesBalanceRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(derivativesBalanceRequestStatus(RequestStatus.Failed));
    }
  }
) as unknown as (params: IEstimatePositionPayload) => AnyAction;

interface ISubscribeDerivativesUserBalansePosition {
  unsubscribe?: boolean
}

export const subscribeUserBalancePositionUpdate = createAsyncThunk(
  'derivatives/subscribeUserPosition',
  async ({ unsubscribe = false}: ISubscribeDerivativesUserBalansePosition, { dispatch, extra }: any) => {
    try {
      (extra.DerivativesService as DerivativesService).subscribeUserBalancePosition({
        next: ({ data: { user_balance_position: {
          equity,
          free_balance,
          margin_call_left_margin,
          currently_used_margin,
          liquidation_left_margin,
          margin_ratio_percentage,
          risk_level,
          pnl,
        } }}: { data: { user_balance_position: IDerivativesBalance }}) => {
          dispatch(updateDerivativeBalances(free_balance));
          dispatch(updateAccountMarginCallLeftMargin(margin_call_left_margin));          
          dispatch(updateAccountDerivativesEquity(equity));
          dispatch(updateAccountDerivativesPnl(pnl));
          dispatch(updateAccountUsedMargin(currently_used_margin));
          dispatch(updateMarginRatioPercent(margin_ratio_percentage));
          dispatch(updateRiskLevelMessage(risk_level));
          dispatch(updateLiquidationLeftMargin(liquidation_left_margin));
        },
        error: (error: any) => {         
          handleErrorThunk(error, 'Subscribe Derivatives Balance Update failed', dispatch);
        },
        complete: () => {},
      }, unsubscribe);
    } catch (error) {     
      handleErrorThunk(error, 'Subscribe Derivatives Balance Update failed', dispatch);
    }
  }
) as unknown as (params: ISubscribeDerivativesUserBalansePosition) => AnyAction;