import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { initialReferralState } from './helpers';
import { IReferralState, IReferralTrackInfo } from './types';
import { RequestStatus } from '#/types';
import { IReferralRewardsHistory } from '#/api/referral/dto';

const referralSlice = createSlice({
  name: 'referral',
  initialState: initialReferralState,
  reducers: {
    updateReferralTrackInfoStatus(state, action: PayloadAction<RequestStatus>) {
      state.getReferralTrackInfoStatus = action.payload;
    },
    updateReferralTrackInfo(state, action: PayloadAction<IReferralTrackInfo>) {
      state.referralTrackInfo = action.payload;
      state.getReferralTrackInfoStatus = RequestStatus.Success;
    },
    updateReferralRewardsHistory(state, action: PayloadAction<Array<IReferralRewardsHistory>>) {
      state.referralRewardsHistory = action.payload;
      state.getReferralRewardsHistoryStatus = RequestStatus.Success;
    },
    updateReferralRewardsHistoryNextPageExists(state, action: PayloadAction<boolean>) {
      state.isNextReferralRewardsHistoryPageExists = action.payload;
    },
    updateReferralRewardsHistoryStatus(state, action: PayloadAction<RequestStatus>) {
      state.getReferralRewardsHistoryStatus = action.payload;
    },
    updateGetReferralsStatus(state, action: PayloadAction<RequestStatus>) {
      state.getReferralsRequestStatus = action.payload;
    },
    updateReferralCode(state, action: PayloadAction<string>) {
      state.referralCode = action.payload;
      state.createReferralRequestStatus = RequestStatus.Success;
      state.getReferralsRequestStatus = RequestStatus.Success;
    },
    updateCreateReferralStatus(state, action: PayloadAction<RequestStatus>) {
      state.createReferralRequestStatus = action.payload;
    },
    updateClaimReferralStatus(state, action: PayloadAction<RequestStatus>) {
      state.claimReferralRequestStatus = action.payload;
    },
  },
});

export const {
  updateGetReferralsStatus,
  updateReferralCode,
  updateCreateReferralStatus,
  updateClaimReferralStatus,
  updateReferralTrackInfo,
  updateReferralTrackInfoStatus,
  updateReferralRewardsHistory,
  updateReferralRewardsHistoryStatus,
  updateReferralRewardsHistoryNextPageExists,
} = referralSlice.actions;

export default referralSlice.reducer;

const selectReferralState = (state: Store): IReferralState => state.referral;

export const selectReferralCode: (state: Store) => string = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.referralCode,
);

export const selectGetReferralStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.getReferralsRequestStatus,
);

export const selectCreateReferralStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.createReferralRequestStatus,
);

export const selectClaimReferralStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.claimReferralRequestStatus,
);

export const selectReferralTrackInfoStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.getReferralTrackInfoStatus,
);

export const selectReferralTrackInfo: (state: Store) => IReferralTrackInfo = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.referralTrackInfo,
);

export const selectReferralRewardsHistory: (state: Store) => Array<IReferralRewardsHistory> = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.referralRewardsHistory,
);

export const selectReferralRewardsHistoryStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.getReferralRewardsHistoryStatus,
);

export const selectReferralRewardsHistoryNextPageExists: (state: Store) => boolean = createDraftSafeSelector(
  selectReferralState,
  (referralState: IReferralState) => referralState.isNextReferralRewardsHistoryPageExists,
);
