import { useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';

import useDepositWithdrawModal from '#/hooks/trade/wallets/depositWithdraw/useDepositWithdrawModal';
import { RequestStatus } from '#/types/enums';

import {
  selectDepositFiatFundingMethod,
  selectDepositCrypto,
  selectDepositStatus,
  setDepositCrypto,
  setDepositFiat,
  setDepositStatus,
  setDepositFiatFundingMethod,
  setDepositFiatPaymentRoute,
} from '#/store/reducers/wallets/deposit';
import {
  selectWithdrawFiatPaymentMethod,
  selectWithdrawCrypto,
  selectWithdrawStatus,
  setWithdrawCrypto,
  setWithdrawFiat,
  setWithdrawStatus,
  setWithdrawFiatPaymentRoute,
  setWithdrawFiatPaymentMethod,
} from '#/store/reducers/wallets/withdraw';

const useClearWalletsState = () => {
  const dispatch = useDispatch();

  const withdrawCrypto = useSelector(selectWithdrawCrypto);
  const withdrawFiatPaymentMethod = useSelector(selectWithdrawFiatPaymentMethod);
  const withdrawStatus = useSelector(selectWithdrawStatus);

  const depositCrypto = useSelector(selectDepositCrypto);
  const depositFiatFundingMethod = useSelector(selectDepositFiatFundingMethod);
  const depositStatus = useSelector(selectDepositStatus);

  const { selectedCurrency, activeSide, isOpenModal } = useDepositWithdrawModal();

  useEffect(() => {
    batch(() => {
      clearRequestStatus();

      depositFiatFundingMethod && clearDepositFiat();
      depositCrypto && clearDepositCrypto();

      withdrawFiatPaymentMethod && clearWithdrawFiat();
      withdrawCrypto && clearWithdrawCrypto();
    });
  }, [selectedCurrency, activeSide, isOpenModal]);

  const clearRequestStatus = () => {
    depositStatus !== RequestStatus.None && dispatch(setDepositStatus(RequestStatus.None));
    withdrawStatus !== RequestStatus.None && dispatch(setWithdrawStatus(RequestStatus.None));
  };

  const clearDepositFiat = () => {
    dispatch(setDepositFiat(null));
    dispatch(setDepositFiatFundingMethod(null));
    dispatch(setDepositFiatPaymentRoute(null));
  };

  const clearDepositCrypto = () => {
    dispatch(setDepositCrypto(null));
  };

  const clearWithdrawFiat = () => {
    dispatch(setWithdrawFiat(null));
    dispatch(setWithdrawFiatPaymentRoute(null));
    dispatch(setWithdrawFiatPaymentMethod(null));
  };

  const clearWithdrawCrypto = () => {
    dispatch(setWithdrawCrypto(null));
  };
};

export default useClearWalletsState;
