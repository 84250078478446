import { RequestStatus, TradeSide } from '#/types';
import { DerivativesTradeState } from './types';

// ---- Used to save the user's choice isShow a creation confirmation modal window
export const savedDerivativesConfirmationStorageKey = 'isHideConfirmationDerivatives';

export const initialStateDerivativesTrade: DerivativesTradeState = {
  closeMarginPositionRequest: RequestStatus.None,
  openMarginPositionRequest: RequestStatus.None,
  openDerivativesOrderRequest: RequestStatus.None,
  closeDerivativesOrderRequest: RequestStatus.None,
  maxPositionSize: 0,
  availableMargin: 0,
  liquidationLeftMargin: 0,
  requiredMargin: 0,
  marginCallLeftMargin: 0,
  equity: 0,
  totalPnl: 0,
  usedMargin: 0,
  marginRatioPercentage: 0,
  riskLevelMessage: '',
}

export const marginTradeSideTerms = (side?: TradeSide) => (
  `${side === TradeSide.Buy
    ? _t('Long', 'MARGING_ORDERS.LONG')
    : _t('Short', 'MARGING_ORDERS.SHORT')
  }`
);