import { createAsyncThunk } from '@reduxjs/toolkit';
import { Orderbook } from './types';
import { handleErrorThunk } from '#/util';
import DerivativesService from '#/api/derivatives/DerivativesService';
import { replaceDerivativesOrderbook } from './derivativesOrderbook';

interface SubscribeDerivativesOrderbookParams {
  instrument_id: string,
  unsubscribe?: boolean,
}

export const subscribeDerivativesOrderbook = createAsyncThunk(
  'derivatives/subscribeOrderbook',
  async ({ instrument_id, unsubscribe = false }: SubscribeDerivativesOrderbookParams, { dispatch, extra }: any) => {        
    try {
      (extra.DerivativesService as DerivativesService).subscribeOrderbook(unsubscribe, instrument_id, {
        next: ({ data: { margin_orderbook }}: { data: { margin_orderbook: Orderbook }}) => {
          dispatch(replaceDerivativesOrderbook(margin_orderbook));
        },
        error: (error: any) => {
          handleErrorThunk(error, 'Subscribe Derivatives orderbook failed', dispatch);
        },
        complete: () => {},
      });
    } catch (error) {
      handleErrorThunk(error, 'Subscribe Derivatives orderbook failed', dispatch);
    }
  }
) as (params: SubscribeDerivativesOrderbookParams) => any;
