import { IVaultsState } from './interface';
import { RequestStatus } from '#/types/enums';

export const intitialVaultsStatev2: IVaultsState = {
  vaults: {
    items: [],
    status: RequestStatus.Pending,
    error: '',
  },

  vaultsAccounts: {
    items: [],
    status: RequestStatus.Pending,
    error: '',
  },

  vaultsTransactions: {
    items: [],
    nextPageExists: false,
    status: RequestStatus.Pending,
    error: '',
  },

  vaultsTotalUserBalance: {
    value: 0,
    status: RequestStatus.Pending,
    error: '',
  },

  vaultsAverageUserApy: {
    value: 0,
    status: RequestStatus.Pending,
    error: '',
  },

  createdVaultDeposit: {
    status: RequestStatus.None,
    error: '',
  },

  createdVaultWithdrawal: {
    status: RequestStatus.None,
    error: '',
  },

  convertedСurrencyAmount: {
    value: 0,
    status: RequestStatus.None,
    error: '',
  },

  estimateVaultInterests: {
    value: 0,
    status: RequestStatus.None,
    error: '',
  },
};
