import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import {
  updateManualKycRequestStatus,
  updateKycSessionId,
  updateKycWidgetStatus,
  updateKycData,
  updateKycDoc,
  updateKycProviders,
  updateKycEnabled,
  updateKycWidgets
} from './kyc';
import { RequestStatus } from '#/types/enums';
import { throttle, handleErrorThunk } from '#/util';
import {
  CountryListResult,
  KycGetParams,
  KycProviders,
  ProvinceListResult
} from './types';
import UserService from '#/api/user/UserService';
import { KycDocumentsTypes } from '#/api/settings/dto';
import SettingsService from '#/api/settings/SettingsService';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import { CreateKycFormData, UploadKycFormData } from '#/hooks/settings/types';
import { buildUpdateKycData, constructKycData, getUserProfile } from '#reducers/user/user';
import { KycType } from './helpers';
import { kycSettingsDataAdapter } from '#helper-module';

export const getKycWidgetProvider = createAsyncThunk(
  'user/getKycTypeProvider',
  throttle(async (_: any, { dispatch, extra }: any) => {
    dispatch(updateKycWidgetStatus(RequestStatus.Pending));
    try {
      const { kyc_preferences: _kyc_preferences } = await (extra.userService as UserService).getKycTypeProviders();

      const kyc_preferences = kycSettingsDataAdapter(_kyc_preferences);
      batch(() => {
        dispatch(updateKycProviders({ individual: kyc_preferences.individual.provider, corporate: kyc_preferences.corporate.provider }));
        dispatch(updateKycEnabled({ individual: kyc_preferences.individual.enabled, corporate: kyc_preferences.corporate.enabled }));
        dispatch(updateKycWidgets({ individual: kyc_preferences.individual.provider_url, corporate: kyc_preferences.corporate.provider_url }));
        dispatch(updateKycWidgetStatus(RequestStatus.Success));
      })
    } catch (error) {
      dispatch(updateKycWidgetStatus(RequestStatus.Failed));
      throw(error);
    }
  }, 1000)
) as unknown as ()=> AnyAction;

export const getKycSessionId = createAsyncThunk(
  'user/getKycSessionId',
  throttle(async (kycType: KycType, { dispatch, extra }: any) => {
    try {
      const { create_kyc_session } = await (extra.userService as UserService).getKycSession(kycType);
      dispatch(updateKycSessionId(create_kyc_session));
    } catch (error) {
      throw(error);
    }
  }, 1000)
) as unknown as (provider: KycType) => AnyAction;;

export const uloadKycDoc = createAsyncThunk(
  'kyc/uloadKycDoc',
  async ({ userUploads }: UploadKycFormData, { dispatch, extra }: any) => {
    try {
      const request = [];
      for (let key in userUploads) {
        if (userUploads[key]) {
          request.push((extra.settingsService as SettingsService).uploadKycDocument({documentType: key as KycDocumentsTypes, file: userUploads[key] as File}));
        }
      }
    } catch (error) {
      handleErrorThunk(error, 'Submit KYC Documents Failed', dispatch);
    }
  }
) as unknown as (data : UploadKycFormData) => AnyAction;

export const createKycForm = createAsyncThunk(
  'kyc/createKycForm',
  async ({isUpdate, userId, userData, userUploads }: CreateKycFormData & UploadKycFormData, { dispatch, extra }: any) => {
    dispatch(updateManualKycRequestStatus(RequestStatus.Pending));
    try {
      const payloadFields = buildUpdateKycData(userData);

      const kycData = isUpdate
        ? (await (extra.settingsService as SettingsService).updateKycForm({userId, kycData: payloadFields})).update_kyc_user_data
        : (await (extra.settingsService as SettingsService).createKycForm({userId, kycData: payloadFields})).create_kyc_user_data;

      dispatch(uloadKycDoc({userUploads}));
      if (!!kycData) {
        batch(() => {
          dispatch(getKycInfoData({userId, kycProperties: Object.keys(userData)}));
          dispatch(getKycDocData());
          dispatch(getUserProfile());
          dispatch(updateManualKycRequestStatus(RequestStatus.Success));
        });
        novaToast.success(_t('KYC Form Submitted', 'TOASTS.FORM_SUBMITTED'));
      }
    } catch (error) {
      dispatch(updateManualKycRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'KYC form submit failed', dispatch);
    }
  }
) as unknown as (data : CreateKycFormData & UploadKycFormData) => AnyAction;

export const getKycInfoData = createAsyncThunk(
  'user/getKycInfoData',
  async ( payload : KycGetParams, { dispatch, extra }: any) => {
    try {
      const { alias_get_kyc_user_data } = await (extra.userService as UserService).getUserKycInfo(payload);
      dispatch(updateKycData(constructKycData(alias_get_kyc_user_data)))
    } catch (error) {
      throw(error);
    }
  }
) as unknown as (payload : KycGetParams) => AnyAction;

export const getKycDocData = createAsyncThunk(
  'user/getKycDocData',
  async (_, { dispatch, extra }: any) => {
    try {
      const { user } = await (extra.userService as UserService).getUserKycDoc();
      dispatch(updateKycDoc(user))
    } catch (error) {
      throw(error);
    }
  }
) as unknown as () => AnyAction;

export const getCountryList = createAsyncThunk(
  'countries',
  async (callback: React.Dispatch<React.SetStateAction<CountryListResult[]>>, { dispatch, extra }: any) => {

    try {
      const { countries } = await (extra.settingsService as SettingsService).getCountryList();
      callback([...countries]);
    } catch (error) {
      throw(error);
    }
  }

) as unknown as (callback: React.Dispatch<React.SetStateAction<CountryListResult[]>>) => AnyAction;

export const getProvinceList = createAsyncThunk(
  'provinces',
  async ({ callback, countryCode }: { callback: React.Dispatch<React.SetStateAction<ProvinceListResult[]>>, countryCode: string }, { dispatch, extra }: any) => {

    try {
      const { provinces } = await (extra.settingsService as SettingsService).getProvinceList(countryCode);
      callback([ ...provinces ]);
    } catch (error) {
      throw(error);
    }
  }
) as unknown as ({ callback, countryCode }: { callback: React.Dispatch<React.SetStateAction<ProvinceListResult[]>>, countryCode: string }) => AnyAction;
