import React, { ReactElement } from 'react';
import NovaTextInput from '../text-input/NovaTextInput';

import './nova-trade-text-field.scss';

export interface PropsNovaTradeTextField {
  [propName: string]: any,
  iconBefore?: ReactElement,
  iconAfter?: ReactElement,
  labelBefore?: ReactElement | string,
  labelMain?: ReactElement | string,
  error?: string,
  disabled?: boolean,
  maxLength?: number,
  className?: string,
}

const NovaTradeTextField = (props: PropsNovaTradeTextField) => {
  const {
    iconBefore = null,
    iconAfter = null,
    labelBefore = null,
    labelMain = null,
    error = '',
    className = '',
  } = props;

  return (
    <div className={`nova-trade-text-field-wrapper ${className}`}>
      {labelMain && <div className="nova-trade-text-field-label">{labelMain}</div>}
      <div className={`nova-trade-text-field ${iconBefore ? 'icon-before' : ''}`}>
        {iconBefore && <div className="nova-icon-before">{iconBefore}</div>}
        {labelBefore && <div className="nova-label-before">{labelBefore}</div>}
        {/* @ts-ignore */}
        <NovaTextInput
          detailsError={error}
          placeholder={'0'}
          {...props}
        />
        {iconAfter && <div className="nova-icon-after">{iconAfter}</div>}
      </div>
    </div>
  );
};

export default NovaTradeTextField;
