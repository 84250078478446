import { GqlClient } from '../gql';
import configEnv from '#/config/config-env';
import userGql, {
  LoginPayload,
  UploadDocument,
  UpdateUserProfile,
  ILoginResponse,
  ISecretCodeResponse,
  UpdateMfaPayload,
  IPaymentLimit,
  GetAccountActivityPayload,
  DocumentTypes,
  IAntiPhishingCodePayload,
  IGetUserProfilePayload,
  IUserPaymentFees,
  IGetUserPaymentFeesPayload,
 } from './user-gql';
import {
  IKycGetResponse,
  IKycProvidersGetResponse,
  UploadDocumentResult,
  UserInfo,
  UserKycDoc
} from '#reducers/user/user';
import { IsActive, IPaymentRoutes } from '#/types';
import { KycGetParams, KycType } from '#reducers/kyc';
import { AccountActivityItem } from '#reducers/home/account-activity';

export default class UserService {
  public gqlRequestClient: GqlClient;

  constructor(gqlRequestClient: GqlClient) {
    this.gqlRequestClient = gqlRequestClient;
  }

  public login(params: LoginPayload): Promise<ILoginResponse> {
    return this.gqlRequestClient.request(userGql.traderLogin(params));
  }

  public checkIn(): Promise<null> {
    return this.gqlRequestClient.request(userGql.checkIn());
  }

  public getUserProfile(params?: IGetUserProfilePayload): Promise<{ user: UserInfo }> {
    return this.gqlRequestClient.request(userGql.getUserProfile(params));
  }

  public getUserKycDoc(): Promise<{ user: UserKycDoc }> {
    return this.gqlRequestClient.request(userGql.getUserKycDoc());
  }

  public getUserKycInfo(params: KycGetParams): Promise<{ alias_get_kyc_user_data: IKycGetResponse[] }> {
    return this.gqlRequestClient.request(userGql.getUserKycInfo(params));
  }

  public getKycTypeProviders(): Promise<{ kyc_preferences: IKycProvidersGetResponse }> {
    return this.gqlRequestClient.request(userGql.getKycTypeProviders());
  }

  public getKycSession(kycType: KycType): Promise<{ create_kyc_session: string }> {
    return this.gqlRequestClient.request(userGql.getKycSession(kycType));
  }

  public getAccountActivity(params: GetAccountActivityPayload): Promise<{ timeline: Array<AccountActivityItem> }> {
    return this.gqlRequestClient.request(userGql.getAccountActivity(params));
  }

  public getPermissions(): Promise<{ permissions: Array<string> }> {
    return this.gqlRequestClient.request(userGql.getPermissions());
  }

  public updateUserProfile(params: UpdateUserProfile): Promise<{ update_user: UserInfo }> {
    return this.gqlRequestClient.request(userGql.updateUserProfile(params));
  }

  public async getMfaStatus(): Promise<{ is_using_2fa: boolean }> {
    const { user } = await this.gqlRequestClient.request(userGql.getMfaStatus());
    const mfaType: IsActive = user.mfa_status;
    return Promise.resolve({ is_using_2fa: mfaType === IsActive.On });
  }

  public async getUserPaymentLimits(): Promise<{ payment_limits: Array<IPaymentLimit> }> {
    const { user } = await this.gqlRequestClient.request(userGql.getUserPaymentLimites());
    return Promise.resolve(user.limit_group);
  }

  public async getUserDisabledCurrenciesInstruments(): Promise<{ disabled_instruments: Array<string>, disabled_currencies: Array<string> }> {
    const { user } = await this.gqlRequestClient.request(userGql.getUserDisabledCurrenciesInstruments());
    return Promise.resolve(user.limit_group);
  }

  public createSecretCode(): Promise<{ create_user_mfa_secret: ISecretCodeResponse }> {
    return this.gqlRequestClient.request(userGql.createMfaCode());
  }

  public updateMfaStatus(payload: UpdateMfaPayload): Promise<{ update_user_mfa_status: boolean }> {
    return this.gqlRequestClient.request(userGql.updateMfa(payload));
  }

  public verifyTwoFaToken(twoFaToken: string): Promise<{ verify_user_mfa_token: boolean }> {
    return this.gqlRequestClient.request(userGql.verifyTwoFaToken(twoFaToken));
  }

  public async uploadDocument(params: UploadDocument): Promise<{ ok: boolean, result: UploadDocumentResult | undefined, error?: string }> {
    try {
      const payload = new FormData();
      payload.append('documentType', params.documentType);
      payload.append('file', params.file);

      const response = await fetch(configEnv.vakoUploads, {
        method: 'POST',
        body: payload,
        headers: {
          Authorization: this.gqlRequestClient.authorization,
        }
      });

      const result: UploadDocumentResult = await response.json();

      if (!result.s3_location) {
        return Promise.reject({ ok: false, error: result.message || 'Uploading failed' });
      }

      return { ok: true, result };
    } catch (e) {
      console.error(e);
      return Promise.reject({ ok: false, error: e });
    }
  }

  public async deleteDocument(documentType: DocumentTypes): Promise<{ ok: boolean, result: UploadDocumentResult | undefined, error?: string }> {
    try {
      const response = await fetch(`${configEnv.vakoUploads}/?document_type=${documentType}`, {
        method: 'Delete',
        headers: {
          Authorization: this.gqlRequestClient.authorization,
        }
      });

      const result: UploadDocumentResult = await response.json();

      if (!result.s3_location) {
        return Promise.reject({ ok: false, error: result.message || 'Deleting failed' });
      }

      return { ok: true, result };
    } catch (e) {
      console.error(e);
      return Promise.reject({ ok: false, error: e });
    }
  }

  public deleteUser(): Promise<any> {
    return this.gqlRequestClient.request(userGql.deleteUser());
  }

  public updateAntiPhishingCode(payload: IAntiPhishingCodePayload): Promise<string> {
    return this.gqlRequestClient.request(userGql.updateAntiPhishingCode(payload));
  }

  public async getUserPaymentFees(payload: IGetUserPaymentFeesPayload): Promise<{ payments_fees: Array<IUserPaymentFees> }> {
    return  await this.gqlRequestClient.request(userGql.getUserPaymentFees(payload));
  }

  public async getUserPaymentRoutes(): Promise<{ payments_routes: IPaymentRoutes }> {
    return await this.gqlRequestClient.request(userGql.getUserPaymentRoutes());
  }
}
