import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { DerivativesOrderbookState, Orderbook, OrderPrices } from './types';
import { initialStateDerivativesOrderbook } from './helpers';

const derivativesOrderbookSlice = createSlice({
  name: 'derivatives-orderbook',
  initialState: initialStateDerivativesOrderbook,
  reducers: {
    replaceDerivativesOrderbook(state, action: PayloadAction<Orderbook>) {
      state.orderbook = action.payload;
    },
    updateOrderPrices(state, action: PayloadAction<OrderPrices>) {
      state.orderPrices = action.payload;
    },
  },
});

export const { replaceDerivativesOrderbook, updateOrderPrices } = derivativesOrderbookSlice.actions;

export default derivativesOrderbookSlice.reducer;

export const selectDerivativesOrderbookState = (state: Store): DerivativesOrderbookState => state.derivatives.derivativesOrderbook;

export const selectDerivativesOrderbook: (state: Store) => Orderbook = createDraftSafeSelector(
  selectDerivativesOrderbookState,
  (orderState: DerivativesOrderbookState) => orderState.orderbook,
);

export const selectDerivativesOrderPrices: (state: Store) => OrderPrices = createDraftSafeSelector(
  selectDerivativesOrderbookState,
  (orderState: DerivativesOrderbookState) => orderState.orderPrices,
);
