import { createSlice } from '@reduxjs/toolkit';

import { intitialVaultsStatev2 } from './initialState';
import { RequestStatus } from '#/types';

const vaultsSlicev2 = createSlice({
  name: 'vaults',
  initialState: intitialVaultsStatev2,
  reducers: {
    setVaultsPending(state) {
      state.vaults.error = '';
      state.vaults.status = RequestStatus.Pending;
    },

    setVaultsSuccess(state, action) {
      state.vaults.items = action.payload;
      state.vaults.status = RequestStatus.Success;
    },

    setVaultsFailed(state, action) {
      state.vaults.error = action.payload;
      state.vaults.status = RequestStatus.Failed;
    },

    // ---

    setVaultsAccountsPending(state) {
      state.vaultsAccounts.error = '';
      state.vaultsAccounts.status = RequestStatus.Pending;
    },

    setVaultsAccountsSuccess(state, action) {
      state.vaultsAccounts.items = action.payload;
      state.vaultsAccounts.status = RequestStatus.Success;
    },

    setVaultsAccountsFailed(state, action) {
      state.vaultsAccounts.error = action.payload;
      state.vaultsAccounts.status = RequestStatus.Failed;
    },

    // ---

    setVaultsTransactionsPending(state) {
      state.vaultsTransactions.error = '';
      state.vaultsTransactions.status = RequestStatus.Pending;
    },

    setVaultsTransactionsSuccess(state, action) {
      state.vaultsTransactions.items = action.payload;
      state.vaultsTransactions.status = RequestStatus.Success;
    },

    setVaultsTransactionsFailed(state, action) {
      state.vaultsTransactions.error = action.payload;
      state.vaultsTransactions.status = RequestStatus.Failed;
    },

    setVaultsTransactionsNextPageExists(state, action) {
      state.vaultsTransactions.nextPageExists = action.payload;
    },

    // ---

    setVaultsTotalUserBalancePending(state) {
      state.vaultsTotalUserBalance.error = '';
      state.vaultsTotalUserBalance.status = RequestStatus.Pending;
    },

    setVaultsTotalUserBalanceSuccess(state, action) {
      state.vaultsTotalUserBalance.value = action.payload;
      state.vaultsTotalUserBalance.status = RequestStatus.Success;
    },

    setVaultsTotalUserBalanceFailed(state, action) {
      state.vaultsTotalUserBalance.error = action.payload;
      state.vaultsTotalUserBalance.status = RequestStatus.Failed;
    },

    // ---

    setVaultsAverageUserApyPending(state) {
      state.vaultsAverageUserApy.error = '';
      state.vaultsAverageUserApy.status = RequestStatus.Pending;
    },

    setVaultsAverageUserApySuccess(state, action) {
      state.vaultsAverageUserApy.value = action.payload;
      state.vaultsAverageUserApy.status = RequestStatus.Success;
    },

    setVaultsAverageUserApyFailed(state, action) {
      state.vaultsAverageUserApy.error = action.payload;
      state.vaultsAverageUserApy.status = RequestStatus.Failed;
    },

    // ---

    setCreateVaultDepositPendig(state) {
      state.createdVaultDeposit.error = '';
      state.createdVaultDeposit.status = RequestStatus.Pending;
    },

    setCreateVaultDepositSuccess(state) {
      state.createdVaultDeposit.status = RequestStatus.Success;
    },

    setCreateVaultDepositFailed(state, action) {
      state.createdVaultDeposit.error = action.payload;
      state.createdVaultDeposit.status = RequestStatus.Failed;
    },

    // ---

    setCreateVaultWithdrawalPendig(state) {
      state.createdVaultWithdrawal.error = '';
      state.createdVaultWithdrawal.status = RequestStatus.Pending;
    },

    setCreateVaultWithdrawalSuccess(state) {
      state.createdVaultWithdrawal.status = RequestStatus.Success;
    },

    setCreateVaultWithdrawalFailed(state, action) {
      state.createdVaultWithdrawal.error = action.payload;
      state.createdVaultWithdrawal.status = RequestStatus.Failed;
    },

    // ---

    setConvertedСurrencyAmountPendig(state) {
      state.convertedСurrencyAmount.error = '';
      state.convertedСurrencyAmount.status = RequestStatus.Pending;
    },

    setConvertedСurrencyAmountSuccess(state, action) {
      state.convertedСurrencyAmount.value = action.payload;
      state.convertedСurrencyAmount.status = RequestStatus.Success;
    },

    setConvertedСurrencyAmountFailed(state, action) {
      state.convertedСurrencyAmount.error = action.payload;
      state.convertedСurrencyAmount.status = RequestStatus.Failed;
    },

    // ---

    setEstimateVaultInterestsPendig(state) {
      state.estimateVaultInterests.error = '';
      state.estimateVaultInterests.status = RequestStatus.Pending;
    },

    setEstimateVaultInterestsSuccess(state, action) {
      state.estimateVaultInterests.value = action.payload;
      state.estimateVaultInterests.status = RequestStatus.Success;
    },

    setEstimateVaultInterestsFailed(state, action) {
      state.estimateVaultInterests.error = action.payload;
      state.estimateVaultInterests.status = RequestStatus.Failed;
    },

    // ---

    clearStatus(state) {
      state.createdVaultDeposit.status = RequestStatus.None;
      state.createdVaultWithdrawal.status = RequestStatus.None;
    },

    // ---

    clearConvertedСurrency(state) {
      state.convertedСurrencyAmount.value = 0;
    },

    // ---

    clearEstimateVaultInterests(state) {
      state.estimateVaultInterests.value = 0;
    },
  },
});

export const {
  setVaultsPending,
  setVaultsSuccess,
  setVaultsFailed,

  setVaultsAccountsPending,
  setVaultsAccountsSuccess,
  setVaultsAccountsFailed,

  setVaultsTransactionsPending,
  setVaultsTransactionsSuccess,
  setVaultsTransactionsFailed,
  setVaultsTransactionsNextPageExists,

  setVaultsTotalUserBalancePending,
  setVaultsTotalUserBalanceSuccess,
  setVaultsTotalUserBalanceFailed,

  setVaultsAverageUserApyPending,
  setVaultsAverageUserApySuccess,
  setVaultsAverageUserApyFailed,

  setCreateVaultDepositPendig,
  setCreateVaultDepositSuccess,
  setCreateVaultDepositFailed,

  setCreateVaultWithdrawalPendig,
  setCreateVaultWithdrawalSuccess,
  setCreateVaultWithdrawalFailed,

  setConvertedСurrencyAmountPendig,
  setConvertedСurrencyAmountSuccess,
  setConvertedСurrencyAmountFailed,

  setEstimateVaultInterestsPendig,
  setEstimateVaultInterestsSuccess,
  setEstimateVaultInterestsFailed,

  clearStatus,
  clearConvertedСurrency,
  clearEstimateVaultInterests,
} = vaultsSlicev2.actions;

export default vaultsSlicev2.reducer;
