import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser, updateUserProfile, UserInfo, UserLanguages } from '#reducers/user/user';
import {
  selectCurrentLanguage,
  selectSiteTheme,
  setLanguage,
  updateSiteTheme,
} from '#reducers/config';
import {
  replaceOrderbook,
  selectInstrumentsArray,
  selectSelectedInstrument,
  updateSelectedInstrument,
} from '#reducers/trade';

import { Languages } from '#/config/language/langConfig';
import { ISelectedInstrument, Themes } from '#/types';

import {
  getUserSettingsFromParams,
  validateLanguage,
  validateTheme,
  findPair,
  IUserPlatformParamsState,
} from './helpers';
import { localStorageLangKey } from '#/util/translation/getCurrentLang';

const useUserPlatformSettings = () => {
  const dispatch = useDispatch();

  const currentTheme: Themes = useSelector(selectSiteTheme);
  const currentLanguage: Languages = useSelector(selectCurrentLanguage);
  const selectedInstrument: ISelectedInstrument = useSelector(selectSelectedInstrument);
  const arrayInstruments: Array<ISelectedInstrument> = useSelector(selectInstrumentsArray);
  const userInfo: UserInfo = useSelector(selectUser);

  const [isApplied, setIsApplied] = useState<boolean>(false);

  const platformSettings: IUserPlatformParamsState = getUserSettingsFromParams();

  const handleUpdateSettings = ({ lang, pair, theme }: IUserPlatformParamsState) => {
    const isThemeValid: boolean = validateTheme(theme);
    const isLanguageValid: boolean = validateLanguage(lang);
    const pairFromSettings: ISelectedInstrument | undefined = findPair(pair, arrayInstruments);

    if (isThemeValid && currentTheme !== theme) {
      dispatch(updateSiteTheme(theme as Themes));
    }

    if (isLanguageValid && currentLanguage !== lang) {
      localStorage.setItem(localStorageLangKey, lang);

      dispatch(setLanguage(lang as Languages));

      userInfo.user_id && dispatch(
        updateUserProfile({
          ...userInfo,
          language: lang as UserLanguages,
          ignoreNotification: true,
        })
      );
    }

    if (pairFromSettings && pairFromSettings.instrument_id !== selectedInstrument.instrument_id) {
      dispatch(updateSelectedInstrument(pairFromSettings));
      dispatch(replaceOrderbook({ buy: [], sell: [] }));
    }

    setIsApplied(true);
  };

  useEffect(() => {
    //apply once because we have several sources where these settings can be changed
    if (arrayInstruments.length && !platformSettings.isAllEmpty && !isApplied) {
      handleUpdateSettings(platformSettings);
    }
  }, [JSON.stringify(platformSettings), JSON.stringify(arrayInstruments)]);
};

export default useUserPlatformSettings;
