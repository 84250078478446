import * as gql from 'gql-query-builder';
import {
  GetAccountActivityPayload,
  IAntiPhishingCodePayload,
  IGetUserPaymentFeesPayload,
  IGetUserProfilePayload,
  LoginPayload,
  UpdateMfaPayload,
  UpdateUserProfile,
} from './dto';
import {
  checkInFields,
  getAccountActivityFields,
  getKycDocFields,
  getKycInfoFields,
  getKycTypeProvidersFields,
  getPaymentFees,
  getPaymentRoutesFields,
  getUserPaymentLimits,
  getUserProfileFields,
  loginFields,
} from './fields';
import { KycGetParams, KycProviders, KycType } from '#reducers/kyc';

export * from './dto';

export enum UserOperations {
  Login = 'trader_demo_signin',
  UpdateUserProfile = 'update_user',
  CreateSecretCode = 'create_user_mfa_secret',
  UpdateMfa = 'update_user_mfa_status',
  GetPermissions = 'permissions',
  GetUser = 'user',
  CreateKycSession = 'create_kyc_session',
  CheckIn = 'checkin',
  VerifyTwoFa = 'verify_user_mfa_token',
  getAccountActivity = 'timeline',
  DeleteUser = 'delete_user',
  GetKycProvidersType = 'kyc_preferences',
  UpdateAntiPhishingCode = 'update_anti_phishing_code',
  GetPaymentsFees = 'payments_fees',
  GetPaymentsRoutes = 'payments_routes',
}

export enum KycOperations {
  GetKyc = 'kyc_user_data',
  CreateKycData = 'create_kyc_user_data',
  UpdateKycData = 'update_kyc_user_data',
  DeleteKycData = 'delete_kyc_user_data',
}

export enum AliasKycOperations {
  GetKyc = 'alias_get_kyc_user_data',
}

export default {
  traderLogin: (params: LoginPayload) => {
    return gql.mutation({
      operation: UserOperations.Login,
      variables: {
        username: { value: params.username, required: true },
      },
      fields: loginFields,
    });
  },

  verifyTwoFaToken: (token: string) => {
    return gql.mutation({
      operation: UserOperations.VerifyTwoFa,
      variables: {
        token: { value: token, required: true },
      },
      fields: [],
    });
  },

  getMfaStatus: () => {
    return gql.query({
      operation: UserOperations.GetUser,
      fields: ['mfa_status'],
    });
  },

  createMfaCode: () => {
    return gql.mutation({
      operation: UserOperations.CreateSecretCode,
      fields: ['secret', 'uri', 'qr'],
    });
  },

  updateMfa: (params: UpdateMfaPayload) => {
    return gql.mutation({
      operation: UserOperations.UpdateMfa,
      variables: {
        status: { value: params.status, required: true, type: 'ToggleSwitch' },
        secret: { value: params.secret, required: false },
        token: { value: params.token, required: true },
      },
      fields: [],
    });
  },

  checkIn: () => {
    return gql.mutation({
      operation: UserOperations.CheckIn,
      fields: checkInFields,
    });
  },

  getPermissions: () => {
    return gql.query({
      operation: UserOperations.GetPermissions,
      fields: [],
    });
  },

  updateUserProfile: (params: UpdateUserProfile) => {
    return gql.mutation({
      operation: UserOperations.UpdateUserProfile,
      variables: {
        username: { value: params.username, required: false },
        email: { value: params.email, required: false },
        language: { value: params.language, required: false },
        primary_market_currency: { value: params.primary_market_currency, required: false },
        timezone: { value: params.timezone, required: false },
        favorite_instruments: { value: params.favorite_instruments, required: false, type: '[String!]' },
        favorite_addresses_crypto: { value: params.favorite_addresses_crypto, required: false, type: '[FavoriteAddressCryptoItem!]' },
        favorite_fiat_destinations: { value: params.favorite_fiat_destinations, required: false, type: '[FavoriteFiatDestinationItem!]' },
        notifications_options: { value: params.notifications_options, required: false, type: 'NotificationOptionsArgs' },
        default_timezone: { value: params.default_timezone, required: false, type: 'ToggleSwitch' },
      },
      fields: getUserProfileFields(),
    });
  },

  getUserProfile: (params?: IGetUserProfilePayload) => {
    return gql.query({
      operation: UserOperations.GetUser,
      fields: getUserProfileFields(params?.twoFaCode),
    });
  },

  getUserKycDoc: () => {
    return gql.query({
      operation: UserOperations.GetUser,
      fields: getKycDocFields,
    });
  },

  getUserKycInfo: (params: KycGetParams) => {
    return gql.query({
      operation: { name: KycOperations.GetKyc, alias: AliasKycOperations.GetKyc },
      variables: {
        user_id: { value: params.userId, required: true, type: 'String' },
        kyc_properties: { value: params.kycProperties, required: true, type: '[String!]' },
      },
      fields: getKycInfoFields,
    });
  },

  getKycTypeProviders: () => {
    return gql.query({
      operation: UserOperations.GetKycProvidersType,
      fields: getKycTypeProvidersFields,
    });
  },

  getKycSession: (kycType: KycType) => {
    return gql.mutation({
      operation: UserOperations.CreateKycSession,
      fields: [],
      variables: {
        kyc_type: { value: kycType, required: true, type: 'KycType'},
      }
    });
  },

  getAccountActivity: (params: GetAccountActivityPayload) => {
    return gql.query({
      operation: UserOperations.getAccountActivity,
      variables: {
        pager: { value: params.pager, required: false, type: 'PagerInput' },
        event_name: { value: params.event_name, required: false, type: '[NotificationTrigger!]' },
      },
      fields: getAccountActivityFields,
    });
  },

  getUserPaymentLimites: () => {
    return gql.query({
      operation: UserOperations.GetUser,
      fields: getUserPaymentLimits,
    });
  },

  getUserDisabledCurrenciesInstruments: () => {
    return gql.query({
      operation: UserOperations.GetUser,
      fields: [{ limit_group: [
        'disabled_instruments',
        'disabled_currencies',
      ] }],
    });
  },

  deleteUser: () => {
    return gql.mutation({
      operation: UserOperations.DeleteUser,
      fields: [],
      variables: {}
    });
  },

  updateAntiPhishingCode: (params: IAntiPhishingCodePayload) => {
    return gql.mutation({
      operation: UserOperations.UpdateAntiPhishingCode,
      variables: {
        anti_phishing_code: { value: params.anti_phishing_code, required: false, type: 'String!' },
        token: { value: params.token, required: false, type: 'String!' },
      },
      fields: [],
    });
  },

  getUserPaymentFees: (params: IGetUserPaymentFeesPayload) => {
    return gql.query({
      operation: UserOperations.GetPaymentsFees,
      fields: getPaymentFees,
      variables: {
        fee_group_id: { value: params.fee_group_id, required: false, type: 'String' },
        pager: { value: { limit: 10000, offset: 0 }, required: false, type: 'PagerInput' },
      },
    });
  },

  getUserPaymentRoutes: () => {
    return gql.query({
      operation: UserOperations.GetPaymentsRoutes,
      variables: {
        pager: { value: { limit: 10000, offset: 0 }, required: false, type: 'PagerInput' },
      },
      fields: getPaymentRoutesFields,
    });
  },
}
