export const referralFields = [
  'serial_id',
  'referral_id',
  'user_id',
  'created_at',
];

export const referralRewardsHistoryFields = [
  'amount',
  'comment',
  'user_id',
  'account_transaction_id',
  'created_by',
  'created_at',
  'created_at_iso',
  {
    currency: [
      'type',
      'precision',
      'currency_id',
    ],
  },
];
