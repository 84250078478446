import { isIframe } from "#/util";

const ALLOWED_DOMAINS = [ /* allowed domains to open exchange directly */
  'cryptosrvc.com',
];

const ALLOWED_PARENT_HOSTNAMES = [ /* allowed parent site hostnames where exchange can be opened in iframe */
  'noones.com',
  'noones.app',
  'noones.global',
  'noones-test.com',
];

const ALLOWED_LOCAL_DEVELOPMENT_HOSTS = [ /* allowed hosts for local development */
  'localhost:8080',
];

export const getCurrentUrl = () => {
  try {
    return new URL(
      isIframe() ? document.referrer : window.location.href
    );
  } catch {
    return null;
  }
};

export const isAllowedDomain = (hostname: string): boolean => {
  const domain = hostname.split('.').slice(-2).join('.');
  return ALLOWED_DOMAINS.includes(domain);
};

export const isAllowedParentHostname = (hostname: string): boolean => {
  return ALLOWED_PARENT_HOSTNAMES.includes(hostname);
};

export const isLocalDevelopmentHost = (host: string): boolean => {
  return ALLOWED_LOCAL_DEVELOPMENT_HOSTS.includes(host);
};