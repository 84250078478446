import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types/enums';
import { initialStateDerivativesBalance } from './helper';
import { DerivativesBalanceState } from './types';

const derivativeBalanceSlice = createSlice({
  name: 'marginBalance',
  initialState: initialStateDerivativesBalance,
  reducers: {
    derivativesBalanceRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.derivativesBalanceRequestStatus = action.payload;
    },
    updateDerivativeBalances(state, action: PayloadAction<number>) {
      state.derivativesBalance = action.payload;
    },
  },
});

export const {
  derivativesBalanceRequestStatus,
  updateDerivativeBalances
} = derivativeBalanceSlice.actions;

export default derivativeBalanceSlice.reducer;

export const selectDerivativeBalanceState = (state: Store): DerivativesBalanceState => state.derivatives.derivativesBalances;

export const selectDerivativesBalance: (store: Store) => number = createDraftSafeSelector(
  selectDerivativeBalanceState,
  (balance: DerivativesBalanceState) => balance.derivativesBalance,
);

export const selectDerivativesConfigRequestStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectDerivativeBalanceState,
  (balance: DerivativesBalanceState) => balance.derivativesBalanceRequestStatus,
);