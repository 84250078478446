import getFormattedDate from './getFormattedDate';

export { default as getFormattedDate } from './getFormattedDate';
export * from './getTimestamp';
export { default as getTimeZone } from './getTimezone';
export * from './ignoreTimezone';
export { default as getTomorrowDate } from './getTomorrowDate';
export { default as getNextDay } from './getNextDay';
export { default as adultVerifier } from './adultVerifier';
export { default as getLastDay } from './getLastDay';
export { default as formattedDateFullString } from './formattedDateFullString';
export { formattedDateToIso } from './formattedDateToIso';
export * from './getChartTimezone';
export * from './getTimeZoneAbrr';

export const defaultFormattedDate = (date: string | number) => {
  try {
    const dateParsed = Number(date) || date || undefined;
    if (!dateParsed) {
      return '-';
    }
    return getFormattedDate(dateParsed, false, false, 'MM/dd/yy', 'h:mm a', true);
  } catch (e) {
    console.error(`invalid date ${e}`, e);
    return '-';
  }
};
