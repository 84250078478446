import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from '@reduxjs/toolkit';

import { handleErrorThunk, throttle } from '#/util'
import { RequestStatus } from '#/types/enums';
import { EDerivativesOrderStatus, IDerivativesOrder, IGetOpenClosedPositionsPayload } from '#/api/derivatives/dto';

import DerivativesService from '#/api/derivatives/DerivativesService';
import { 
  derivativesOrdersRequestStatus,
  removeClosedOrderFromOpenedList,
  updateDerivativesClosedOrders,
  updateDerivativesOpenOrders,
  updateNextOrdersExists,
  updateOpenOrder,
} from './derivativesOrders';
import { preparePagerDifferencePlus1, prepareResultPagerDifference } from '#/util/pager';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import { marginTradeSideTerms } from '../derivativesTrade';

export const getDerivativesOpenOrders = createAsyncThunk(
  'derivatives/getOpenOrders',
  throttle(async (params: IGetOpenClosedPositionsPayload, { dispatch, extra }: any) => {
    dispatch(derivativesOrdersRequestStatus(RequestStatus.Pending));
    try {      
      const { open_margin_orders } = await (extra.DerivativesService as DerivativesService).getDerivativesOpenOrders(preparePagerDifferencePlus1(params));

      const pagerOrders = prepareResultPagerDifference(open_margin_orders, params?.pager?.limit);

      dispatch(updateDerivativesOpenOrders(pagerOrders));
      dispatch(updateNextOrdersExists(open_margin_orders.length > pagerOrders.length));
      dispatch(derivativesOrdersRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(derivativesOrdersRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get derivatives open orders failed', dispatch);
    }
  }, 100),
) as unknown as (params: IGetOpenClosedPositionsPayload) => AnyAction;

export const getDerivativesClosedOrders = createAsyncThunk(
  'derivatives/getClosedOrders',
  throttle(async (params: IGetOpenClosedPositionsPayload, { dispatch, extra }: any) => {
    dispatch(derivativesOrdersRequestStatus(RequestStatus.Pending));
    try {      
      const { closed_margin_orders } = await (extra.DerivativesService as DerivativesService).getDerivativesClosedOrders(preparePagerDifferencePlus1(params));
      
      const pagerOrders = prepareResultPagerDifference(closed_margin_orders, params?.pager?.limit);

      dispatch(updateDerivativesClosedOrders(pagerOrders));
      dispatch(updateNextOrdersExists(closed_margin_orders.length > pagerOrders.length));
      dispatch(derivativesOrdersRequestStatus(RequestStatus.Success));    
    } catch (error) {
      dispatch(derivativesOrdersRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get derivatives closed orders failed', dispatch);
    }
  }, 100),
) as unknown as (params: IGetOpenClosedPositionsPayload) => AnyAction;

interface SubscribeOrders {
  unsubscribe?: boolean
}

export const subscribeDerivativesOrdersUpdate = createAsyncThunk(
  'derivatives/subscribeOrdersUpdate',
  async ({ unsubscribe = false}: SubscribeOrders, { dispatch, extra }: any) => {
    try {
      (extra.DerivativesService as DerivativesService).subscribeOrdersUpdate({
        next: ({ data: { margin_order_update }}: { data: { margin_order_update: IDerivativesOrder }}) => {                
          if (margin_order_update.status !== EDerivativesOrderStatus.Open) {
            dispatch(removeClosedOrderFromOpenedList(margin_order_update));
            dispatch(getDerivativesClosedOrders({ pager: { limit: 7, offset: 0 } }));
          } else {
            dispatch(updateOpenOrder(margin_order_update));
          }
          novaToast.success(`Order ${margin_order_update.instrument_id} is ${margin_order_update.status}`, {
            [_t('ID', 'EXECUTE_ORDER.ID')]: `${margin_order_update.margin_order_id}`,
            [_t('Amount', 'EXECUTE_ORDER.AMOUNT')]: `${margin_order_update.quantity}`,
            [_t('Price', 'MARGING_ORDERS.PRICE')]: `${margin_order_update.price}`,
            [_t('Side', 'NOVA_ORDER.SIDE')]: marginTradeSideTerms(margin_order_update.side),
          });
        },
        error: (error: any) => {         
          handleErrorThunk(error, 'Subscribe Derivatives Orders Update failed', dispatch);
        },
        complete: () => {},
      }, unsubscribe);
    } catch (error) {     
      handleErrorThunk(error, 'Subscribe Derivatives Orders Update failed', dispatch);
    }
  }
) as unknown as (params: SubscribeOrders) => AnyAction;