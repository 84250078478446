import React from 'react';

import { FiatFields, FiatWalletFields, NovaElementsOptions, NovaElementsSizes } from '#/types';

import { BankFields, ICountry } from './NovaBankFields';
import NovaTextInput from '#/nova/components/controls/form/text-input/NovaTextInput';
import NovaTradeTextField from '#/nova/components/controls/form/trade-text-field/NovaTradeTextField';
import NovaTradeLabel from '#/nova/components/text/trade-label/NovaTradeLabel';
import Select from 'react-select';
import NovaButton from '#/nova/components/controls/button/NovaButton';
import { checkIsRequiredFiatFields } from '../../../payments/add-modal/helpers';

export interface IWithdrawFiatWireFieldsProps {
  fields: BankFields,
  setFields: (fields: BankFields) => void,
  getFieldKey: (fieldKeyAddressbook: FiatFields) => string,
  getFieldValue: (field: FiatFields) => string,
  onFieldTouch: (field: FiatFields) => () => void,
  defineFieldClassName: (field: FiatFields) => string,
  renderErrorMessage: (field: FiatFields) => string,
  setOpenModalFiat: (value: boolean) => void,
  countryList: ICountry[],
  isAddressbook: boolean,
  showAddressbookImportButton: boolean,
}

export const requiredDefaultFields = [
  FiatWalletFields.BankName,
  FiatWalletFields.BankCountry,
  FiatWalletFields.BankAddress,
  FiatWalletFields.BankCity,
  FiatWalletFields.BeneficiaryName,
  FiatWalletFields.BeneficiaryCity,
  FiatWalletFields.BeneficiaryCountry,
  FiatWalletFields.BeneficiaryAccountNumber,
  FiatWalletFields.BeneficiaryAddress,
  FiatWalletFields.Reference,
]

export const defaultFiatProperties = [
  ...requiredDefaultFields,
  FiatWalletFields.RoutingNumber,
  FiatWalletFields.BeneficiaryPostalCode,
  FiatWalletFields.BankPostalCode,
  FiatWalletFields.BankRegion,
  FiatWalletFields.BeneficiaryRegion,
]

export const requiredAddressBookFiatFields = [
  FiatFields.BankName,
  FiatFields.BankCountry,
  FiatFields.BankAddress,
  FiatFields.BankCity,
  FiatFields.BeneficiaryName,
  FiatFields.BeneficiaryCity,
  FiatFields.BeneficiaryCountry,
  FiatFields.BeneficiaryAccountNumber,
  FiatFields.BeneficiaryAddress,
  FiatFields.Reference,
];

const DefaultWithdrawalFields = (
  {
    fields,
    countryList,
    setFields,
    getFieldKey,
    getFieldValue,
    onFieldTouch,
    defineFieldClassName,
    renderErrorMessage,
    setOpenModalFiat,
    isAddressbook,
    showAddressbookImportButton,
  }: IWithdrawFiatWireFieldsProps) => {

  return (
    <>
      {(!isAddressbook && showAddressbookImportButton) &&
        <NovaButton
          onClick={() => setOpenModalFiat(true)}
          className="nova-wallet-address-book"
          variant={NovaElementsOptions.Secondary}
          size={NovaElementsSizes.MediumSmall}
          value={_t('Import from Fiat Address Book', 'NOVA_WITHDRAW.IMPORT_FIAT_ADDRESS_BOOK')}
        />
      }

      <NovaTradeTextField
        className={defineFieldClassName(FiatFields.BeneficiaryName)}
        labelMain={<NovaTradeLabel
          isRequired={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryName, requiredDefaultFields)}
          label={_t('Beneficiary Name', 'NOVA_WITHDRAW.BANK_BENEFICIARY_NAME')} />}
        value={getFieldValue(FiatFields.BeneficiaryName) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryName)]: e.target.value })}
        placeholder={''}
        error={renderErrorMessage(FiatFields.BeneficiaryName)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryName)}
      />

      <NovaTextInput
        label={_t('Beneficiary Street Address', 'NOVA_WITHDRAW.BENEFICIARY_STREET_ADDRESS')}
        value={getFieldValue(FiatFields.BeneficiaryAddress) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryAddress)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryAddress, requiredDefaultFields)}
        detailsError={renderErrorMessage(FiatFields.BeneficiaryAddress)}
        className={defineFieldClassName(FiatFields.BeneficiaryAddress)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryAddress)}
      />

      <NovaTextInput
        label={_t('Beneficiary City', 'NOVA_WITHDRAW.BENEFICIARY_CITY')}
        value={getFieldValue(FiatFields.BeneficiaryCity) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryCity)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryCity, requiredDefaultFields)}
        detailsError={renderErrorMessage(FiatFields.BeneficiaryCity)}
        className={defineFieldClassName(FiatFields.BeneficiaryCity)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryCity)}
      />

      <NovaTextInput
        label={_t('Beneficiary Region', 'NOVA_WITHDRAW.BENEFICIARY_REGION')}
        value={getFieldValue(FiatFields.BeneficiaryRegion) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryRegion)]: e.target.value })}
      />

      <div className="input-label">
        <span>{_t('Beneficiary Country', 'NOVA_WITHDRAW.BENEFICIARY_COUNTRY')}</span><span className='form-required'>*</span>
        <span className="input-error-message">{renderErrorMessage(FiatFields.BeneficiaryCountry)}</span>
      </div>
      <Select
        value={{ value: getFieldValue(FiatFields.BeneficiaryCountry), label: getFieldValue(FiatFields.BeneficiaryCountry) }}
        onChange={(e: any) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryCountry)]: e.value })}
        options={countryList}
        className={`select-container ${defineFieldClassName(FiatFields.BeneficiaryCountry)}`}
        classNamePrefix="country-select"
        required={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryCountry, requiredDefaultFields)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryCountry)}
      />

      <NovaTextInput
        label={_t('Beneficiary Postal Code', 'NOVA_WITHDRAW.BENEFICIARY_POSTAL_CODE')}
        value={getFieldValue(FiatFields.BeneficiaryPostalCode) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryPostalCode)]: e.target.value })}
      />

      <NovaTextInput
        label={_t('Bank Name', 'NOVA_WITHDRAW.BANK_NAME')}
        value={getFieldValue(FiatFields.BankName) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankName)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BankName, requiredDefaultFields)}
        detailsError={renderErrorMessage(FiatFields.BankName)}
        className={defineFieldClassName(FiatFields.BankName)}
        onBlur={onFieldTouch(FiatFields.BankName)}
      />

      <NovaTextInput
        required={checkIsRequiredFiatFields(FiatWalletFields.BeneficiaryAccountNumber, requiredDefaultFields)}
        label={_t('Account Number', 'NOVA_WITHDRAW.BANK_BENEFICIARY_ACCOUNT')}
        value={getFieldValue(FiatFields.BeneficiaryAccountNumber) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BeneficiaryAccountNumber)]: e.target.value })}
        detailsError={renderErrorMessage(FiatFields.BeneficiaryAccountNumber)}
        className={defineFieldClassName(FiatFields.BeneficiaryAccountNumber)}
        onBlur={onFieldTouch(FiatFields.BeneficiaryAccountNumber)}
      />

      <NovaTextInput
        label={_t('Bank Routing Number', 'NOVA_WITHDRAW.BANK_ROUTING_NUMBER')}
        required={checkIsRequiredFiatFields(FiatWalletFields.RoutingNumber, requiredDefaultFields)}
        value={getFieldValue(FiatFields.RoutingNumber) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.RoutingNumber)]: e.target.value })}
      />

      <NovaTextInput
        label={_t('Bank Street Address', 'NOVA_WITHDRAW.BANK_STREET_ADDRESS')}
        value={getFieldValue(FiatFields.BankAddress) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankAddress)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BankAddress, requiredDefaultFields)}
        detailsError={renderErrorMessage(FiatFields.BankAddress)}
        className={defineFieldClassName(FiatFields.BankAddress)}
        onBlur={onFieldTouch(FiatFields.BankAddress)}
      />

      <NovaTextInput
        label={_t('Bank City', 'NOVA_WITHDRAW.BANK_CITY')}
        value={getFieldValue(FiatFields.BankCity) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankCity)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BankCity, requiredDefaultFields)}
        detailsError={renderErrorMessage(FiatFields.BankCity)}
        className={defineFieldClassName(FiatFields.BankCity)}
        onBlur={onFieldTouch(FiatFields.BankCity)}
      />

      <NovaTextInput
        label={_t('Bank Region', 'NOVA_WITHDRAW.BANK_REGION')}
        value={getFieldValue(FiatFields.BankRegion) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankRegion)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BankRegion, requiredDefaultFields)}
      />

      <div className="input-label">
        <span>{_t('Bank Country', 'NOVA_WITHDRAW.BANK_COUNTRY')}</span><span className='form-required'>*</span>
        <span className="input-error-message">{renderErrorMessage(FiatFields.BankCountry)}</span>
      </div>
      <Select
        value={{ value: getFieldValue(FiatFields.BankCountry), label: getFieldValue(FiatFields.BankCountry) }}
        onChange={(e: any) => setFields({ ...fields, [getFieldKey(FiatFields.BankCountry)]: e.value })}
        options={countryList}
        className={`select-container ${defineFieldClassName(FiatFields.BankCountry)}`}
        classNamePrefix="country-select"
        required={checkIsRequiredFiatFields(FiatWalletFields.BankCountry, requiredDefaultFields)}
        onBlur={onFieldTouch(FiatFields.BankCountry)}
      />

      <NovaTextInput
        label={_t('Bank Postal Code ', 'NOVA_WITHDRAW.BANK_POSTAL_CODE')}
        value={getFieldValue(FiatFields.BankPostalCode) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.BankPostalCode)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.BankPostalCode, requiredDefaultFields)}
      />

      <NovaTextInput
        label={_t('Reference', 'NOVA_WITHDRAW.REFERENCE')}
        value={getFieldValue(FiatFields.Reference) || ''}
        size={NovaElementsSizes.Small}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFields({ ...fields, [getFieldKey(FiatFields.Reference)]: e.target.value })}
        required={checkIsRequiredFiatFields(FiatWalletFields.Reference, requiredDefaultFields)}
        detailsError={renderErrorMessage(FiatFields.Reference)}
        className={defineFieldClassName(FiatFields.Reference)}
        onBlur={onFieldTouch(FiatFields.Reference)}
      />
    </>
  );
};

export default DefaultWithdrawalFields;