import { IPaymentRoute, IPaymentRoutes, RequestStatus } from '#/types';
import { IPaymentRoutesState } from './types';

export const initialPaymentRoutesState: IPaymentRoutesState = {
  paymentRoutes: {},
  paymentRoutesRequestStatus: RequestStatus.None,
};

export const groupRoutesByCurrencyId = (arr: IPaymentRoutes) => {
  const result = {};

  arr.forEach((item: IPaymentRoute) => {
    const currencyId = item.currency_id;
    if (!result[currencyId]) {
      result[currencyId] = {
        currency_id: item.currency_id,
        type: item.currency.type,
        paymentRoutes: [],
      };
    }
    result[currencyId].paymentRoutes.push(item);
  });

  return result;
};
